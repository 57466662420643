import RentalCreateAction from "../../stores/rental/create/RentalCreateAction";
import React, {useState} from "react";
import ApiEnum from "../constants/ApiEnum";
import AuthenticationEffect from "../../stores/authentication/AuthenticationEffect";
import {useAppDispatch, useAppSelector} from "../../createSlice/app/hooks";
import AuthenticationAction from "../../stores/authentication/AuthenticationAction";

export const PinValidationWrapper = (props) => {

    const [validated, setValidated] = useState(false)
    const [loading, setLoading] = useState(false)
    const [pin, setPin] = useState()

    const dispatch = useAppDispatch()

    const {accessToken, xPartnerLocationAuth} = useAppSelector(state => state.authentication);
    const {slug, data} = useAppSelector(state => state.environment);
    const {clientId} = useAppSelector(state => state.partnerLocation);


    const validatePin = () => {

        setLoading(true)

        dispatch(AuthenticationAction.getUserByPin(pin, dispatch)).then((result) => {
            setLoading(false)
            setValidated(true)
        })
    }

    return (
        <>
            {clientId === 8 && !validated &&
                <form onSubmit={(e) => {
                    e.preventDefault()
                    validatePin(pin)
                }} style={{alignItems: 'center', marginBottom: '2rem', textAlign: 'center', padding: '4rem'}}>

                    <label style={{marginRight: '1rem', minWidth: '100%'}}>Pincode:</label>

                    <input
                        type={'password'}
                        value={pin}
                        onChange={(e) => setPin(e.target.value)}
                        required
                        disabled={loading}
                        style={{
                            marginRight: '1rem',
                            marginBottom: '1rem',
                            background: '#eee',
                            border: 0,
                            padding: '1rem',
                            textAlign: 'center'
                        }}
                    />

                    <button
                        style={{margin: '0 auto'}}
                        className={'btn btn--rounded btn--primary'}
                        disabled={loading}
                        type={'submit'}><span>Submit</span>
                    </button>

                </form>
            }

            {(clientId !== 8 || validated) && <> {props.children} </>}

        </>
    )
}
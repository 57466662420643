import React, {useState} from "react";
import translate from "../../../common/helpers/utils/translate";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import EnvironmentAction from "../../../../stores/environment/EnvironmentAction";
import {Link} from "react-router-dom";
import {requestForgotPassword, setData, setErrors} from "./forgotPasswordSlice";


export const ForgotPasswordForm = () => {
    const {environment, forgotPasswordPage} = useAppSelector((state) => state)
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [requestSend, setRequestSend] = useState(false)
    const dispatch = useAppDispatch()

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(EnvironmentAction.setLoader(true)).then(() => {
            dispatch(requestForgotPassword()).then((response) => {
                if (response.payload.status.code !== 200) {
                    dispatch(setErrors({
                        isError: true,
                        message: response.payload.message
                    }))
                    setShowErrorMessage(true)
                    dispatch(EnvironmentAction.setLoader(false))
                    return
                }

                setRequestSend(true)
                dispatch(EnvironmentAction.setLoader(false))
            })
        })
    };

    if (requestSend) {
        return (<div className="c-auth__form">
            <h3>{translate('passwordResetRequestedTitle')}</h3>
            <p>{translate('passwordResetRequestedTekst')}</p>
            <p>{translate('changesMadeWarning')}</p>
            <Link to={"/" + environment.slug}>{translate('return')}</Link>
        </div>)
    }


    return (
        <form className="c-auth__form c-form" onSubmit={handleSubmit}>

            <small>{translate('forgotPasswordLabel')}</small>

            <div className="c-form__row">
                <div className="c-form__group">
                    <label htmlFor="email">{translate('emailAddress')}</label>
                    <input onChange={(e) => dispatch(setData({
                        ...forgotPasswordPage.data,
                        email: e.target.value
                    }))} type="email" name={'email'} required
                           id="email"/>
                </div>
            </div>

            {!!showErrorMessage && <p style={{marginBottom: '2rem'}}>{forgotPasswordPage.errors.message}</p>}

            <div className="c-form__row">
                <div className="c-form__group">
                    <button className="btn btn--primary" type="submit"
                            style={{backgroundColor: environment.colors.dark}}>
                        <span>{translate('requestPasswordResetButton')}</span></button>

                    <Link to={"/" + environment.slug}>{translate('login')}</Link>
                </div>
            </div>

        </form>
    )
}
import RentalIssueItemsAction from "./RentalIssueItemsAction";

export default class RentalIssueItemsReducer {

    static initialState = {
        scannedItem: null,
        items: [],
        status: {
            correctCount: 0,
            errors: [],
            completed: false
        },
        remarks: {
            show: false,
            value: ''
        },
        categorySelection: [],
        popup: false,
        unlinkResponse: null,
        lastScanWarning: null,
        passOnCategories: true,
        response: null
    };

    static reducer(state = RentalIssueItemsReducer.initialState, action) {

        switch (action.type) {

            case RentalIssueItemsAction.SCAN_CODE :

                return {
                    ...state
                };

            case RentalIssueItemsAction.SCAN_CODE + '_FINISHED':


                // If is scanned and item is already in items
                if (action.payload?.scanned && !!state.items.find((item) => item.id === action.payload.item.id)) {
                    return {
                        ...state,
                        scannedItem: null
                    }
                }

                return {
                    ...state,
                    scannedItem: action.payload?.item
                };


            case RentalIssueItemsAction.VALIDATE_AND_SET_ITEM :

                return {
                    ...state,
                };

            case RentalIssueItemsAction.VALIDATE_AND_SET_ITEM + '_FINISHED':

                if (action.payload) {

                    if (action.payload.item) {

                        if (!state.items.some(item => item.id === action.payload.item.id)) {

                            return {
                                ...state,
                                items: [
                                    ...state.items,
                                    action.payload.item
                                ],
                                lastScanWarning: action.payload.lastScanWarning
                            };


                        } else {

                            if (action.payload.removal) {

                                const removalItem = state.items.find((item) => {
                                    return item.id === action.payload.item.id;
                                })

                                if (removalItem.valid.status.code !== 'locked') {

                                    state.items.slice(0, removalItem)

                                    return {
                                        ...state,
                                        items: state.items.filter(item => item.id !== action.payload.item.id),
                                        lastScanWarning: null
                                    };
                                } else {
                                    return {
                                        ...state,
                                        lastScanWarning: null,
                                    }
                                }

                            } else {
                                return {
                                    ...state,
                                    items: state.items.map(item => {
                                        if (item.id === action.payload.item.id) {
                                            return action.payload.item;
                                        } else {
                                            return item
                                        }
                                    })
                                }
                            }


                        }
                    } else {
                        return {
                            ...state,
                            lastScanWarning: action.payload.lastScanWarning
                        };
                    }
                } else return state

            case RentalIssueItemsAction.LOAD_STATUS_FROM_ITEMS + '_FINISHED':


                return {
                    ...state,
                    status: action.payload
                };


            case RentalIssueItemsAction.SUBMIT :

                return {
                    ...state,
                };

            case RentalIssueItemsAction.SUBMIT + '_FINISHED':
                return {
                    ...state,
                    response: action.payload
                };

            case RentalIssueItemsAction.LOAD_FROM_RENTAL :

                return {
                    ...state,
                };

            case RentalIssueItemsAction.LOAD_FROM_RENTAL + '_FINISHED':
                return {
                    ...state,
                    items: action.payload
                };

            case RentalIssueItemsAction.UNLINK_ITEM :
                return {
                    ...state,
                };

            case RentalIssueItemsAction.UNLINK_ITEM + '_FINISHED':
                return {
                    ...state,
                    unlinkItemResponse: action.payload
                };

            case RentalIssueItemsAction.LOAD_INITIAL_CATEGORY_SELECTION :

                return {
                    ...state,
                };

            case RentalIssueItemsAction.LOAD_INITIAL_CATEGORY_SELECTION + '_FINISHED':
                return {
                    ...state,
                    categorySelection: action.payload
                };


            case RentalIssueItemsAction.MUTATE_CATEGORY_SELECTION + '_FINISHED':

                return {
                    ...state,
                    categorySelection: [
                        ...state.categorySelection,
                    ]
                };


            case RentalIssueItemsAction.CHANGE_REMARKS + '_FINISHED':
                return {
                    ...state,
                    remarks: {
                        ...state.remarks,
                        value: action.payload
                    }
                };

            case RentalIssueItemsAction.TOGGLE_REMARKS + '_FINISHED':
                return {
                    ...state,
                    remarks: {
                        ...state.remarks,
                        show: action.payload.show,
                        value: action.payload.defaultRemarks
                    }
                };

            case RentalIssueItemsAction.TOGGLE_POPUP + '_FINISHED':
                return {
                    ...state,
                    popup: !state.popup
                };

            case RentalIssueItemsAction.TOGGLE_PASS_ON_CATEGORIES + '_FINISHED':
                return {
                    ...state,
                    passOnCategories: action.payload
                };

            case RentalIssueItemsAction.RESET + '_FINISHED':
                return {
                    ...RentalIssueItemsReducer.initialState,
                };


            default:
                return state
        }

    }

}
import {useAppAxios} from "../../../../app/hooks";
import {apiEnum} from "../../../enums/apiEnum";

export const apiResetPassword = async (data) => {
    return await useAppAxios.post(apiEnum.PARTNER + '/location/reset-password?resetHash=' + data.hash, {
        new_password: data.password,
        new_password_repeat: data.repeatedPassword
    }, {}).then(function (response) {
        switch (response.status) {
            case(200):
                return {
                    data: {
                        status: {
                            code: 200
                        },
                        message: "Password successfully reset!"
                    }
                }
            case(404):
                return {
                    data: {
                        status: {
                            code: 500
                        },
                        message: "Er heeft zich een onbekende fout voorgedaan. Probeer het later opnieuw."
                    }
                }
            default:
                return {
                    data: {
                        status: {
                            code: 500
                        },
                        message: "Er heeft zich een onbekende fout voorgedaan. Probeer het later opnieuw."
                    }
                }
        }
    }).catch(function (error) {
        console.log(error)
        return {
            data: {
                status: {
                    code: 500
                },
                message: "Er heeft zich een onbekende fout voorgedaan. Probeer het later opnieuw."
            }
        }
    })
}

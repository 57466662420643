import ErrorAction from "../../stores/error/ErrorAction";
import rootStore from "../../stores/rootStore";
import rootReducer from "../../stores/rootReducer";
import {createMemoryHistory} from "history";
import {getLanguageCode} from "../../createSlice/common/helpers/utils/getLanguageCode";
import {useAppAxios} from "../../createSlice/app/hooks";
import {apiEnum} from "../../createSlice/common/enums/apiEnum";

let store
export const injectStoreFetch = _store => {
    store = _store
}

const fetchHelper = async (values, dispatch) => {

    const language = getLanguageCode()

    if (values.fetchUrl.includes('?')) {
        values.fetchUrl += '&language=' + language
    } else {
        values.fetchUrl += '?language=' + language
    }

    if (values.method === 'post') {
        return await useAppAxios.post(values.fetchUrl, values.params, {
            skipCamel: true
        }).then(function (response) {
            return response?.data
        })

    } else {
        return await useAppAxios.get(values.fetchUrl, {
            params: values.params,
            skipCamel: true
        }).then(function (response) {
            if (response.data.status.code === 200) {
                dispatch(ErrorAction.unsetError())
                return response?.data
            }
            if (response?.data.status.code === 400) {
                dispatch(ErrorAction.unsetError())
                return response?.data
            }
        })

    }

}

export default fetchHelper

// Dependencies
import React from 'react'
import {connect} from "react-redux";
import moment from 'moment'

// Actions
import RentalIssueItemsAction from "../../stores/rental/issueItems/RentalIssueItemsAction";
import RentalTotalsAction from "../../stores/rental/totals/RentalTotalsAction";
import RentalDetailAction from "../../stores/rental/detail/RentalDetailAction";
import RentalCreateAction from "../../stores/rental/create/RentalCreateAction";

// Components
import RentalForm from '../../common/components/Rental/RentalForm'
import Bicycles from "../../common/components/Rental/RentalBicycles";
import Accessoires from "../../common/components/Rental/RentalAccessoires";
import RentalOptions from "../../common/components/Rental/RentalOptions";
import Delivery from "../../common/components/Rental/RentalAccommodation";
import RentalTotals from "../../common/components/Rental/RentalTotals";
import RentalCreateIssueItems from "../../common/components/Rental/RentalCreateIssueItems";
import RentalIssueItemsErrors from "./components/RentalIssueItemsErrors";

// Constants
import RouteEnum from "../../common/constants/RouteEnum";

// Dispatchers
import RentalDispatcher from "../../common/dispatchers/RentalDispatcher";
import RentalInsurance from "../../common/components/Rental/RentalInsurance";
import DisplayModuleByEnvironment from "../../common/helpers/DisplayModuleByEnvironment";
import CheckDisplayModule from "../../common/helpers/CheckDisplayModule";
import RentalPeriodAction from "../../stores/rental/period/RentalPeriodAction";
import RentalDeliveryAction from "../../stores/rental/delivery/RentalDeliveryAction";
import {Notification} from "../../createSlice/common/components/Notification";
import {RentalPeriod} from "../../createSlice/common/components/RentalPeriod";
import translate from "../../createSlice/common/helpers/utils/translate";
import {PinValidationWrapper} from "../../common/wrappers/PinValidationWrapper";

const mapStateToProps = (state) => ({
    ...state.rentalPeriod,
    ...state.rentalCustomerData,
    ...state.rentalItems,
    ...state.rentalDelivery,
    ...state.rentalTotals,
    ...state.rentalCreate,
    partnerLocation: state.partnerLocation,
    insurances: state.insurances,
    rentalIssue: state.rentalIssue,
    environment: state.environment,
    partnerUser: state.authentication.partnerUser
});


class RentalCreate extends React.Component {


    componentDidMount() {
        this._init()
    }

    _init() {
        this.props.resetAll()
        this.props.dispatch(RentalPeriodAction.loadEnvironmentSettings())
        this.props.dispatch(RentalTotalsAction.setDeposit(!!this.props.environment.data.info.enable_deposit))
    }

    render() {

        let buttonClass = 'btn btn--rounded btn--primary'

        if (this.props.isSubmitting) {
            buttonClass += ' is-disabled'
        }

        if (CheckDisplayModule('rentalCreate', this.props.environment, this.props.partnerUser)) {
            return (
                <div className="o-container o-container--primary">

                    <header className="c-subject-header c-subject-header--split">
                        <h1>{translate('newRental')}</h1>
                        <div className="c-subject-header__buttons">
                            <button className="btn btn--small btn--outline" onClick={() => this._cancel()}>
                                <span>{translate('cancel')}</span>
                            </button>
                        </div>
                    </header>
                    <div className="s-order-detail">
                        <div className="c-info-blocks c-form">

                            {!!this.props.resetMessage && <Notification type={'error'} text={this.props.resetMessage}/>}

                            <PinValidationWrapper>

                                <RentalPeriod
                                    setPeriod={(period) => this.props.dispatch(RentalPeriodAction.set(period)).then(() => {
                                        this.props.dispatch(RentalIssueItemsAction.reset()).then(() => {
                                            this.props.dispatch(RentalTotalsAction.requestTotals(true, true))
                                        })
                                    })}
                                    partnerEnvironment={this.props.environment}
                                />

                                {this._showAccommodation() && <Delivery defaultAddress={true}/>}
                                {this._showForm() && <RentalForm create={true}/>}
                                {this._showBicycles() && <Bicycles/>}
                                {this._showAccessoires() && <Accessoires/>}
                                {this._showIssueItems() && <RentalCreateIssueItems/>}
                                {this._showOptions() && <RentalOptions create={true}/>}
                                {this._showInsurance() && <RentalInsurance/>}
                                {this._showTotals() && <RentalTotals create={true} data={this.props.totals}/>}

                                {this._showSubmit() &&
                                    <div className="c-form__row">
                                        <div className="c-form__group">
                                            <button onClick={() => this._onSubmit()}
                                                    type="submit" className={buttonClass}>
                                                <span>{translate('agreeAndGoToPayment')}</span>
                                            </button>
                                        </div>
                                    </div>
                                }

                                <RentalIssueItemsErrors/>

                            </PinValidationWrapper>

                        </div>
                    </div>
                </div>
            )
        } else return null
    }

    _cancel = () => {
        const {history} = this.props
        history.push('/' + this.props.environment.slug + RouteEnum.Dashboard)

    }

    _showAccommodation = () => {
        return this.props.period.startDate
            && this.props.period.endDate
            && !!this.props.period.amount
    }

    _showForm = () => {
        return this._showAccommodation()
            && this.props.delivery.completed
    }

    _showBicycles = () => {
        return this._showAccommodation()
            && this._showForm() && this.props.form.completed
            && (this.props.period.startDate !== moment().format('YYYY-MM-DD') || this.props.environment?.data?.type === 'fun_and_entertainment')
    }

    _showAccessoires = () => {
        return this._showAccommodation()
            && this._showForm() && this.props.form.completed
            && (this.props.period.startDate !== moment().format('YYYY-MM-DD') || this.props.environment?.data?.type === 'fun_and_entertainment')
    }

    _showIssueItems = () => {
        return this._showAccommodation()
            && this._showForm() && this.props.form.completed
            && this.props.period.startDate === moment().format('YYYY-MM-DD')
            && this.props.environment?.data?.type === 'rental'
    }

    _showOptions = () => {
        return this._showAccommodation()
            && this._showForm()
            && (this._showBicycles() || this._showIssueItems())
            && (this.props.shoppingCart.bicycles.length > 0 ||
                this.props.shoppingCart.accessoires.length > 0 ||
                this.props.rentalIssue.items.length > 0
            )
    }

    _showInsurance = () => {
        return this._showAccommodation()
            && this._showForm()
            && (this._showBicycles() || this._showIssueItems())
            && (this.props.shoppingCart.bicycles.length > 0 ||
                this.props.shoppingCart.accessoires.length > 0 ||
                this.props.rentalIssue.items.length > 0
            )
    }

    _showTotals = () => {
        return this._showAccommodation()
            && this._showForm()
            && (this._showBicycles() || this._showIssueItems())
            && (this._showInsurance() && this.props.insurances.selected !== null)
            && (this.props.shoppingCart.bicycles.length > 0 ||
                this.props.shoppingCart.accessoires.length > 0 ||
                this.props.rentalIssue.items.length > 0
            )
    }

    _showSubmit = () => {
        return this._showAccommodation()
            && this._showForm()
            && (this._showBicycles() || this._showIssueItems())
            && (this._showInsurance() && this.props.insurances.selected !== null)
            && (this.props.shoppingCart.bicycles.length > 0 ||
                this.props.shoppingCart.accessoires.length > 0 ||
                this.props.rentalIssue.items.length > 0)
    }

    _onSubmit = () => {

        this.props.dispatch(RentalDetailAction.setLoader(true))

        if (this.props.response) {

            const uuid = this.props.response.items.rental.uuid

            this.props.dispatch(RentalCreateAction.submit(uuid, true)).then(() => {

                const slug = this.props.environment.slug

                if (this.props.period.startDate === moment().format('YYYY-MM-DD') && this.props.environment?.data?.type === 'rental') {

                    this.props.dispatch(RentalIssueItemsAction.submit(true, uuid, true, true)).then(() => {

                        if (this.props.rentalIssue.response && this.props.rentalIssue.response.status.code === 200) {
                            this.props.dispatch(RentalTotalsAction.submitDeposit(uuid)).then(() => {
                                this.props.history.push('/' + slug + '/dashboard/rental/transaction-check/' + this.props.response.items.rental.uuid)
                                this.props.dispatch(RentalDetailAction.setLoader(false))
                            })
                        } else {
                            this.props.dispatch(RentalIssueItemsAction.togglePopup())
                            this.props.dispatch(RentalDetailAction.setLoader(false))
                        }
                    })

                } else {
                    this.props.dispatch(RentalTotalsAction.submitDeposit(uuid)).then(() => {
                        this.props.history.push('/' + slug + '/dashboard/rental/transaction-check/' + uuid)
                        this.props.dispatch(RentalDetailAction.setLoader(false))
                    })
                }


            })
        }
    }

}

export default connect(mapStateToProps, RentalDispatcher)(RentalCreate);

const formatterEUR = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR'
})

const formatterDKK = new Intl.NumberFormat('da-DK', {
    style: 'currency',
    currency: 'DKK'
})


const DisplayPrice = (number) => {

    let denmarkSlugCheck = 'denmark'

    if (window.location.href.indexOf("/3/") > -1 && window.location.href.indexOf(denmarkSlugCheck) > -1) {
        return formatterDKK.format(parseFloat(number))
    }else{
        return formatterEUR.format(parseFloat(number))
    }

}

export default DisplayPrice




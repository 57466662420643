import translate from "../../createSlice/common/helpers/utils/translate";

const DashboardDisplayOptions = () => {
    return [
        {
            type: "reservation",
            label: translate("reservations"),
            show: true,
        },
        {
            type: "return",
            label: translate("returns"),
            show: true,
        },
        {
            type: "registration",
            label: translate("preRegisters"),
            show: true,
        },
        {
            type: "items",
            label: translate("itemsGive"),
            show: true,
        },
        {
            type: "items-return",
            label: translate("itemsTake"),
            show: true,
        },
        {
            type: "late",
            label: translate("tooLate"),
            show: true,
        },
    ];
};

export default DashboardDisplayOptions;

import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {apiPartnerItemUnlinkRental} from "../../../common/api/partner/item/apiPartnerItemUnlinkRental";
import {apiPartnerItemUpdateStatus} from "../../../common/api/partner/item/apiPartnerItemUpdateStatus";
import {apiPartnerItemReturn} from "../../../common/api/partner/item/apiPartnerItemReturn";
import {apiPartnerUserTwoFactorCode} from "../../../common/api/partner/user/apiPartnerUserTwoFactorCode";

export const submitTwoFactor = createAsyncThunk(
    'twoFactorAuthModal/submitTwoFactor',
    async (_, {getState}) => {
        const {twoFactorAuthModal} = getState()
        return apiPartnerUserTwoFactorCode(twoFactorAuthModal.twoFactorCode)
    }
)
const initialState = {
    active: false,
    submitting: false,
    submitted: false,
    twoFactorCode: '',
    error: false
}

export const twoFactorAuthModalSlice = createSlice({
    name: 'twoFactorAuthModal',
    initialState,
    reducers: {
        setTwoFactorCode: (state, action) => {
            state.twoFactorCode = action.payload
        },
        activate2faModal: (state) => {
            state.active = true
        },
    },
    extraReducers: (builder) => {
        builder.addCase(submitTwoFactor.pending, (state) => {
            state.submitting = true
            state.submitted = false
            state.error = false
        })
        builder.addCase(submitTwoFactor.fulfilled, (state, action) => {
            state.submitting = false

            if (action.payload.data.status === 400) {
                state.error = 'Invalid code'
                state.submitted = false
                return
            }

            state.submitted = true
            state.active = false
        })
    },
})

export const {setTwoFactorCode, activate2faModal} = twoFactorAuthModalSlice.actions

export const twoFactorAuthModalReducer = twoFactorAuthModalSlice.reducer
export const actionGiveItem = (args) => {

    let {item, rentalGiveItems, occupiedItems, rentalItems, scanned, serviceItems} = args
    let categoryMutationQuantity = 0

    rentalGiveItems = [...rentalGiveItems]
    occupiedItems = [...occupiedItems]
    rentalItems = [...rentalItems]
    serviceItems = [...serviceItems]

    const setItemState = (itemState, changeMutationQuantity = false) => {


        // Remove if already inside <-> else add to array.
        if (itemState?.find(giveItem => giveItem.id === item.id)) {

            if (!scanned) { // If item is scanned -> dont remove

                if (changeMutationQuantity) {
                    categoryMutationQuantity--
                }

                itemState = itemState.filter((stateItem) => stateItem.id !== item.id)
            }

        } else {

            if (changeMutationQuantity) {
                categoryMutationQuantity++
            }

            itemState.push(item)

        }
        return itemState
    }

    // Only allow to scan item if the item isn't already part of the rental
    if (!rentalItems.find((rentalItem) => rentalItem.id === item.id)) {

        const inService = item.rentalStatus.code === 'service'

        if (!item.rental && !inService) {

            rentalGiveItems = setItemState(rentalGiveItems, true)

            // Remove possible occupied item from the occupied list after unlink mutation
            occupiedItems = occupiedItems.filter((occupiedItem) => {
                return occupiedItem.id !== item.id
            })

            serviceItems = serviceItems.filter((serviceItem) => {
                return serviceItem.id !== item.id
            })


        } else {

            if (item.rental) {
                occupiedItems = setItemState(occupiedItems)
            }

            if(inService) {
                serviceItems = setItemState(serviceItems)
            }

        }

    }

    return {
        rentalGiveItems: rentalGiveItems,
        occupiedItems: occupiedItems,
        serviceItems: serviceItems,
        categoryMutationQuantity: categoryMutationQuantity
    }

}
import {apiEnum} from "../../../enums/apiEnum";
import {useAppAxios} from "../../../../app/hooks";
import {decamelizeKeys} from "humps";


export const apiPartnerRentalList = async (
    params, body) => {
    return await useAppAxios.post(apiEnum.PARTNER + '/rental/list', decamelizeKeys(body)
        ,
        {
            params: params
        }).then(function (response) {
        return {
            ...response,
            data: {
                ...response.data,
                items: {
                    ...response.data.items,
                    data: Object.values(response.data.items.data)
                }
            }
        }
    }).catch(function (error) {
        console.log(error)
        return {
            data: {
                status: {
                    code: 500
                }
            }
        }
    })
}

import ActionUtility from "../../../common/helpers/ActionUtility";
import InsurancesEffect from "./InsurancesEffect";
import ApiEnum from "../../../common/constants/ApiEnum";
import moment from "moment";
import MapIssuesToCategories from "../../../common/helpers/MapIssuesToCategories";

export default class InsurancesAction {

    static REQUEST_LIST = 'InsurancesAction.REQUEST_LIST';
    static SET_INSURANCE = 'InsurancesAction.SET_INSURANCE';

    static list(rentalContainer = false) {

        console.log(rentalContainer)

        return async (dispatch, getState) => {

            if (rentalContainer) {

                const values = {
                    fetchUrl: ApiEnum.Api + '/insurance/list',
                    method: 'post',
                    accessToken: getState().authentication.accessToken,
                    params: {
                        auth_token: localStorage.getItem('authToken'),
                        startDate: rentalContainer.rental.dateRentStart,
                        endDate: rentalContainer.rental.dateRentEnd,
                        timeUnitId: rentalContainer.timeUnit.id,
                    }
                }

                values.params.categories = rentalContainer.rentalItems.filter((rentalItem) => rentalItem.type === 'category')
                    .map((rentalItem) => {
                        return {
                            id: rentalItem.categoryId,
                            quantity: rentalItem.quantity
                        }
                    })

                await ActionUtility.createThunkEffect(dispatch, InsurancesAction.REQUEST_LIST, InsurancesEffect.list, values);

            } else {
                const values = {
                    fetchUrl: ApiEnum.Api + '/insurance/list',
                    method: 'post',
                    accessToken: getState().authentication.accessToken,
                    params: {
                        auth_token: localStorage.getItem('authToken'),
                        startDate: moment(getState().rentalPeriod.period.startDate).format('YYYY-MM-DD') + ' ' + getState().rentalPeriod.period.startTime,
                        endDate: moment(getState().rentalPeriod.period.endDate).format('YYYY-MM-DD') + ' ' + getState().rentalPeriod.period.endTime,
                        timeUnitId: getState().rentalPeriod.period.chosenUnit || getState().rentalDetail.data.data.time_unit.id,
                    }
                }

                if (getState().rentalPeriod.period.startDate === moment().format('YYYY-MM-DD') && getState().environment?.data?.type === 'rental') {
                    values.params.categories = MapIssuesToCategories(getState().rentalIssue.items)
                } else {
                    values.params.categories = Object.values(getState().rentalItems.shoppingCart.bicycles).concat(Object.values(getState().rentalItems.shoppingCart.accessoires))
                }

                // Just map the IDS (disc.. for this endpoint..)
                values.params.categories = values.params.categories.map((category) => {
                    return {id: category.id, quantity: category.quantity}
                })

                // In case an extension is in place. Submit that as last date
                if (getState().rentalExtend.newDate) {
                    values.params.endDate = moment(getState().rentalExtend.newDate).format('YYYY-MM-DD')
                }

                await ActionUtility.createThunkEffect(dispatch, InsurancesAction.REQUEST_LIST, InsurancesEffect.list, values);
            }
        }
    }

    static setInsurance(value) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, InsurancesAction.SET_INSURANCE, InsurancesEffect.setInsurance, value);
        };
    }

}

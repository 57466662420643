import ApiEnum from "../../../common/constants/ApiEnum";
import ErrorAction from "../../error/ErrorAction";
import fetchHelper from "../../../common/helpers/fetchHelper";

export default class RentalReturnItemsEffect {

    static async scanCode(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        if (response.status.code === 200) {

            dispatch(ErrorAction.unsetError())

            const item = response.items.item

            if (item) {
                return {
                    item: item,
                    scanned: values.scanned
                }
            } else return null

        } else {
            dispatch(ErrorAction.setError(response))
            return null
        }
    }


    static async validateItem(values, dispatch) {

        const item = values.item

        if (item) {

            values.fetchUrl = ApiEnum.Api + '/item/unlink-rental-valid?id=' + item.id + '&rental=' + values.uuid
            values.method = 'post'

            const response = await fetchHelper(values, dispatch)

            if (response.status.code === 200) {
                dispatch(ErrorAction.unsetError())

                item.valid = response
                return item

            } else {
                dispatch(ErrorAction.setError(response))
                return null
            }

        } else {
            return null
        }
    }

    static loadStatusFromItems(values) {

        let status = {
            correctCount: 0,
            errors: [],
            completed: false
        }


        if (values.returnItems.length) {

            values.returnItems.forEach((item) => {

                if (item.valid.items && item.valid.items.errors && item.valid.items.errors.length) {
                    item.valid.items.errors.forEach(error => {
                        status.errors.push(error)
                    })
                }

                values.issuedItems.forEach((issuedItem) => {

                    if (issuedItem.id === item.id) {
                        status.correctCount = status.correctCount + 1
                    }
                })

            })

            if (values.issuedItems.length === status.correctCount) {
                status.completed = true
            }


        }

        return status

    }

    static async submit(values, dispatch) {


        const response = await fetchHelper(values.unlinkRental, dispatch)

        if (response) {

            const response = await fetchHelper(values.linkLocation, dispatch)

            if (response) {

                return response

            } else return null

        } else return null

    }

    static toggleRemarks(values) {
        return values;
    }

    static changeRemarks(value) {
        return value;
    }

    static togglePopup() {
        return true;
    }

    static reset() {
        return true;
    }

    static async loadFromRental(values, dispatch) {

        values.fetchUrl = ApiEnum.Api + '/item/list?rental=' + values.uuid
        values.method = 'post'

        const response = await fetchHelper(values, dispatch)

        if (response.status.code === 200) {

            dispatch(ErrorAction.unsetError())

            if (response.items && response.items.data) {

                return response.items.data.map(item => {
                    return {
                        ...item,
                        locked: true,
                        valid: {
                            status: {
                                code: 'locked'
                            }
                        }
                    }
                })

            } else {
                return []
            }
        } else {
            dispatch(ErrorAction.setError(response))
            return []
        }
    }
}
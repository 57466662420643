// Dependencies
import React from 'react'
import {connect} from "react-redux";

// Actions
import EnvironmentAction from "../../stores/environment/EnvironmentAction";
import TransactionAction from "../../stores/transaction/TransactionAction";
import RentalTotalsAction from "../../stores/rental/totals/RentalTotalsAction";
import PaymentAction from "../../stores/payment/PaymentAction";

// Components
import RentalTotals from "../../common/components/Rental/RentalTotals";
import TinyLoader from "../../common/components/General/TinyLoader";
import RentalInfoBar from "../RentalDetail/components/RentalInfoBar";
import QrPopup from "./components/qrPopup";

// Dispatchers
import RentalDispatcher from "../../common/dispatchers/RentalDispatcher";

// Helpers
import IsCurrentPartnerLocation from "../../common/helpers/IsCurrentPartnerLocation";
import DisplayPrice from "../../common/helpers/DisplayEuro";

// Constants
import RouteEnum from "../../common/constants/RouteEnum";
import ToTransactionCheck from "../../common/helpers/ToTransactionCheck";
import Scanner from "../../common/components/General/Scanner";
import translate from "../../createSlice/common/helpers/utils/translate";


const mapStateToProps = (state) => ({
    transaction: state.transaction.detail,
    rentalDetail: state.rentalDetail.data,
    rentalTotals: state.rentalTotals,
    payment: state.payment,
    environment: state.environment
});

class Transaction extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            selectedMethod: null,
            autoRefundEnabled: false,
            selectedStatus: null,
            selectedStatusDeposit: null,
            selectedStatusReturnDeposit: null,
            selectedStatusInsurance: null,
            partialAmount: null,
            partialAmountDeposit: null,
            partialAmountInsurance: null,
            showVenditPopup: null
        }


    }

    componentDidMount() {
        this.props.dispatch(PaymentAction.requestMethodList(this.props.match.params.uuid));
        this.props.dispatch(PaymentAction.requestStatusList());

        this.props.dispatch(EnvironmentAction.setLoader(true)).then(() => {

                if (this.props.match) {

                    let rentalUuid = this.props.match.params.uuid
                    let id = this.props.match.params.transactionId

                    this.props.loadMin(rentalUuid)

                    this.props.dispatch(RentalTotalsAction.requestTotals()).then(() => {
                        this.props.dispatch(TransactionAction.requestDetail({
                            rental: rentalUuid,
                            id: id
                        })).then(() => {
                            this.props.dispatch(EnvironmentAction.setLoader(false))
                        })

                    })
                }
            }
        )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.transaction?.id && (this.props.transaction !== prevProps.transaction || prevProps.payment.methodList !== this.props.payment.methodList)) {
            const defaultPaymentMethod = this.props.payment.methodList?.find((paymentMethod) => !!paymentMethod.default)
            if (defaultPaymentMethod) {
                // this.props.dispatch(TransactionAction.updatePaymentMethod({
                //     rental: this.props.match.params.uuid,
                //     id: this.props.transaction?.id,
                //     payment_method: defaultPaymentMethod.code
                // })).then(() => {
                    this.setState({
                        selectedMethod: defaultPaymentMethod.code,
                        autoRefundEnabled: defaultPaymentMethod.is_automatic_refund ?? false
                    })
                // })
            }

        }

        if (prevState.selectedMethod !== this.state.selectedMethod) {
            this.setState({
                showVenditPopup: this.state.selectedMethod === 'vendit'
            })
        }

        if (this.props.rentalTotals.totals && prevProps.rentalTotals.totals) {

            if (this.props.rentalTotals.totals.data.totalPrice !== prevProps.rentalTotals.totals.data.totalPrice) {
                ToTransactionCheck(this.props)
            }
        }


        if (prevProps.match.params.transactionId !== this.props.match.params.transactionId) {
            const isCurrentPartnerLocation = IsCurrentPartnerLocation(this.props)

            if (!isCurrentPartnerLocation) {
                const {history} = this.props
                history.push('/' + this.props.environment.slug + RouteEnum.RentalDetail + '/' + this.props.rentalDetail.data.rental.uuid)
            }

            this.props.dispatch(EnvironmentAction.setLoader(true)).then(() => {

                    if (this.props.match) {

                        let rentalUuid = this.props.match.params.uuid
                        let id = this.props.match.params.transactionId

                        this.props.loadMin(rentalUuid)

                        this.props.dispatch(RentalTotalsAction.requestTotals()).then(() => {
                            this.props.dispatch(TransactionAction.requestDetail({
                                rental: rentalUuid,
                                id: id
                            })).then(() => {
                                this.props.dispatch(EnvironmentAction.setLoader(false))
                            })

                        })
                    }
                }
            )
        }


    }

    render() {

        const paymentData = this.props.payment

        const methodList = paymentData.methodList
        const statusList = paymentData.statusList


        if (statusList && this.props.transaction && this.props.rentalDetail) {
            return (
                <main>

                    <Scanner type={'rental'}/>

                    <div className="o-container o-container--primary">
                        <header className="c-subject-header c-subject-header--split">
                            <h1>{translate('payment')}</h1>
                        </header>

                        <RentalInfoBar/>


                        <div className="s-order-detail">

                            <RentalTotals
                                data={this.props.rentalTotals.totals}
                                overrideItems={this.props.rentalDetail.RentalItems}
                                hideInput={false}
                            />

                            <div className="c-info-blocks__row">
                                <div className="c-info-blocks__single">
                                    <header className="c-subject-header c-subject-header--closed">
                                        <h2>{translate('paymentOptions')}</h2>
                                    </header>
                                    <div className="c-info-blocks__single__body">
                                        <div className="c-form__row">
                                            <div
                                                className="c-form__group c-form__group--radio c-form__group--radio-label">
                                                <div>
                                                    {!!methodList && methodList.map((paymentMethod) => {

                                                        let checked = false
                                                        const isAutomaticRefund = paymentMethod.is_automatic_refund ?? false

                                                        if (this.state.selectedMethod === paymentMethod.code) {
                                                            checked = true
                                                        }

                                                        return (
                                                            <label htmlFor={paymentMethod.code}
                                                                   key={'payment-' + paymentMethod.code}>
                                                                <input data-hj-allow type="radio" value={paymentMethod.code}
                                                                       checked={checked}
                                                                       id={paymentMethod.code} name="payment[]"
                                                                       onChange={(e) => this._changePaymentMethod(e, isAutomaticRefund)}
                                                                />
                                                                {/*<span>{!isAutomaticRefund ? paymentMethod.name : translate('automated-refunding')}</span>*/}
                                                                <span>{!isAutomaticRefund ? paymentMethod.name : translate('automated-refunding')}</span>
                                                            </label>
                                                        )
                                                    })
                                                    }
                                                    {!methodList &&
                                                    <p>{translate('there-are-no-payment-methods-available')}</p>}
                                                </div>


                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>


                            {this.state.selectedMethod && this.state.selectedMethod !== 'vendit' &&
                            <div className="c-info-blocks__row">
                                <div className="c-info-blocks__single">
                                    <header className="c-subject-header c-subject-header--closed">
                                        <h2>{translate('paymentStatus')}</h2>
                                    </header>
                                    <div className="c-info-blocks__single__body">
                                        <div className="c-form__row">

                                            {!!parseFloat(this.props.transaction.total + '') > 0 &&

                                                <div
                                                    className="c-form__group c-form__group--radio c-form__group--radio-label">

                                                    <p>{translate('amount-payable')}: {DisplayPrice(this.props.transaction.total)}</p>

                                                    <div>
                                                        {statusList.map((paymentStatus) => {

                                                            let checked = false
                                                            if (this.state.selectedStatus === paymentStatus.code) {
                                                                checked = true
                                                            }

                                                            return (
                                                                <label htmlFor={paymentStatus.code}
                                                                       key={'paymentmethod-' + paymentStatus.code}>
                                                                    <input data-hj-allow type="radio"
                                                                           value={paymentStatus.code}
                                                                           checked={checked}
                                                                           id={paymentStatus.code} name="status[]"
                                                                           onChange={(e) => this._changePaymentStatus(e)}
                                                                    />
                                                                    <span>{paymentStatus.name}</span>
                                                                </label>
                                                            )
                                                        })}
                                                    </div>

                                                    {this.state.selectedStatus === 'partial' &&

                                                        <label htmlFor={'partial-amount'}
                                                               className={'c-partial-amount'}>
                                                            <p>{translate('paid')}:</p>
                                                            <input data-hj-allow type="number"
                                                                   value={this.state.partialAmount}
                                                                   id={'partial-amount'} name="status[]"
                                                                   onChange={(e) => this._changePartialAmount(e)}

                                                            />

                                                        </label>


                                                    }


                                                </div>
                                            }


                                            {!!parseFloat(this.props.transaction.deposit + '') > 0 &&

                                            <div className="c-form__group c-form__group--radio c-form__group--radio-label">

                                                <p>{translate('security-deposit-to-be-paid')}: {DisplayPrice(this.props.transaction.deposit)}</p>

                                                <div>
                                                    {statusList.map((paymentStatus) => {

                                                        let checked = false
                                                        if (this.state.selectedStatusDeposit === paymentStatus.code) {
                                                            checked = true
                                                        }

                                                        return (

                                                            <label htmlFor={paymentStatus.code + '-deposit'}
                                                                   key={'paymentstatus-' + paymentStatus.code}>
                                                                <input data-hj-allow type="radio" value={paymentStatus.code}

                                                                       checked={checked}
                                                                       id={paymentStatus.code + '-deposit'}
                                                                       name="statusDeposit[]"
                                                                       onChange={(e) => this._changePaymentStatusDeposit(e)}
                                                                />
                                                                <span>{paymentStatus.name}</span>
                                                            </label>
                                                        )
                                                    })}
                                                </div>

                                                {this.state.selectedStatusDeposit === 'partial' &&

                                                <label htmlFor={'partial-amount'} className={'c-partial-amount'}>
                                                    <p>{translate('paid')}:</p>
                                                    <input data-hj-allow type="number"
                                                           value={this.state.partialAmountDeposit}
                                                           id={'partial-amount'} name="statusDeposit[]"
                                                           onChange={(e) => this._changePartialAmountDeposit(e)}

                                                    />

                                                </label>

                                                }

                                            </div>
                                            }

                                            {parseFloat(this.props.transaction.deposit_returned + '') < 0 &&

                                                this.props.transaction.status === 'pending' ?

                                            <div className="c-form__group c-form__group--radio c-form__group--radio-label">

                                                <p>{translate('deposit-to-be-returned')}: {DisplayPrice(this.props.transaction.deposit_returned)}</p>

                                                <div>
                                                    {statusList.filter(status => status.code !== 'partial').map((paymentStatus) => {

                                                        let checked = false
                                                        if (this.state.selectedStatusReturnDeposit === paymentStatus.code) {
                                                            checked = true
                                                        }

                                                        return (

                                                            <label htmlFor={paymentStatus.code + '-deposit'}
                                                                   key={'paymentstatus-' + paymentStatus.code}>
                                                                <input data-hj-allow type="radio" value={paymentStatus.code}

                                                                       checked={checked}
                                                                       id={paymentStatus.code + '-deposit'}
                                                                       name="statusDeposit[]"
                                                                       onChange={(e) => this._changePaymentStatusReturnDeposit(e)}
                                                                />
                                                                <span>{paymentStatus.name}</span>
                                                            </label>
                                                        )
                                                    })}
                                                </div>

                                                {this.state.selectedStatusDeposit === 'partial' &&

                                                <label htmlFor={'partial-amount'} className={'c-partial-amount'}>
                                                    <p>{translate('paid')}:</p>
                                                    <input data-hj-allow type="number"
                                                           value={this.state.partialAmountDeposit}
                                                           id={'partial-amount'} name="statusDeposit[]"
                                                           onChange={(e) => this._changePartialAmountDeposit(e)}

                                                    />

                                                </label>
                                                }

                                            </div>
                                                : this.props.transaction.status !== 'pending' && <p>{translate('transaction-not-pending')}</p>
                                            }

                                            {!!parseFloat(this.props.transaction.insurance + '') > 0  &&

                                            <div className="c-form__group c-form__group--radio c-form__group--radio-label">

                                                <p>{translate('insurance-to-be-paid')}: {DisplayPrice(this.props.transaction.insurance)}</p>

                                                <div>
                                                    {statusList.map((paymentStatus) => {

                                                        let checked = false
                                                        if (this.state.selectedStatusInsurance === paymentStatus.code) {
                                                            checked = true
                                                        }

                                                        return (

                                                            <label htmlFor={paymentStatus.code + '-insurance'}
                                                                   key={'paymentstatus-' + paymentStatus.code}>
                                                                <input data-hj-allow type="radio" value={paymentStatus.code}

                                                                       checked={checked}
                                                                       id={paymentStatus.code + '-insurance'}
                                                                       name="statusInsurance[]"
                                                                       onChange={(e) => this._changePaymentStatusInsurance(e)}
                                                                />
                                                                <span>{paymentStatus.name}</span>
                                                            </label>
                                                        )
                                                    })}
                                                </div>

                                                {this.state.selectedStatusInsurance === 'partial' &&

                                                <label htmlFor={'partial-amount'} className={'c-partial-amount'}>
                                                    <p>{translate('paid')}:</p>
                                                    <input data-hj-allow type="number"
                                                           value={this.state.partialAmountInsurance}
                                                           id={'partial-amount'} name="statusInsurance[]"
                                                           onChange={(e) => this._changePartialAmountInsurance(e)}

                                                    />

                                                </label>

                                                }

                                            </div>
                                            }

                                        </div>

                                    </div>
                                </div>
                            </div>
                            }


                            {this._showSubmit() &&
                            <button type="button" className="btn btn--rounded btn--primary"
                                    onClick={() => this._submit()}>
                                <span>{translate('process')}</span>
                            </button>
                            }

                        </div>

                    </div>


                    {!!this.state.showVenditPopup &&
                    <QrPopup close={() => this._closeVenditPopup()} qrUrl={this.props.rentalDetail.data.qr_url}
                             qrSourceUrl={this.props.rentalDetail.data.qr_source_url}/>
                    }

                </main>
            )
        } else {

            return <TinyLoader/>

        }
    }

    _changePaymentMethod = (e, isAutomaticRefund) => {

        const value = e.target.value

        this.setState({
            selectedMethod: value,
            autoRefundEnabled: isAutomaticRefund
        })
    }

    _changePartialAmount = (e) => {
        this.setState({
            partialAmount: e.target.value
        })
    }

    _changePartialAmountDeposit = (e) => {
        this.setState({
            partialAmountDeposit: e.target.value
        })
    }

    _changePartialAmountInsurance = (e) => {
        this.setState({
            partialAmountInsurance: e.target.value
        })
    }

    _changePaymentStatus = (e) => {
        this.setState({
            selectedStatus: e.target.value
        })
    }

    _changePaymentStatusDeposit = (e) => {
        this.setState({
            selectedStatusDeposit: e.target.value
        })
    }

    _changePaymentStatusReturnDeposit = (e) => {
        console.log('e.target.value', e.target.value)
        this.setState({
            selectedStatusReturnDeposit: e.target.value,
            selectedStatus: e.target.value
        })
    }

    _changePaymentStatusInsurance = (e) => {
        this.setState({
            selectedStatusInsurance: e.target.value
        })
    }

    _showSubmit = () => {

        let shouldShow = true;

        if (!this.state.selectedMethod) {
            return false
        }

        if (this.state.selectedMethod === 'vendit') {
            return true
        }

        if (parseFloat(this.props.transaction.total + '') > 0) {
            let paid = false

            if (this.state.selectedStatus) {
                if (this.state.selectedStatus !== 'partial' || this.state.partialAmount) {
                    paid = true
                }
            }

            if (!paid) {
                shouldShow = false
            }
        }

        if (parseFloat(this.props.transaction.deposit + '') > 0) {
            let depositPaid = false

            if (this.state.selectedStatusDeposit) {
                if (this.state.selectedStatusDeposit !== 'partial' || this.state.partialAmountDeposit) {
                    depositPaid = true
                }
            }

            if (!depositPaid) {
                shouldShow = false
            }
        }

        if (parseFloat(this.props.transaction.insurance + '') > 0) {
            let insurancePaid = false

            if (this.state.selectedStatusInsurance) {
                if (this.state.selectedStatusInsurance !== 'partial' || this.state.partialAmountInsurance) {
                    insurancePaid = true
                }
            }

            if (!insurancePaid) {
                shouldShow = false
            }
        }

        return shouldShow

        // // If normal payment isnt partial and no deposit is there
        // if (this.state.selectedMethod && this.state.selectedStatus && this.state.selectedStatus !== 'partial' && !this.props.transaction.deposit) {
        //     return true
        //
        //     // If vendit (skip status)
        // } else if (this.state.selectedMethod && this.state.selectedMethod === 'vendit') {
        //     return true
        //
        //     // If normal payment and deposit arent partial
        // } else if (this.state.selectedMethod && this.state.selectedStatus && this.state.selectedStatus !== 'partial' && this.props.transaction.deposit && this.state.selectedStatusDeposit && this.state.selectedStatusDeposit !== 'partial') {
        //     return true
        //
        // } else if (this.state.selectedMethod && this.state.selectedStatus && this.state.selectedStatus === 'partial' && this.state.partialAmount && !this.props.transaction.deposit) {
        //     return true
        //
        //     // If they're both partial and both filled
        // } else if (this.state.selectedMethod && this.state.selectedStatus && this.state.selectedStatus === 'partial' && this.state.partialAmount
        //     && this.props.transaction.deposit && this.state.selectedStatusDeposit && this.state.selectedStatusDeposit === 'partial' && this.state.partialAmountDeposit) {
        //     return true
        //
        //     // If normal isnt partial, deposit is and filled
        // } else if (this.state.selectedMethod && this.state.selectedStatus && this.state.selectedStatus !== 'partial' && this.props.transaction.deposit && this.state.selectedStatusDeposit && this.state.selectedStatusDeposit === 'partial' && this.state.partialAmountDeposit) {
        //     return true
        //
        //     // If normal isnt partial, deposit is and filled
        // } else if (this.state.selectedMethod && this.state.selectedStatus && this.state.selectedStatus === 'partial' && this.state.partialAmount && this.props.transaction.deposit && this.state.selectedStatusDeposit && this.state.selectedStatusDeposit !== 'partial') {
        //     return true
        // } else if (this.props.transaction.deposit && this.state.selectedStatusDeposit) {
        //     return true
        // } else if (this.props.transaction.insurance && this.state.selectedStatusDeposit) {
        //     return true
        // } else {
        //     return false
        // }
    }


    _submit = () => {

        const {history} = this.props

        const values = {
            rental: this.props.match.params.uuid,
            id: this.props.transaction.id,
            status: this.state.selectedStatus,
            deposit_status: this.state.selectedStatusDeposit,
            insurance_status: this.state.selectedStatusInsurance,
            payment_method: this.state.selectedMethod,
            paid: this.state.partialAmount,
            deposit_paid: this.state.partialAmountDeposit,
            insurance_paid: this.state.partialAmountInsurance,
            price_to_pay: this.props.transaction.total,
            deposit_to_pay: this.props.transaction.deposit,
            insurance_to_pay: this.props.transaction.insurance,
            deposit_returned_status: this.state.selectedStatusReturnDeposit,
            deposit_returned: this.props.transaction.deposit_returned ?? ''
        }

        this.props.dispatch(EnvironmentAction.setLoader(true)).then(() => {

            this.props.dispatch(TransactionAction.updatePaymentMethod({
                rental: this.props.match.params.uuid,
                id: this.props.transaction.id,
                payment_method: this.state.selectedMethod
            }))

            if (this.state.selectedMethod !== 'vendit') {
                if (this.state.autoRefundEnabled) {
                    this.props.dispatch(TransactionAction.refundPayment(values)).then(() => {
                        this.props.dispatch(EnvironmentAction.setLoader(false)).then(() => {
                            history.push('/' + this.props.environment.slug + '/dashboard/rental/' + values.rental + '/transaction/' + values.id + '/status')
                        })
                    })
                }
                else{
                    this.props.dispatch(TransactionAction.partialPayment(values)).then(() => {
                        this.props.dispatch(EnvironmentAction.setLoader(false)).then(() => {
                            history.push('/' + this.props.environment.slug + '/dashboard/rental/' + values.rental + '/transaction/' + values.id + '/status')
                        })
                    })
                }
            } else {
                this.props.dispatch(EnvironmentAction.setLoader(false)).then(() => {
                    history.push('/' + this.props.environment.slug + '/dashboard/rental/' + values.rental + '/transaction/' + values.id + '/status')
                })
            }


        })
    }

    _closeVenditPopup() {
        this.setState({
            showVenditPopup: false
        })
    }
}

export default connect(mapStateToProps, RentalDispatcher)(Transaction);

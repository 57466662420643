import ErrorAction from "../error/ErrorAction";
import fetchHelper from "../../common/helpers/fetchHelper";

export default class CategoryEffect {

    static async countryList(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        if (response.status.code === 200) {

            dispatch(ErrorAction.unsetError())

            return {
                response: response.items.countries,
            }

        } else {
            return {
                error: true,
                message: response.status.message
            }
        }

    }

}



import React from 'react'
import {connect} from "react-redux";
import DisplayPrice from "../../helpers/DisplayEuro";
import RentalTotalsAction from "../../../stores/rental/totals/RentalTotalsAction";
import CheckDisplayModule from "../../helpers/CheckDisplayModule";
import RentalDispatcher from "../../dispatchers/RentalDispatcher";
import RentalDetailAction from "../../../stores/rental/detail/RentalDetailAction";
import translate from "../../../createSlice/common/helpers/utils/translate";

const mapStateToProps = (state) => ({
    ...state.rentalTotals,
    environment: state.environment,
    rentalDetail: state.rentalDetail,
    partnerUser: state.authentication.partnerUser

});

class RentalTotals extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.init()
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.discountPercentage !== this.props.discountPercentage) {
            this.init()
        }

    }

    init = () => {
        this.setState({
            ...this.state,
        })
    }

    render() {

        const data = this.props.totals

        if (data) {
            if (this.props.overrideItems && this.props.overrideItems.length > 0) {
                data.RentalItems = this.props.overrideItems
            }
        }

        if (data && CheckDisplayModule('financial', this.props.environment, this.props.partnerUser)) {

            return (
                <div className="c-info-blocks__row">
                    <div className="c-info-blocks__single">
                        <header className="c-subject-header c-subject-header--closed">
                            <h2>Totalen</h2>
                        </header>
                        <div className="c-info-blocks__single__body c-info-blocks__single__body--scroll">
                            {data.RentalItems &&
                            <table className="c-table-standard c-table-standard--prices">
                                <tbody>
                                <tr>
                                    <td>Item(s)</td>
                                    <td>{translate('price')}</td>
                                    <td>{translate('amount')}</td>
                                    <td>{translate('duration')}</td>
                                    <td>{translate('discount')}</td>
                                    <td>{translate('total')}</td>
                                </tr>
                                {data.RentalItems.map((item) => {

                                    let duration = ''

                                    if (item.data.time_unit) {
                                        duration = item.data.time_unit.type.name + ' x ' + (item.data.time_unit_amount * item.data.time_unit.amount)
                                    }


                                    return (
                                        <tr key={'rental-item-' + item.data.category_id}>
                                            <td>{item.data.category?.alternative_label ? item.data.category?.alternative_label : item.title}</td>
                                            <td>{DisplayPrice(item.data.price)}</td>
                                            <td>{item.data.quantity} x</td>
                                            <td>{duration}</td>
                                            <td>{item.discount}</td>
                                            <td>{DisplayPrice(item.data.totalPrice)}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                            }
                            <div className="c-totals__options">
                                <div className="c-totals__options__half">
                                    {!this.props.hideInput &&

                                    <div className="c-totals__sum">
                                        <span>
                                            <label>
                                                {translate('depositActive')} <input
                                                checked={this.props.deposit}
                                                onChange={(e) => {
                                                    this._setDeposit(e)
                                                }}
                                                type={'checkbox'}/>
                                        </label>
                                        </span>
                                    </div>}
                                </div>
                                <div className="c-totals__options__half">

                                    {!this.props.hideInput &&
                                    <>

                                        <div className="c-form__row">

                                            <div className={'c-form__group c-form__group--row discount-row'}>
                                                <select value={this.props.discountType}
                                                        onChange={(e) => this.props.dispatch(RentalTotalsAction.setDiscountType(e.target.value))}>
                                                    <option
                                                        value={'percentage'}>{translate('percentageDiscount')}</option>
                                                    <option value={'fixed'}>{translate('fixedAmountDiscount')}</option>
                                                </select>
                                            </div>

                                        </div>

                                        {this.props.discountType === 'percentage' &&
                                        <div className="c-form__row">
                                            <form className="c-form__group c-form__group--row discount-row"
                                                  onSubmit={(e) => {
                                                      e.preventDefault()
                                                      this._submitDiscount()
                                                  }}

                                            >
                                                <label htmlFor="">% {translate('discount')} </label>
                                                <input onChange={(e) => {
                                                    this.props.dispatch(RentalTotalsAction.setDiscountPercentage(e.target.value))
                                                }} value={this.props.discountPercentage} type="number" step=".01"
                                                       required=""/>
                                                <button type={'submit'}>
                                                    <svg version="1.1" id="Capa_1" x="0px" y="0px" width="438.529px"
                                                         height="438.528px" viewBox="0 0 438.529 438.528"
                                                         style={{enableBackground: 'new 0 0 438.529 438.528'}}>
                                                        <g>
                                                            <g>
                                                                <path d="M433.109,23.694c-3.614-3.612-7.898-5.424-12.848-5.424c-4.948,0-9.226,1.812-12.847,5.424l-37.113,36.835
			c-20.365-19.226-43.684-34.123-69.948-44.684C274.091,5.283,247.056,0.003,219.266,0.003c-52.344,0-98.022,15.843-137.042,47.536
			C43.203,79.228,17.509,120.574,5.137,171.587v1.997c0,2.474,0.903,4.617,2.712,6.423c1.809,1.809,3.949,2.712,6.423,2.712h56.814
			c4.189,0,7.042-2.19,8.566-6.565c7.993-19.032,13.035-30.166,15.131-33.403c13.322-21.698,31.023-38.734,53.103-51.106
			c22.082-12.371,45.873-18.559,71.376-18.559c38.261,0,71.473,13.039,99.645,39.115l-39.406,39.397
			c-3.607,3.617-5.421,7.902-5.421,12.851c0,4.948,1.813,9.231,5.421,12.847c3.621,3.617,7.905,5.424,12.854,5.424h127.906
			c4.949,0,9.233-1.807,12.848-5.424c3.613-3.616,5.42-7.898,5.42-12.847V36.542C438.529,31.593,436.733,27.312,433.109,23.694z"/>
                                                                <path d="M422.253,255.813h-54.816c-4.188,0-7.043,2.187-8.562,6.566c-7.99,19.034-13.038,30.163-15.129,33.4
			c-13.326,21.693-31.028,38.735-53.102,51.106c-22.083,12.375-45.874,18.556-71.378,18.556c-18.461,0-36.259-3.423-53.387-10.273
			c-17.13-6.858-32.454-16.567-45.966-29.13l39.115-39.112c3.615-3.613,5.424-7.901,5.424-12.847c0-4.948-1.809-9.236-5.424-12.847
			c-3.617-3.62-7.898-5.431-12.847-5.431H18.274c-4.952,0-9.235,1.811-12.851,5.431C1.807,264.844,0,269.132,0,274.08v127.907
			c0,4.945,1.807,9.232,5.424,12.847c3.619,3.61,7.902,5.428,12.851,5.428c4.948,0,9.229-1.817,12.847-5.428l36.829-36.833
			c20.367,19.41,43.542,34.355,69.523,44.823c25.981,10.472,52.866,15.701,80.653,15.701c52.155,0,97.643-15.845,136.471-47.534
			c38.828-31.688,64.333-73.042,76.52-124.05c0.191-0.38,0.281-1.047,0.281-1.995c0-2.478-0.907-4.612-2.715-6.427
			C426.874,256.72,424.731,255.813,422.253,255.813z"/>
                                                            </g>
                                                        </g>

                                                    </svg>
                                                </button>
                                            </form>
                                        </div>
                                        }

                                        {this.props.discountType === 'fixed' &&
                                        <div className="c-form__row">
                                            <form className="c-form__group c-form__group--row discount-row"
                                                  onSubmit={(e) => {
                                                      e.preventDefault()
                                                      this._submitDiscount()
                                                  }}
                                            >
                                                <label htmlFor="">€ {translate('discount')} </label>
                                                <input value={this.props.discountPrice} onChange={(e) => {
                                                    this.props.dispatch(RentalTotalsAction.setDiscountPrice(e.target.value))
                                                }}
                                                       step=".01"
                                                       type="number"
                                                       required=""/>
                                                <button type={'submit'}>
                                                    <svg version="1.1" id="Capa_1" x="0px" y="0px" width="438.529px"
                                                         height="438.528px" viewBox="0 0 438.529 438.528"
                                                         style={{enableBackground: 'new 0 0 438.529 438.528'}}>
                                                        <g>
                                                            <g>
                                                                <path d="M433.109,23.694c-3.614-3.612-7.898-5.424-12.848-5.424c-4.948,0-9.226,1.812-12.847,5.424l-37.113,36.835
			c-20.365-19.226-43.684-34.123-69.948-44.684C274.091,5.283,247.056,0.003,219.266,0.003c-52.344,0-98.022,15.843-137.042,47.536
			C43.203,79.228,17.509,120.574,5.137,171.587v1.997c0,2.474,0.903,4.617,2.712,6.423c1.809,1.809,3.949,2.712,6.423,2.712h56.814
			c4.189,0,7.042-2.19,8.566-6.565c7.993-19.032,13.035-30.166,15.131-33.403c13.322-21.698,31.023-38.734,53.103-51.106
			c22.082-12.371,45.873-18.559,71.376-18.559c38.261,0,71.473,13.039,99.645,39.115l-39.406,39.397
			c-3.607,3.617-5.421,7.902-5.421,12.851c0,4.948,1.813,9.231,5.421,12.847c3.621,3.617,7.905,5.424,12.854,5.424h127.906
			c4.949,0,9.233-1.807,12.848-5.424c3.613-3.616,5.42-7.898,5.42-12.847V36.542C438.529,31.593,436.733,27.312,433.109,23.694z"/>
                                                                <path d="M422.253,255.813h-54.816c-4.188,0-7.043,2.187-8.562,6.566c-7.99,19.034-13.038,30.163-15.129,33.4
			c-13.326,21.693-31.028,38.735-53.102,51.106c-22.083,12.375-45.874,18.556-71.378,18.556c-18.461,0-36.259-3.423-53.387-10.273
			c-17.13-6.858-32.454-16.567-45.966-29.13l39.115-39.112c3.615-3.613,5.424-7.901,5.424-12.847c0-4.948-1.809-9.236-5.424-12.847
			c-3.617-3.62-7.898-5.431-12.847-5.431H18.274c-4.952,0-9.235,1.811-12.851,5.431C1.807,264.844,0,269.132,0,274.08v127.907
			c0,4.945,1.807,9.232,5.424,12.847c3.619,3.61,7.902,5.428,12.851,5.428c4.948,0,9.229-1.817,12.847-5.428l36.829-36.833
			c20.367,19.41,43.542,34.355,69.523,44.823c25.981,10.472,52.866,15.701,80.653,15.701c52.155,0,97.643-15.845,136.471-47.534
			c38.828-31.688,64.333-73.042,76.52-124.05c0.191-0.38,0.281-1.047,0.281-1.995c0-2.478-0.907-4.612-2.715-6.427
			C426.874,256.72,424.731,255.813,422.253,255.813z"/>
                                                            </g>
                                                        </g>

                                                    </svg>
                                                </button>
                                            </form>
                                        </div>
                                        }
                                    </>
                                    }


                                    {!!data.data.totalDelivery &&
                                    <div className="c-totals__sum">
                                        <span>{translate('deliveryCosts')}</span>
                                        <span><strong
                                            style={{textDecoration: 'underline'}}>{DisplayPrice(parseFloat(data.data.totalDelivery))}</strong></span>
                                    </div>
                                    }

                                    <div className="c-totals__sum">
                                        <span>{translate('totalsDiscount')}</span>
                                        <span><strong>{DisplayPrice(data.data.totalDiscount)}</strong></span>
                                    </div>

                                    <div className="c-totals__sum">
                                        <span>{translate('subtotalRental')}</span>
                                        <span><strong>{DisplayPrice(data.data.subTotalPrice)}</strong></span>
                                    </div>

                                    <div className="c-totals__sum">
                                        <span>{translate('subtotalInsurance')}</span>
                                        <span><strong>{DisplayPrice(data.data.totalInsurance)}</strong></span>
                                    </div>

                                    <div className="c-totals__sum">
                                        <span>{translate('subtotalDeposit')}</span>
                                        <span><strong>{DisplayPrice(data.data.totalDeposit)}</strong></span>
                                    </div>

                                    <div className="c-totals__sum">
                                        <span>{translate('totalIncVAT')}</span>
                                        <span><strong
                                            style={{textDecoration: 'underline'}}>{DisplayPrice(data.data.totalPrice)}</strong></span>
                                    </div>

                                    {!!data.data.totalPricePaid &&
                                    <div className="c-totals__sum">
                                        <span>{translate('alreadyPaid')}</span>
                                        <span><strong
                                            style={{textDecoration: 'underline'}}>{DisplayPrice(parseFloat(data.data.totalPricePaid) + parseFloat(data.data.totalDepositPaid))}</strong></span>
                                    </div>
                                    }
                                    {/*{Bookings and Payment page}*/}
                                    {/*{!!data.data.cancellationFee && parseInt(data.data.cancellationFee) > 0 &&*/}
                                    {/*<div className="c-totals__sum">*/}
                                    {/*    <span>{translate('fineForLateCancellation')}</span>*/}
                                    {/*    <span><strong*/}
                                    {/*        style={{textDecoration: 'underline'}}>{DisplayPrice(parseFloat(data.data.cancellationFee))}</strong></span>*/}
                                    {/*</div>*/}
                                    {/*}*/}

                                    {!!this.props.rentalDetail?.data?.paymentStatus === 'open' &&
                                    <div className="c-totals__sum">
                                        <span>{translate('amountToPay')}</span>
                                        <span><strong
                                            style={{textDecoration: 'underline'}}>{DisplayPrice(data.data.totalPriceRest)}</strong></span>
                                    </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )

        } else {
            return null
        }
    }


    _setDeposit = (e) => {

        this.props.dispatch(RentalTotalsAction.setDeposit(e.target.checked)).then(() => {
            this.props.dispatch(RentalTotalsAction.requestTotals(!!this.props.create, true, !!this.props.create, !!this.props.useDetail))

            if (this.props.rentalDetail?.data?.uuid) {
                this.props.dispatch(RentalTotalsAction.submitDeposit(this.props.rentalDetail.data.uuid))
            }

        })
    }


    _submitDiscount = () => {

        if (this.props.rentalDetail?.data?.uuid) {
            this.props.dispatch(RentalTotalsAction.submitDiscount()).then(() => {
                this.props.dispatch(RentalTotalsAction.requestTotals(!!this.props.create, false, false, !!this.props.useDetail)).then(() => {
                    this.props.dispatch(RentalDetailAction.requestTransactions(this.props.rentalDetail?.data?.uuid))
                })
            })
        } else {
            this.props.dispatch(RentalTotalsAction.requestTotals(!!this.props.create, false, false, !!this.props.useDetail))
        }

    }

}

export default connect(mapStateToProps, RentalDispatcher)(RentalTotals);

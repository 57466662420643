import React from 'react'
import {Route, BrowserRouter} from 'react-router-dom'
import '../App.css'
import ViewRouter from "./ViewRouter";
import AuthenticationAction from "../stores/authentication/AuthenticationAction";
import Loader from "./Loader";
import {connect} from "react-redux";
import ErrorDisplay from "../common/components/General/ErrorDisplay";
import {Helmet} from "react-helmet";
import ErrorAction from "../stores/error/ErrorAction";


const mapStateToProps = (state) => ({
    environment: state.environment,
    authentication: state.authentication,
    rentalDetail: state.rentalDetail,
    partnerLocation: state.partnerLocation.detail
});

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            authTokenGenerated: false
        }
    }

    componentDidMount() {
        this.props.dispatch(AuthenticationAction.requestAccessToken())

        this.unsetErrorInterval = setInterval(() => {
            this.props.dispatch(ErrorAction.unsetError());
        }, 12000);

    }

    componentWillUnmount() {
        // Clear the interval when the component unmounts
        if (this.unsetErrorInterval) {
            clearInterval(this.unsetErrorInterval);
        }
    }

    render() {

        const isLoading = (this.props.environment.isLoading || this.props.rentalDetail.loading)

        if (this.props.authentication.accessToken) {
            return (
                <BrowserRouter basename={'/'}>

                    {this.props.partnerLocation?.name &&
                        <Helmet>
                            <title>{this.props.partnerLocation?.name} | Tilia Partneromgeving</title>
                        </Helmet>
                    }

                    <Loader loading={isLoading}/>

                    <ErrorDisplay/>

                    <Route
                        path={'/:partnerId/:partnerSlug?'}
                        history={this.props.history}
                        render={(props) => (
                            <ViewRouter {...props} />
                        )}
                    />

                </BrowserRouter>


            )
        } else {
            return <Loader/>
        }


    }

}

export default connect(mapStateToProps)(App);
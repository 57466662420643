import React from 'react'
import AvailabilityTable from "../../common/components/General/AvailabilityTable";
import {connect} from "react-redux";
import DashboardAction from "../../stores/dashboard/DashboardAction";
import Scanner from "../../common/components/General/Scanner";
import translate from "../../createSlice/common/helpers/utils/translate";
import DateRangeData from "../../common/constants/DateRangeData";
import {DatePicker} from "rsuite";
import moment from "moment/moment";

const mapStateToProps = (state) => ({
    ...state.dashboard,
    environment: state.environment,
    rentalReturn: state.rentalReturn
});

class Availability extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            locale: DateRangeData().locale,
            startDate: moment(),
            endDate: moment().add(7, 'days')
        };
        console.log(this.props.period.end)
        this.changeAvailabilityDates = this.changeAvailabilityDates.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(DashboardAction.requestWeeklyAvailability(this.state.startDate, this.state.endDate))
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.startDate !== prevState.startDate || this.state.endDate !== prevState.endDate) {
            this.props.dispatch(DashboardAction.requestWeeklyAvailability(this.state.startDate, this.state.endDate))
        }
    }

    changeAvailabilityDates(data, type) {
        if (type === 'startDate') {
            let endDate = moment(data)
            endDate.add(7, 'days')
            this.setState({startDate: moment(data), endDate: endDate})
        } else if (type === 'endDate'){
            let startDate = moment(data)
            startDate.subtract(7, 'days')
            this.setState({startDate: startDate , endDate: moment(data)})
        }
    }

    render() {
        return (
            <div className={'o-container o-container--primary'}>

                <Scanner type={'rental'}/>

                <div className="s-dashboard-table">

                    <header
                        className="c-subject-header c-subject-header--closed c-subject-header--split"
                        style={{marginTop: '2rem'}}
                    >
                        <h2>{translate('availability')}</h2>

                        <div className={'c-table-standard--datepicker_availability'}>

                            <span className="c-table-standard--datepicker_availability--start">
                                {translate('startDate')}
                            </span>

                            <DatePicker
                                ranges={[]}
                                cleanable={false}
                                isoWeek={true}
                                locale={this.state.locale}
                                format={translate('dateFormat')}
                                value={this.state.startDate}
                                onChange={(data) => this.changeAvailabilityDates(data, 'startDate')}
                                style={{marginRight: '1.5rem'}}
                            />

                            {/*<span className="c-table-standard--datepicker_availability--till">*/}
                            {/*    {translate('till')}*/}
                            {/*</span>*/}

                            {/*<DatePicker*/}
                            {/*    ranges={[]}*/}
                            {/*    cleanable={false}*/}
                            {/*    isoWeek={true}*/}
                            {/*    locale={this.state.locale}*/}
                            {/*    format={translate('dateFormat')}*/}
                            {/*    // calendarDefaultDate={this.state.endDate}*/}
                            {/*    value={this.state.endDate}*/}
                            {/*    onChange={(data) => this.changeAvailabilityDates(data, 'endDate')}*/}
                            {/*    style={{marginRight: '1.5rem'}}*/}
                            {/*/>*/}

                        </div>

                    </header>

                    <AvailabilityTable {...this.props}/>

                </div>
            </div>
        );
    }

}

export default connect(mapStateToProps)(Availability)
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import 'rsuite/dist/styles/rsuite-default.css'
import './common/styles/Main.scss';
import App from './views/App';
import {createMemoryHistory} from 'history';
import rootStore from './stores/rootStore';
import 'react-virtualized/styles.css';
import 'moment/locale/nl'
import {injectStore} from "./createSlice/app/hooks";
import {injectStoreFetch} from "./common/helpers/fetchHelper";
import {injectStoreTranslate} from "./createSlice/common/helpers/utils/translate";

(async (window) => {
    const initialState = {};
    const history = createMemoryHistory();
    const store = rootStore(initialState, history);
    injectStore(store);
    injectStoreFetch(store)
    injectStoreTranslate(store)
    const rootEl = document.getElementById('root');
    const render = (Component, el) => {
        ReactDOM.render(
            <Provider store={store}>
                <Component history={history} dispatch={store.dispatch}/>
            </Provider>,
            el
        );
    };
    render(App, rootEl);
})(window);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA


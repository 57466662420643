import {useDispatch, useSelector} from 'react-redux'
import {useLocation} from "react-router-dom";
import axios, {AxiosResponse} from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import {camelizeKeys} from "humps";
import {apiEnum} from "../common/enums/apiEnum";
import {useEffect, useRef} from "react";
import {activate2faModal} from "../features/modals/TwoFactorAuthModal/twoFactorAuthModalSlice";
import ErrorAction from "../../stores/error/ErrorAction";
import AuthenticationAction from "../../stores/authentication/AuthenticationAction";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch()
export const useAppSelector = useSelector
export const useAppLocation = () => useLocation()


export const useAppAxios = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        "content-type": "application/json"
    },
    responseType: "json"
});

const refreshAuthLogic = (failedRequest) =>
    useAppAxios
        .post(apiEnum.AUTH + '/refresh', {
            refresh_token: localStorage.getItem("refreshToken")
        })
        .then(tokenRefreshResponse => {

            if (tokenRefreshResponse.data.items?.accessToken?.accessToken) {
                localStorage.setItem("accessToken", tokenRefreshResponse.data.items.accessToken.accessToken);
                localStorage.setItem("refreshToken", tokenRefreshResponse.data.items.accessToken.refreshToken);
                failedRequest.data.response.config.headers["Authorization"] =
                    "Bearer " + tokenRefreshResponse.data.items.accessToken.access_token;
                return Promise.resolve();
            }

            return {
                data: {
                    status: {
                        code: 401
                    }
                }
            }

        })
        .catch(error => {
            store.dispatch(AuthenticationAction.unsetUser())
            return Promise.resolve();
        });

createAuthRefreshInterceptor(useAppAxios, refreshAuthLogic);

export const injectStore = _store => {
    store = _store
}

// Use interceptor to inject the token to requests
useAppAxios.interceptors.request.use(request => {


    if (request.headers) {

        request.headers['Authorization'] = `Bearer ${getAccessToken()}`;

        request.headers['X-Partner-User-Auth'] = store.getState().authentication.xPartnerUserAuth ?? null;
        request.headers['X-Partner-Location-Auth'] = store.getState().authentication.xPartnerLocationAuth ?? null;

        request.skipCamel = !!request.skipCamel

    }

    return request;
});

let store


useAppAxios.interceptors.response.use((response) => {

        if (!response) {
            store.dispatch(AuthenticationAction.logout())

            return {
                data: {
                    status: {
                        code: 401
                    }
                }
            }
        }

        if (response.data?.status?.code === 401) {
            store.dispatch(AuthenticationAction.logout())
        }

        if (response.data?.status?.code === 403) {
            store.dispatch(AuthenticationAction.unsetUser())
        }

        if (!response.config?.skipCamel) {
            response.data = camelizeKeys(response.data);
        }

        return response;
    },
    (error) => {

        if (error.response) {

            const errorCode = error.response.status;

            switch (errorCode) {
                case 401:
                    store.dispatch(AuthenticationAction.logout())
                    break;
                case 403:
                    store.dispatch(AuthenticationAction.unsetUser())
                    break;
                case 423:
                    store.dispatch(activate2faModal());
                    break;
                default:
                    store.dispatch(ErrorAction.catchError(error))
                    break;
            }
        }

        if (error.code) {
            switch (error.code) {
                case 401:
                    store.dispatch(AuthenticationAction.logout())
                    break;
                case 403:
                    store.dispatch(AuthenticationAction.unsetUser())
                    break;
                case 423:
                    store.dispatch(activate2faModal());
                    break;
                default:
                    store.dispatch(ErrorAction.catchError(error))
                    break;
            }
        }

        return Promise.resolve();
    }
);

function getAccessToken() {
    return localStorage.getItem('accessToken');
}


export const useHasChanged = (val) => {
    const prevVal = usePrevious(val)
    return prevVal !== val
}

export const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

import ActionUtility from "../../../common/helpers/ActionUtility";
import RentalCustomerDataEffect from "./RentalCustomerDataEffect";
import ApiEnum from "../../../common/constants/ApiEnum";

export default class RentalCustomerDataAction {

    static SET_FORM_DATA = 'RentalCustomerDataAction.SET_FORM_DATA';
    static SUBMIT_MUTATION = 'RentalCustomerDataAction.SUBMIT_MUTATION';
    static SEARCH_CUSTOMER_BY_EMAIL = 'RentalCustomerDataAction.SEARCH_CUSTOMER_BY_EMAIL';
    static UNSET_FORM_COMPLETION = 'RentalCustomerDataAction.UNSET_FORM_COMPLETION';
    static RESET = 'RentalCustomerDataAction.RESET';


    static validateForm(data) {

        return async (dispatch) => {

            const resp = {
                customerData: data
            }
            await ActionUtility.createThunkEffect(dispatch, RentalCustomerDataAction.SET_FORM_DATA, RentalCustomerDataEffect.validateForm, resp);
        }

    }

    static updateForm(data) {
        return async (dispatch) => {

            const resp = {
                customerData: data,
                completed: true
            }
            await ActionUtility.createThunkEffect(dispatch, RentalCustomerDataAction.SET_FORM_DATA, RentalCustomerDataEffect.validateForm, resp);
        }
    }

    static setInitialData() {

        return async (dispatch, getState) => {

            if (getState().rentalDetail && getState().rentalDetail.data && getState().rentalDetail.data.data && getState().rentalDetail.data.data.customer) {

                const rental = getState().rentalDetail.data.data

                const data = {
                    customerData: {
                        type: rental.customer.type,
                        newCustomer: 'false',
                        address: Object.values(rental.customer_addresses).filter((item) => item.type_id === "2")[0],
                        customer: rental.customer,
                        privatePerson: rental.customer_private_person,
                        contactPerson: rental.contactPerson,
                        company: rental.customer_company,
                        remarks: rental.customer.remarks
                    },
                    completed: false
                }

                if (rental.customer_company_contacts) {
                    data.customerData.contactPersons = Object.values(rental.customer_company_contacts)

                    if (rental.contact_person_id) {
                        data.customerData.contactPerson = rental.customer_company_contacts[rental.contact_person_id]
                    }
                }

                await ActionUtility.createThunkEffect(dispatch, RentalCustomerDataAction.SET_FORM_DATA, RentalCustomerDataEffect.validateForm, data);
            }
        }

    }

    static submitMutation() {

        return async (dispatch, getState) => {

            const data = getState().rentalCustomerData

            const values = {
                customerData: {
                    fetchUrl: ApiEnum.Api + '/mutation/create?rental=' + getState().rentalDetail.data.data.rental.uuid,
                    method: 'post',
                    accessToken: getState().authentication.accessToken,
                    params: {
                        auth_token: localStorage.getItem('authToken'),
                        type: "customer",
                        customer: {
                            ...data.form.data,
                        },
                    }
                }
            }


            if (values.customerData.params.customer.type === 'private') {
                values.customerData.params.customer.private = data.form.data.privatePerson
            } else {
                values.customerData.params.customer.company = data.form.data.company
                values.customerData.params.customer.contactPerson = data.form.data.contactPerson
            }


            await ActionUtility.createThunkEffect(dispatch, RentalCustomerDataAction.SUBMIT_MUTATION, RentalCustomerDataEffect.submitMutation, values);
        }

    }

    static unsetFormCompletion() {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, RentalCustomerDataAction.UNSET_FORM_COMPLETION, RentalCustomerDataEffect.unsetFormCompletion);
        }
    }


    static searchCustomerByEmail(email) {

        return async (dispatch, getState) => {

            const values = {
                fetchUrl: ApiEnum.Api + '/customer/list',
                method: 'post',
                accessToken: getState().authentication.accessToken,
                params: {
                    auth_token: localStorage.getItem('authToken'),
                    filter: {
                        search_email: email
                    }
                }
            }

            await ActionUtility.createThunkEffect(dispatch, RentalCustomerDataAction.SEARCH_CUSTOMER_BY_EMAIL, RentalCustomerDataEffect.searchCustomerByEmail, values);
        }

    }

    static setSearchedCustomerData(values) {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, RentalCustomerDataAction.SET_FORM_DATA, RentalCustomerDataEffect.setSearchedCustomerData, values);
        }

    }


    static reset() {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, RentalCustomerDataAction.RESET, RentalCustomerDataEffect.reset);
        }
    }

}

import ActionUtility from "../../common/helpers/ActionUtility";
import TransactionEffect from "./TransactionEffect";
import ApiEnum from "../../common/constants/ApiEnum";
import moment from 'moment'

export default class TransactionAction {

    static REQUEST_DETAIL = 'TransactionAction.REQUEST_DETAIL';
    static REQUEST_LIST = 'TransactionAction.REQUEST_LIST';

    static REQUEST_LIST_BY_LOCATION = 'TransactionAction.REQUEST_LIST_BY_LOCATION';
    static SET_PERIOD = 'TransactionAction.SET_PERIOD';
    static SET_PAGE = 'TransactionAction.SET_PAGE';
    static SET_SEARCH_VALUE = 'TransactionAction.SET_SEARCH_VALUE';

    static UPDATE_STATUS = 'TransactionAction.UPDATE_STATUS';
    static UPDATE_PAYMENT_METHOD = 'TransactionAction.UPDATE_PAYMENT_METHOD';

    static PARTIAL_PAYMENT = 'TransactionAction.PARTIAL_PAYMENT';
    static REFUND_PAYMENT = 'TransactionAction.REFUND_PAYMENT';


    static requestDetail(values) {
        return async (dispatch, getState) => {

            values = {
                fetchUrl: ApiEnum.Api + '/transaction/detail?rental=' + values.rental + '&id=' + values.id,
                method: 'post',
                accessToken: getState().authentication.accessToken,
            }

            await ActionUtility.createThunkEffect(dispatch, TransactionAction.REQUEST_DETAIL, TransactionEffect.requestDetail, values);
        }
    }

    static updatePaymentMethod(values) {
        return async (dispatch, getState) => {

            values = {
                fetchUrl: ApiEnum.Api + '/transaction/update-payment-method?rental=' + values.rental + '&id=' + values.id,
                method: 'post',
                accessToken: getState().authentication.accessToken,
                params: {
                    payment_method: values.payment_method
                }
            }

            await ActionUtility.createThunkEffect(dispatch, TransactionAction.UPDATE_PAYMENT_METHOD, TransactionEffect.updatePaymentMethod, values);
        }
    }

    static requestList(uuid) {

        return async (dispatch, getState) => {
            const values = {
                fetchUrl: ApiEnum.Api + '/transaction/list?rental=' + uuid,
                method: 'post',
                accessToken: getState().authentication.accessToken,
            }

            await ActionUtility.createThunkEffect(dispatch, TransactionAction.REQUEST_LIST, TransactionEffect.requestList, values);
        }

    }

    static requestListByLocation() {

        return async (dispatch, getState) => {
            const values = {
                fetchUrl: ApiEnum.Api + '/transaction/list-by-location' + '?page=' + getState().transaction.activeListPage,
                method: 'post',
                accessToken: getState().authentication.accessToken,
                params: {
                    date_from: moment(getState().transaction.listPeriod.start).format('YYYY-MM-DD'),
                    date_to: moment(getState().transaction.listPeriod.end).format('YYYY-MM-DD'),
                    filter: getState().transaction.listSearchValue

                }
            }

            await ActionUtility.createThunkEffect(dispatch, TransactionAction.REQUEST_LIST_BY_LOCATION, TransactionEffect.requestListByLocation, values);
        }

    }


    static partialPayment(values) {
        return async (dispatch, getState) => {

            const params = {
                payment_method: values.payment_method,
            }

            if (values.paid) {
                params.paid = values.paid
            } else {
                params.paid = 0
            }

            if (values.deposit_paid) {
                params.deposit_paid = values.deposit_paid
            } else {
                params.deposit_paid = 0
            }

            if (values.insurance_paid) {
                params.insurance_paid = values.insurance_paid
            } else {
                params.insurance_paid = 0
            }

            if (values.status === 'completed') {
                params.paid = values.price_to_pay
            }

            if (values.deposit_status === 'completed') {
                params.deposit_paid = values.deposit_to_pay
            }

            if (values.deposit_returned_status === 'completed') {
                params.deposit_returned = values.deposit_returned.replace('-', '')
            }

            if (values.insurance_status === 'completed') {
                params.insurance_paid = values.insurance_to_pay
            }

            values = {
                fetchUrl: ApiEnum.Api + '/transaction/partial-payment?rental=' + values.rental + '&id=' + values.id,
                method: 'post',
                accessToken: getState().authentication.accessToken,
                params: params
            }

            await ActionUtility.createThunkEffect(dispatch, TransactionAction.PARTIAL_PAYMENT, TransactionEffect.partialPayment, values);
        }

    }

    static refundPayment(values) {
        return async (dispatch, getState) => {

            const params = {
                auth_token: localStorage.getItem('authToken'),
                payment_method: values.payment_method,
                rentalUuid: values.rental,
                transaction_id: values.id
            }

            values = {
                fetchUrl: ApiEnum.Api + '/transaction/refund?rentalUuid=' + values.rental,
                method: 'post',
                accessToken: getState().authentication.accessToken,
                params: params
            }

            await ActionUtility.createThunkEffect(dispatch, TransactionAction.REFUND_PAYMENT, TransactionEffect.refundPayment, values);
        }

    }

    static setPeriod(data) {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, TransactionAction.SET_PERIOD, TransactionEffect.setPeriod, data);
        }

    }

    static setSearchValue(value) {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, TransactionAction.SET_SEARCH_VALUE, TransactionEffect.setSearchValue, value);
        }

    }

    static setPage(number) {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, TransactionAction.SET_PAGE, TransactionEffect.setPage, number);
        }

    }


}

import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import EnvironmentAction from "../../../../stores/environment/EnvironmentAction";
import translate from "../../../common/helpers/utils/translate";
import {Link} from "react-router-dom";
import {postPasswordReset, setData, setErrors, validateData} from "./forgotPasswordSlice";
import {IconEyeClosed, IconEyeOpen} from "./IconVisibility";

export const ResetPasswordForm = () => {
    const {environment, forgotPasswordPage} = useAppSelector((state) => state)
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [resetFinished, setResetFinished] = useState(false)
    const [passwordField, setPasswordField] = useState({
        type: 'password', icon: <IconEyeClosed/>,
    });

    const [repeatPasswordField, setRepeatPasswordField] = useState({
        type: 'password', icon: <IconEyeClosed/>,
    });


    const dispatch = useAppDispatch()

    useEffect(() => {
        const hash = new URLSearchParams(location.search).get("hash")
        if (!hash) location.replace("/" + environment.slug);
        dispatch(setData({
            ...forgotPasswordPage.data,
            hash: hash
        }))
    }, []);

    useEffect(() => {
        setShowErrorMessage(false)
    }, [forgotPasswordPage.body])

    const togglePasswordVisibility = (field) => {
        if (field === 'password') {
            setPasswordField((prevState) => ({
                type: prevState.type === 'password' ? 'text' : 'password',
                icon: prevState.type === 'password' ? <IconEyeOpen/> : <IconEyeClosed/>,
            }));
        } else if (field === 'repeatPassword') {
            setRepeatPasswordField((prevState) => ({
                type: prevState.type === 'password' ? 'text' : 'password',
                icon: prevState.type === 'password' ? <IconEyeOpen/> : <IconEyeClosed/>,
            }));
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        dispatch(EnvironmentAction.setLoader(true)).then(() => {
            dispatch(validateData())
                .then((action) => {
                    if (action.meta.requestStatus === 'fulfilled') {
                        dispatch(postPasswordReset()).then((response) => {
                            if (response.payload.status.code !== 200) {
                                dispatch(setErrors({
                                    isError: true,
                                    message: response.payload.message
                                }))
                                setShowErrorMessage(true)
                                dispatch(EnvironmentAction.setLoader(false))
                                return
                            }

                            setResetFinished(true)
                            dispatch(EnvironmentAction.setLoader(false))
                        })
                    } else if (action.meta.requestStatus === 'rejected') {
                        setShowErrorMessage(true)
                        dispatch(EnvironmentAction.setLoader(false))
                    }
                })
                .catch((error) => {
                    setShowErrorMessage(true)
                    dispatch(EnvironmentAction.setLoader(false))
                    console.error('Unexpected error:', error);
                });
        })
    };

    if (resetFinished) {
        return (<div className="c-auth__form">
            <h3>{translate('passwordResetTitle')}</h3>
            <p>{translate('passwordResetText')}</p>
            <p>{translate('changesMadeWarning')}</p>
            <Link to={"/" + environment.slug}>{translate('return')}</Link>
        </div>)
    }

    return (

        <form className="c-auth__form c-form" onSubmit={handleSubmit}>

            <small>{translate('resetPasswordLabel')}</small>

            <div className="c-form__row">
                <div className="c-form__group">
                    <label htmlFor="password">{translate('newPasswordLabel')}</label>
                    <div className="u-flex">
                        <input onChange={(e) => dispatch(setData({
                            ...forgotPasswordPage.data,
                            password: e.target.value
                        }))}
                               type={passwordField.type} name={'newPassword'}
                               required
                               id="password"/>
                        <span className="c-form__group--visibility"
                              onClick={() => togglePasswordVisibility("password")}>
                             {passwordField.icon}
                        </span>
                    </div>


                </div>
            </div>

            <div className="c-form__row">
                <div className="c-form__group">
                    <label htmlFor="repeatedPassword">{translate('repeatPasswordLabel')}</label>
                    <div className="u-flex">
                        <input onChange={(e) => dispatch(setData({
                            ...forgotPasswordPage.data,
                            repeatedPassword: e.target.value
                        }))} type={repeatPasswordField.type}
                               name={'repeatPassword'}
                               required
                               id="repeatedPassword"/>
                        <span className="c-form__group--visibility"
                              onClick={() => togglePasswordVisibility("repeatPassword")}>
                              {repeatPasswordField.icon}
                        </span>
                    </div>
                </div>
            </div>

            {!!showErrorMessage && <p style={{marginBottom: '2rem'}}>{forgotPasswordPage.errors.message}</p>}

            <div className="c-form__row">
                <div className="c-form__group">
                    <button className="btn btn--primary" type="submit"
                            style={{backgroundColor: environment.colors.dark}}>
                        <span>{translate('resetPasswordButton')}</span></button>

                    <Link to={"/" + environment.slug}>{translate('login')}</Link>
                </div>
            </div>

        </form>)
}

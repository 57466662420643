import ItemAction from "./ItemAction";

export default class ItemReducer {

    static initialState = {
        scannedItems: [],
        response: null,
        lastScanWarning: null,
        stockCategories: null,
        stockItems: null,
        lateItems: {
            data: null,
            pages: null,
            activePage: 0
        },
        inServiceItems: {
            data: null,
            pages: null,
            activePage: 0
        },
        formattedStock: null,
        submitting: false,
        statusResponse: null,
        exportFile: null,
        exportLoading: false,
    };

    static reducer(state = ItemReducer.initialState, action) {

        switch (action.type) {

            case ItemAction.SCAN_CODE :

                return {
                    ...state,
                };

            case ItemAction.SCAN_CODE + '_FINISHED':

                if (action.payload) {

                    if (action.payload.item) {

                        if (!state.scannedItems.some(item => item.id === action.payload.item.id)) {

                            return {
                                ...state,
                                scannedItems: [
                                    ...state.scannedItems,
                                    action.payload.item
                                ],
                                lastScanWarning: action.payload.lastScanWarning
                            };


                        } else {

                            const removalItem = state.scannedItems.find((item) => {
                                return item.id === action.payload.item.id;
                            })

                            state.scannedItems.slice(0, removalItem)

                            return {
                                ...state,
                                scannedItems: state.scannedItems.filter(item => item.id !== action.payload.item.id),
                                lastScanWarning: null
                            };


                        }
                    } else {
                        return {
                            ...state,
                            lastScanWarning: action.payload.lastScanWarning
                        };
                    }
                } else return state


            case ItemAction.SUBMIT :

                return {
                    ...state,
                    submitting: true
                };


            case ItemAction.SUBMIT + '_FINISHED':
                return {
                    ...state,
                    submitting: false,
                    response: action.payload
                };

            case ItemAction.REQUEST_AVAILABLE_CATEGORY_LIST :

                return {
                    ...state,
                };

            case ItemAction.REQUEST_AVAILABLE_CATEGORY_LIST + '_FINISHED':

                return {
                    ...state,
                    stockCategories: action.payload
                };

            case ItemAction.REQUEST_AVAILABLE_ITEM_LIST :

                return {
                    ...state,
                };

            case ItemAction.REQUEST_AVAILABLE_ITEM_LIST + '_FINISHED':
                return {
                    ...state,
                    stockItems: action.payload
                };

            case ItemAction.REQUEST_LATE_ITEMS :

                return {
                    ...state,
                };

            case ItemAction.REQUEST_LATE_ITEMS + '_FINISHED':
                return {
                    ...state,
                    lateItems: {
                        ...state.lateItems,
                        ...action.payload
                    }
                };

            case ItemAction.SET_LATE_ITEMS_PAGE + '_FINISHED':
                return {
                    ...state,
                    lateItems: {
                        ...state.lateItems,
                        activePage: action.payload
                    }
                };

            case ItemAction.REQUEST_IN_SERVICE_ITEMS :
                return {
                    ...state,
                };

            case ItemAction.REQUEST_IN_SERVICE_ITEMS + '_FINISHED':
                return {
                    ...state,
                    inServiceItems: {
                        ...state.inServiceItems,
                        ...action.payload
                    }
                };

            case ItemAction.SET_IN_SERVICE_ITEMS_PAGE :
                return {
                    ...state,
                };

            case ItemAction.SET_IN_SERVICE_ITEMS_PAGE + '_FINISHED':
                return {
                    ...state,
                    inServiceItems: {
                        ...state.inServiceItems,
                        activePage: action.payload
                    }
                };

            case ItemAction.FORMAT_STOCK_ITEMS_BY_CATEGORY :

                return {
                    ...state,
                };

            case ItemAction.FORMAT_STOCK_ITEMS_BY_CATEGORY + '_FINISHED':
                return {
                    ...state,
                    formattedStock: action.payload
                };

            case ItemAction.REQUEST_EXPORT_LIST:
                return {
                    ...state,
                    exportFile: null,
                    exportLoading: true,
                };

            case ItemAction.REQUEST_EXPORT_LIST + '_FINISHED':
                return {
                    ...state,
                    exportFile: action.payload,
                    exportLoading: false,
                };

            case ItemAction.RESET_EXPORT_FILE_DATA + '_FINISHED':

                return {
                    ...state,
                    exportFile: null,
                }

            case ItemAction.UPDATE_STATUS :

                return {
                    ...state,
                };

            case ItemAction.UPDATE_STATUS + '_FINISHED':

                return {
                    ...state,
                    statusResponse: action.payload
                };

            case ItemAction.EMPTY_SCANNED_ITEMS + '_FINISHED':
                return {
                    ...state,
                    scannedItems: []
                };


            case ItemAction.RESET + '_FINISHED':
                return {
                    ...ItemReducer.initialState,
                };


            default:
                return state
        }

    }

}
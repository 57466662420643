import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {apiResetPassword} from "../../../common/api/partner/authentication/apiResetPassword";
import {apiRequestPasswordReset} from "../../../common/api/partner/authentication/apiRequestPasswordReset";

export const postPasswordReset = createAsyncThunk(
    'resetPassword',
    async (_, {getState}) => {
        const {forgotPasswordPage} = getState();
        try {
            const data = {
                hash: forgotPasswordPage.data.hash,
                password: forgotPasswordPage.data.password,
                repeatedPassword: forgotPasswordPage.data.repeatedPassword
            }

            const response = await apiResetPassword(data)

            return response.data
        } catch (e) {
            console.log(e)
        }
    }
)

export const requestForgotPassword = createAsyncThunk(
    'requestPasswordReset',
    async (_, {getState}) => {
        const {environment, forgotPasswordPage} = getState();

        try {
            const partnerId = environment.data.info.partner_id;
            const slug = environment.slug;
            const baseUrl = location.protocol + "//" + location.host + "/" + slug + "/reset-password";
            const urlParams = '?partnerId=' + partnerId + '&email=' + forgotPasswordPage.data.email + '&redirectUrl=' + baseUrl

            const data = {
                url: urlParams,
                email: forgotPasswordPage.data.email
            }

            const response = await apiRequestPasswordReset(data)

            return response.data
        } catch (e) {
            console.log(e)
        }
    }
)

export const validateData = createAsyncThunk(
    'data/validate',
    async (_, {rejectWithValue, getState}) => {
        const {forgotPasswordPage} = getState();

        try {
            if (forgotPasswordPage.data.password !== forgotPasswordPage.data.repeatedPassword) {
                return rejectWithValue('passwords are not equal');
            }

            if (!/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/.test(forgotPasswordPage.data.password)) {
                return rejectWithValue('Password must contain one digit from 1 to 9, one lowercase letter, one uppercase letter, one special character, no space, and it must be 8-16 characters long.');
            }


        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);


const initialState = {
    status: '',
    data: {
        hash: '',
        email: '',
        password: '',
        repeatedPassword: ''
    },
    errors: {
        isError: false,
        message: '',
    }
}

export const forgotPasswordPageSlice = createSlice({
    name: 'forgotPassword',
    initialState,
    reducers: {
        setData: (state, action) => {
            state.data = action.payload
        },
        setErrors: (state, action) => {
            state.errors = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(validateData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(validateData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.errors = {
                    isError: false,
                    message: ""
                };
            })
            .addCase(validateData.rejected, (state, action) => {
                state.status = 'failed';
                state.errors = {
                    isError: true,
                    message: action.payload
                };
            });
    },
})

export const {setData, setErrors} = forgotPasswordPageSlice.actions

export const forgotPasswordReducer = forgotPasswordPageSlice.reducer
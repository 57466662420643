import React from 'react'
import Dashboard from "./SignedIn";
import Authenticate from "./Authenticate";
import {connect} from "react-redux";
import EnvironmentAction from "../stores/environment/EnvironmentAction";
import ValidateSlug from "../common/helpers/ValidateSlug";
import Speeds from "../common/constants/Speeds";
import TinyLoader from "../common/components/General/TinyLoader";
import {reformatOldSlugs} from "../common/helpers/reformatOldSlugs";


const mapStateToProps = (state) => ({
    environment: state.environment,
    authentication: state.authentication,
    error: state.error,
    user: state.user,
    twoFactorAuthModal: state.twoFactorAuthModal
});

const isNumeric = (value) => {
    return /^-?\d+$/.test(value);
}

class ViewRouter extends React.Component {

    componentDidMount() {
        if (this.props.match) {
            let slug = this.props.match.params.partnerId
            let formattedSlug


            if (/^\d+$/.test(slug)) {
                slug += '/' + ValidateSlug(this.props.match.params.partnerSlug)
            } else {
                slug = ValidateSlug(slug)
            }

            formattedSlug = reformatOldSlugs(slug)

            if (formattedSlug !== slug) {

                let url = this.props.location.pathname.replace(slug, formattedSlug)

                this.props.history.push(url)
                this.props.dispatch(EnvironmentAction.detail(formattedSlug))
            } else {
                this.props.dispatch(EnvironmentAction.detail(slug))
            }


        }
    }

    componentDidUpdate() {

        // Backup for removing the loading screen after 30s in all cases

        setTimeout(() => {
            if (this.props.environment.isLoading) {
                setTimeout(() => {
                    this.props.dispatch(EnvironmentAction.setLoader(false))
                }, Speeds.LoaderSpeed)
            }
        }, 30000)
    }

    render() {

        const environment = this.props.environment
        const authentication = this.props.authentication
        const partnerUser = authentication.partnerUser

        if (environment.success)
            if (partnerUser.success && partnerUser.data.auth_token && !this.props.error.forceLogout && !this.props.twoFactorAuthModal.active) {
                return <Dashboard history={this.props.history}/>
            } else {
                return <Authenticate history={this.props.history}/>
            }
        else if (environment.error) {
            return <p style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh'}}>Geen
                partneromgeving gevonden</p>
        } else {
            return (
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh'}}>
                    <TinyLoader/>
                </div>
            )
        }

    }
}

export default connect(mapStateToProps)(ViewRouter);

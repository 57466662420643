import ActionUtility from "../../../common/helpers/ActionUtility";
import RentalIssueItemsEffect from "./RentalIssueItemsEffect";
import ApiEnum from "../../../common/constants/ApiEnum";

export default class RentalIssueItemsAction {

    static SET_DELIVERY_LOCATION = 'RentalCreateAction.SET_DELIVERY_LOCATION'

    static RESET = 'RentalIssueAction.RESET';
    static SCAN_CODE = 'RentalIssueAction.SCAN_CODE';
    static VALIDATE_AND_SET_ITEM = 'RentalIssueAction.VALIDATE_AND_SET_ITEM';

    static LOAD_STATUS_FROM_ITEMS = 'RentalIssueAction.LOAD_STATUS_FROM_ITEMS';

    static SUBMIT = 'RentalIssueAction.SUBMIT';

    static TOGGLE_REMARKS = 'RentalIssueAction.TOGGLE_REMARKS'
    static CHANGE_REMARKS = 'RentalIssueAction.CHANGE_REMARKS'

    static TOGGLE_POPUP = 'RentalIssueAction.TOGGLE_POPUP'

    static UNLINK_ITEM = 'RentalIssueAction.UNLINK_ITEM'

    static LOAD_FROM_RENTAL = 'RentalIssueAction.LOAD_FROM_RENTAL'

    static LOAD_INITIAL_CATEGORY_SELECTION = 'RentalIssueAction.LOAD_INITIAL_CATEGORY_SELECTION'

    static MUTATE_CATEGORY_SELECTION = 'RentalIssueAction.MUTATE_CATEGORY_SELECTION'

    static TOGGLE_PASS_ON_CATEGORIES = 'RentalIssueAction.TOGGLE_PASS_ON_CATEGORIES'

    static scanCode(code, scanned) {
        return async (dispatch, getState) => {

            console.log(scanned)

            let fetchUrl = ''

            if (code.includes("t29.nl/i/")) {
                code = code.replace('http://', '');
                code = code.replace('https://', '');
                code = code.replace('www.', '');
                code = code.replace('t29.nl/i/', '');
                fetchUrl = ApiEnum.Api + '/item/detail-by-id?id=' + code
            } else {
                code = code.replace('http://2cr.nl/?1ATX0', '');
                code = code.replace('http://2CR.NL/?1ATX0', '');
                code = code.replace('http://2cr.nl/?1ABB0', '');
                code = code.replace('http://2CR.NL/?1ABB0', '');
                code = code.replace('http://2cr.nl/?1AVB0', '');
                code = code.replace('http://2CR.NL/?1AVB0', '');
                code = code.replace('http://2cr.nl/?1ALB0', '');
                code = code.replace('http://2CR.NL/?1ALB0', '');
                code = code.replace(/.*(?=DV)/, '');
                code = code.split('!')[0]
                fetchUrl = ApiEnum.Api + '/item/detail-by-code?code=' + code
            }

            const values = {
                method: 'post',
                scanned: !!scanned,
                fetchUrl: fetchUrl,
                accessToken: getState().authentication.accessToken,
                params: {
                    auth_token: localStorage.getItem('authToken'),
                }
            }

            if (getState().rentalDetail.data) {
                values.uuid = getState().rentalDetail.data.data.rental.uuid
            }

            await ActionUtility.createThunkEffect(dispatch, RentalIssueItemsAction.SCAN_CODE, RentalIssueItemsEffect.scanCode, values);

        };
    }

    static unlinkItem(itemId, rentalUuid) {
        return async (dispatch, getState) => {

            const values = {
                method: 'post',
                fetchUrl: ApiEnum.Api + '/item/unlink-rental?id=' + itemId + '&rental=' + rentalUuid,
                accessToken: getState().authentication.accessToken,
                params: {
                    auth_token: localStorage.getItem('authToken'),
                }
            }

            await ActionUtility.createThunkEffect(dispatch, RentalIssueItemsAction.UNLINK_ITEM, RentalIssueItemsEffect.unlinkItem, values);

        };
    }


    static validateAndSetItem(categoryAvailabilityCheck = false, removal = true) {
        return async (dispatch, getState) => {

            let availableCategories = Object.values(getState().rentalItems.bicycles)
            let rentalCategories = getState().rentalDetail.data.RentalItems
            availableCategories = availableCategories.concat(Object.values(getState().rentalItems.accessoires))

            let currentItems = getState().rentalIssue.items

            const values = {
                removal: removal,
                item: getState().rentalIssue.scannedItem,
                accessToken: getState().authentication.accessToken,
                categories: getState().rentalIssue.categorySelection.filter(category => category.quantity !== 0),
                rentalCategories: rentalCategories, //
                availableCategories: availableCategories,
                availabilityCheck: categoryAvailabilityCheck,
                currentItems: currentItems,
                params: {
                    auth_token: localStorage.getItem('authToken'),
                }
            }

            if (getState().rentalDetail.data && getState().rentalDetail.data.rentalItems) {
                values.uuid = getState().rentalDetail.data.data.rental.uuid
            }

            await ActionUtility.createThunkEffect(dispatch, RentalIssueItemsAction.VALIDATE_AND_SET_ITEM, RentalIssueItemsEffect.validateItem, values);
        };
    }

    static loadStatusFromItems() {

        return async (dispatch, getState) => {
            const values = {
                items: getState().rentalIssue.items,
                categorySelection: getState().rentalIssue.categorySelection
            }

            const detail = getState().rentalDetail

            if (detail) {
                values.categories = detail.RentalItems
                values.amount = detail.amount
            }

            await ActionUtility.createThunkEffect(dispatch, RentalIssueItemsAction.LOAD_STATUS_FROM_ITEMS, RentalIssueItemsEffect.loadStatusFromItems, values);

        };

    }

    static submit(overwrite = false, uuid = false, changePhase = true, create = false) {
        return async (dispatch, getState) => {

            if (!uuid) {
                uuid = getState().rentalDetail.data.data.rental.uuid
            }

            const values = {
                uuid: uuid,
                create: create,
                accessToken: getState().authentication.accessToken,
                params: {
                    auth_token: localStorage.getItem('authToken'),
                    items: getState().rentalIssue.items.filter(item => item.valid.status.code !== 'locked').map((item) => (item.id)),
                    overwrite: overwrite,
                    change_phase: changePhase,
                },
                mutationParams: {
                    auth_token: localStorage.getItem('authToken'),
                    categories: getState().rentalIssue.categorySelection.filter(category => category.quantity !== 0),
                    language: 'nl',
                    type: 'items'
                }
            }


            await ActionUtility.createThunkEffect(dispatch, RentalIssueItemsAction.SUBMIT, RentalIssueItemsEffect.submit, values);
        };
    }

    static toggleRemarks(value) {
        return async (dispatch, getState) => {

            const values = {
                show: value,
                defaultRemarks: ''
            }

            if (!getState().rentalIssue.remarks) {
                values.defaultRermarks = getState().rentalDetail.data.data.rental.remarks
            } else {
                values.defaultRermarks = getState().rentalIssue.remarks
            }

            await ActionUtility.createThunkEffect(dispatch, RentalIssueItemsAction.TOGGLE_REMARKS, RentalIssueItemsEffect.toggleRemarks, values);
        };
    }

    static loadFromRental(uuid) {
        return async (dispatch, getState) => {

            const values = {
                accessToken: getState().authentication.accessToken,
                params: {
                    auth_token: localStorage.getItem('authToken'),
                    filter: {
                        rental: uuid
                    },
                }
            }

            await ActionUtility.createThunkEffect(dispatch, RentalIssueItemsAction.LOAD_FROM_RENTAL, RentalIssueItemsEffect.loadFromRental, values);
        };
    }

    static togglePopup() {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, RentalIssueItemsAction.TOGGLE_POPUP, RentalIssueItemsEffect.togglePopup);
        };
    }

    static loadInitialCategorySelection() {


        return async (dispatch, getState) => {
            if (getState().rentalDetail && getState().rentalDetail.data && getState().rentalDetail.data.data) {
                await ActionUtility.createThunkEffect(dispatch, RentalIssueItemsAction.LOAD_INITIAL_CATEGORY_SELECTION, RentalIssueItemsEffect.loadInitialCategorySelection, getState().rentalDetail.data.data.rentalItems);
            }
        }

    }

    static mutateCategorySelection(values) {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, RentalIssueItemsAction.MUTATE_CATEGORY_SELECTION, RentalIssueItemsEffect.mutateCategorySelection, values);
        }

    }

    static togglePassOnCategories() {
        return async (dispatch, getState) => {

            const value = !getState().rentalIssue.passOnCategories;

            await ActionUtility.createThunkEffect(dispatch, RentalIssueItemsAction.TOGGLE_PASS_ON_CATEGORIES, RentalIssueItemsEffect.togglePassOnCategories, value);
        };
    }

    static reset() {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, RentalIssueItemsAction.RESET, RentalIssueItemsEffect.reset);
        };
    }

}

import data from '../../json/translations.json'
import dataFE from '../../json/translationsFE.json'
import dataLandal from '../../json/translationsLandal.json'
import {getLanguageCode} from "./getLanguageCode";

let store
export const injectStoreTranslate = _store => {
    store = _store
}

const translate = (key) => {

    let string = key

    function capitalize(s) {
        return s[0].toUpperCase() + s.slice(1);
    }

    const language = getLanguageCode()

    // Dormio F&E translations
    if (window.location.href.indexOf("/16/") > -1) {
        dataFE.filter(translateString => translateString["key"] === key).forEach(translateString => {
            string = translateString[language]
        })
    } else if (window.location.href.indexOf("/3/") > -1) {
        dataLandal.filter(translateString => translateString["key"] === key).forEach(translateString => {
            string = translateString[language]
        })
    }else{
        data.filter(translateString => translateString["key"] === key).forEach(translateString => {
            string = translateString[language]
        })
    }

    if(!string){
        string = key
    }

    if (store?.getState().environment?.data?.item_label) {
        string = string.replace('*item_single*', store?.getState().environment?.data?.item_label)
        string = string.replace('*item_single_cap*', capitalize(store?.getState().environment?.data?.item_label))
    }

    if (store?.getState().environment?.data?.item_label_plural) {
        string = string.replace('*item_plural*', store?.getState().environment?.data?.item_label_plural)
        string = string.replace('*item_plural_cap*', capitalize(store?.getState().environment?.data?.item_label_plural))
    }

    return string

}

export default translate

import AuthenticationAction from "./AuthenticationAction";

export default class AuthenticationReducer {

    static initialState = {
        auth: false,
        login: false,
        partnerUser: false,
        authTime: false,
        timeOut: false,
        accessToken: null,
        xPartnerLocationAuth: localStorage.getItem('xPartnerLocationAuth') ?? null,
        xPartnerUserAuth: localStorage.getItem('xPartnerUserAuth') ?? null
    };

    static reducer(state = AuthenticationReducer.initialState, action) {
        switch (action.type) {

            case AuthenticationAction.REQUEST_ACCESS_TOKEN:

                return {
                    ...state,
                };

            case AuthenticationAction.REQUEST_ACCESS_TOKEN + '_FINISHED':

                localStorage.setItem('accessToken', action.payload.response.accessToken.access_token);

                return {
                    ...state,
                    auth: action.payload.response,
                    accessToken: action.payload.response.accessToken.access_token
                };

            case AuthenticationAction.LOGIN:

                return {
                    ...state,
                    login: false,
                    xPartnerLocationAuth: null
                };

            case AuthenticationAction.LOGIN + '_FINISHED':

                const returnObject = {
                    ...state,
                    login: action.payload,
                }

                if (action.payload.partnerLocationAuth) {
                    localStorage.setItem('xPartnerLocationAuth', action.payload.partnerLocationAuth);
                    returnObject.xPartnerLocationAuth = action.payload.partnerLocationAuth

                }

                return returnObject

            case AuthenticationAction.GET_USER_BY_PIN:
                return {
                    ...state,
                };

            case AuthenticationAction.GET_USER_BY_PIN + '_FINISHED':

                const returnUserPin = {
                    ...state,
                    partnerUser: action.payload,
                    timeOut: false
                }

                if (action.payload.xPartnerUserAuth) {
                    localStorage.setItem('xPartnerUserAuth', action.payload.xPartnerUserAuth);
                    returnUserPin.xPartnerUserAuth = action.payload.xPartnerUserAuth
                }

                return returnUserPin

            case AuthenticationAction.TIME_OUT:
                return {
                    ...state,
                };

            case AuthenticationAction.TIME_OUT + '_FINISHED':
                return {
                    ...state,
                    partnerUser: false,
                    timeOut: true
                };

            case AuthenticationAction.LOGOUT + '_FINISHED':
                return {
                    ...state,
                    login: false,
                    partnerUser: false,
                };

            case AuthenticationAction.UNSET_USER:
                return {
                    ...state,
                    login: false
                };

            case AuthenticationAction.UNSET_USER + '_FINISHED':
                return {
                    ...state,
                    partnerUser: false,
                    authTime: false,
                    timeOut: false
                };

            case AuthenticationAction.UPDATE_PARTNER_LOCATION_AUTH + '_FINISHED':
                return {
                    ...state,
                    xPartnerLocationAuth: action.payload.xPartnerLocationAuth,
                };

            default:
                return state;
        }
    }
}
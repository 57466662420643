// Dependencies
import  React from 'react'
import {Route, Switch} from "react-router";
import {connect} from "react-redux";

// Constants
import RouteEnum from "../../common/constants/RouteEnum";

// Views
import Login from "./Login";
import Pin from "./Pin";
import PinForgot from "./PinForgot";
import PinTimeOut from "./PinTimeOut";

// Components
import AuthWrapper from "../../common/components/Authentication/AuthWrapper";

// Helpers
import UserIsLoggedIn from "../../common/helpers/UserIsLoggedIn";
import {ForgotPasswordForm} from "../../createSlice/features/pages/Authentication/ForgotPasswordForm";
import {ResetPasswordForm} from "../../createSlice/features/pages/Authentication/ResetPasswordForm";
import {
    TwoFactorAuthModalContainer
} from "../../createSlice/features/modals/TwoFactorAuthModal/TwoFactorAuthModalContainer";

const mapStateToProps = (state) => ({
    ...state.authentication,
    environment: state.environment,
    forgotPassword: state.forgotPassword,
    requestedPasswordReset: state.requestedPasswordReset,
    twoFactorAuthModal: state.twoFactorAuthModal
});

class Authenticate extends React.Component {

    render() {

        /** USER LOGGED IN **/
        if (UserIsLoggedIn()) return <LoggedInDisplay {...this.props}/>;

        /** USER NOT LOGGED IN **/
        if (!UserIsLoggedIn()) return <LoggedOutDisplay {...this.props}/>

    }

}

const LoggedOutDisplay = (props) => (
    <AuthWrapper history={props.history}>
        <Switch>
            <Route path={'/:partnerId/:partnerSlug?' + RouteEnum.PasswordReset}
                   component={ResetPasswordForm}/>
            <Route path={'/:partnerId/:partnerSlug?' + RouteEnum.PasswordForgot} component={ForgotPasswordForm}/>
            <Route component={Login}/>
        </Switch>
    </AuthWrapper>
);

const LoggedInDisplay = (props) => (
    <AuthWrapper history={props.history}>
        <Switch>
            <Route
                exact
                path={'/:partnerSlug?' + RouteEnum.PinForgot}
                history={props.history}
                component={PinForgot}
            />

            {!!props.twoFactorAuthModal.active && <Route component={TwoFactorAuthModalContainer}/>}
            {!props.timeOut && <Route component={Pin}/>}
            {!!props.timeOut && <Route component={PinTimeOut}/>}

        </Switch>
    </AuthWrapper>
);

export default connect(mapStateToProps)(Authenticate);

import {apiEnum} from "../../../enums/apiEnum";
import {useAppAxios} from "../../../../app/hooks";

export const apiPartnerEventCheckin = async (args) => {

    return await useAppAxios.post(apiEnum.PARTNER + '/event/checkin', {}, {
        params: {
            code: args.code,
        }
    }).then(function (response) {

        return response

    }).catch(function (error) {

        return {
            data: {
                status: {
                    code: 500
                }
            }
        }

    })
}

import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    setTwoFactorCode,
    submitTwoFactor,
    twoFactorAuthModalReducer,
    twoFactorAuthModalSlice, twoFactorInput
} from "./twoFactorAuthModalSlice";
import {TinyLoader} from "../../../common/components/TinyLoader";
import translate from "../../../common/helpers/utils/translate";
import React from "react";
import EnvironmentAction from "../../../../stores/environment/EnvironmentAction";
import PinInput from "react-pin-input";
import {requestNewTwoFactorCode} from "../SendTwoFactorAuthModal/SendTwoFactorAuthModalSlice";

export const TwoFactorAuthModalContainer = () => {

    const {twoFactorCode, submitting, error} = useAppSelector((state) => state.twoFactorAuthModal)
    const { requestingNewCode, error: newCodeError } = useAppSelector((state) => state.sendTwoFactorAuthModal);
    const dispatch = useAppDispatch()
    const {dark} = useAppSelector(state => state.environment)

    const handleRequestNewCode = () => {
        dispatch(requestNewTwoFactorCode());
    };

    return (
        <form className="c-auth__form c-form"
              onSubmit={(e) => {
                  e.preventDefault()
                  dispatch(EnvironmentAction.setLoader(true)).then(() => {
                      dispatch(submitTwoFactor()).then(() => {
                          dispatch(EnvironmentAction.setLoader(false))
                      })
                  })
              }}>

            <small>Two Factor Authentication</small>

            <div className="c-form__row">
                <div className="c-form__group">
                    <label htmlFor="2fa-code">{translate('Enter the 2fa code send to your email')}</label>
                    <PinInput
                        length={6}
                        focus={true}
                        type="numeric"
                        onComplete={(value, index) => {
                            dispatch(setTwoFactorCode(value, index))
                        }}
                    />
                </div>
            </div>

            <div className="c-form__row">
                <div className="c-form__group c-form__group--buttons">
                    <button className="btn btn--primary" type="submit" style={{backgroundColor: dark}}>
                        <span>{translate('submit2fa')}</span>
                    </button>
                    <a className="btn btn--secondary" onClick={handleRequestNewCode}>
                        <span>{translate('RequestNewCode')}</span>
                    </a>
                </div>
            </div>

            {!!error &&
                <>
                    <div className="c-notification c-notification--error">
                        <i className="icon icon-info"/>
                        <span>{error}</span>
                    </div>
                </>
            }

            {!!newCodeError &&
                <div className="c-notification c-notification--error">
                    <i className="icon icon-info" />
                    <span>{newCodeError}</span>
                </div>
            }

        </form>
    )
}
import fetchHelper from "../../../common/helpers/fetchHelper";
import ErrorAction from "../../error/ErrorAction";
import RentalCreateAction from "./RentalCreateAction";
import RentalDispatcher from "../../../common/dispatchers/RentalDispatcher";


export default class RentalCreateEffect {

    static async submit(data, dispatch) {

        const response = await fetchHelper(data, dispatch)


        if (response?.status?.code !== 400) {
            return response
        } else {
            const dispatcher = RentalDispatcher(dispatch)
            dispatcher.resetAll()
            return {
                resetMessage: 'Er is geen beschikbaarheid meer voor een van de gekozen categorieen. Probeer het opnieuw.'
            }
        }

    }

    static reset() {
        return true;
    }

}


import moment from "moment";

import ActionUtility from "../../../common/helpers/ActionUtility";
import RentalCategoryEffect from "./RentalCategoryEffect";
import FormatCartToRentalRequest from "../../../common/helpers/FormatCartToRentalRequest";
import ApiEnum from "../../../common/constants/ApiEnum";
import MapValuesAsGetParameters from "../../../common/helpers/MapValuesAsGetParameters";
import FormatDetailToCategoryRequest from "../../../common/helpers/FormatDetailToCategoryRequest";
import CalculateSwapToCategoryRequest from "../../../common/helpers/CalculateSwapToCategoryRequest";

export default class RentalCategoryAction {


    static GET_AVAILABLE_BICYCLES = 'RentalItemsAction.GET_AVAILABLE_BICYCLES';
    static GET_AVAILABLE_ACCESSOIRES = 'RentalItemsAction.GET_AVAILABLE_ACCESSOIRES';

    static MUTATE_BICYCLE_SELECTION_NUMBER = 'RentalItemsAction.MUTATE_BICYCLE_SELECTION_NUMBER';
    static MUTATE_ACCESSOIRE_SELECTION_NUMBER = 'RentalItemsAction.MUTATE_ACCESSOIRE_SELECTION_NUMBER';

    static UPDATE_CART = 'RentalItemsAction.UPDATE_CART';
    static EMPTY_CART = 'RentalItemsAction.EMPTY_CART';

    static RESET = 'RentalItemsAction.RESET';
    static SUBMIT_MUTATION = 'RentalItemsAction.SUBMIT_MUTATION';

    static LOAD_BICYCLE_SELECTION_FROM_INFO = 'RentalItemsAction.LOAD_BICYCLE_SELECTION_FROM_INFO';
    static LOAD_ACCESSOIRE_SELECTION_FROM_INFO = 'RentalItemsAction.LOAD_ACCESSOIRE_SELECTION_FROM_INFO';


    static getAvailableBicycles(includeAvailableForRental = true) {

        return async (dispatch, getState) => {

            let getParams = {
                startDate: moment(getState().rentalPeriod.period.startDate).format('YYYY-MM-DD') + ' ' + getState().rentalPeriod.period.startTime,
                endDate: moment(getState().rentalPeriod.period.endDate).format('YYYY-MM-DD') + ' ' + getState().rentalPeriod.period.endTime,
                language: 'nl',
                timeUnitId: getState().rentalPeriod.period.chosenUnit
            }

            if (getState().rentalDetail?.data?.data?.rental?.uuid) {
                getParams = {
                    startDate: moment(getState().rentalPeriod.period.startDate).format('YYYY-MM-DD') + ' ' + getState().rentalPeriod.period.startTime,
                    endDate: moment(getState().rentalPeriod.period.endDate).format('YYYY-MM-DD') + ' ' + getState().rentalPeriod.period.endTime,
                    language: 'nl',
                    rentalId: getState().rentalDetail.data.data.rental.uuid,
                    timeUnitId: getState().rentalPeriod.period.chosenUnit || getState().rentalDetail.data.data.time_unit.id
                }
            }

            const values = {
                fetchUrl: ApiEnum.Api + '/availability/per-category' + MapValuesAsGetParameters(getParams),
                method: 'post',
                shoppingCart: getState().rentalItems.shoppingCart,
                accessToken: getState().authentication.accessToken,
                params: {
                    auth_token: localStorage.getItem('authToken'),
                    visible_in_partner_environment: includeAvailableForRental
                }

            }

            await ActionUtility.createThunkEffect(dispatch, RentalCategoryAction.GET_AVAILABLE_BICYCLES, RentalCategoryEffect.getAvailableBicycles, values);
        }

    }

    static getAvailableAccessoires(includeAvailableForRental = true) {

        return async (dispatch, getState) => {

            let getParams = {
                startDate: moment(getState().rentalPeriod.period.startDate).format('YYYY-MM-DD') + ' ' + getState().rentalPeriod.period.startTime,
                endDate: moment(getState().rentalPeriod.period.endDate).format('YYYY-MM-DD') + ' ' + getState().rentalPeriod.period.endTime,
                language: 'nl',
                type: 'accessoire',
                timeUnitId: getState().rentalPeriod.period.chosenUnit
            }

            if (getState().rentalDetail?.data?.data?.rental?.uuid) {
                getParams = {
                    startDate: moment(getState().rentalPeriod.period.startDate).format('YYYY-MM-DD') + ' ' + getState().rentalPeriod.period.startTime,
                    endDate: moment(getState().rentalPeriod.period.endDate).format('YYYY-MM-DD') + ' ' + getState().rentalPeriod.period.endTime,
                    language: 'nl',
                    type: 'accessoire',
                    rentalId: getState().rentalDetail.data.data.rental.uuid,
                    timeUnitId: getState().rentalPeriod.period.chosenUnit || getState().rentalDetail.data.data.time_unit.id
                }
            }

            const values = {
                fetchUrl: ApiEnum.Api + '/availability/per-category' + MapValuesAsGetParameters(getParams),
                method: 'post',
                shoppingCart: getState().rentalItems.shoppingCart,
                auth_token: localStorage.getItem('authToken'),
                accessToken: getState().authentication.accessToken,
                params: {
                    auth_token: localStorage.getItem('authToken'),
                    visible_in_partner_environment: includeAvailableForRental
                }
            }

            await ActionUtility.createThunkEffect(dispatch, RentalCategoryAction.GET_AVAILABLE_ACCESSOIRES, RentalCategoryEffect.getAvailableAccessoires, values);
        }

    }

    static loadBicycleSelectionFromInfo() {
        return async (dispatch, getState) => {

            if (getState().rentalDetail.data && getState().rentalDetail.data.data) {
                const data = {
                    rentalItems: getState().rentalDetail.data.data.rentalItems,
                    items: getState().rentalItems.bicycles,
                }

                await ActionUtility.createThunkEffect(dispatch, RentalCategoryAction.LOAD_BICYCLE_SELECTION_FROM_INFO, RentalCategoryEffect.loadQuantityFromInfo, data);
            }
        }
    }

    static loadAccessoireSelectionFromInfo() {
        return async (dispatch, getState) => {

            if (getState().rentalDetail && getState().rentalDetail.data && getState().rentalDetail.data.data) {
                const data = {
                    rentalItems: getState().rentalDetail.data.data.rentalItems,
                    items: getState().rentalItems.accessoires,
                    accessToken: getState().authentication.accessToken
                }

                await ActionUtility.createThunkEffect(dispatch, RentalCategoryAction.LOAD_ACCESSOIRE_SELECTION_FROM_INFO, RentalCategoryEffect.loadQuantityFromInfo, data);

            }
        }
    }

    static updateCart() {
        return async (dispatch, getState) => {
            const values = {}
            values.bicycles = getState().rentalItems.bicycles
            values.accessoires = getState().rentalItems.accessoires

            await ActionUtility.createThunkEffect(dispatch, RentalCategoryAction.UPDATE_CART, RentalCategoryEffect.updateCart, values);
        };
    }

    static emptyCart() {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, RentalCategoryAction.EMPTY_CART, RentalCategoryEffect.emptyCart);
        };
    }


    static mutateBicycleSelectionNumber(values) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, RentalCategoryAction.MUTATE_BICYCLE_SELECTION_NUMBER, RentalCategoryEffect.mutateSelectionNumber, values);
        };
    }

    static mutateAccessoireSelectionNumber(values) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, RentalCategoryAction.MUTATE_ACCESSOIRE_SELECTION_NUMBER, RentalCategoryEffect.mutateSelectionNumber, values);
        };
    }

    static reset() {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, RentalCategoryAction.RESET, RentalCategoryEffect.reset);
        }
    }

    static submitMutation() {
        return async (dispatch, getState) => {

            const values = {
                fetchUrl: ApiEnum.Api + '/mutation/create?rental=' + getState().rentalDetail.data.data.rental.uuid,
                method: 'post',
                accessToken: getState().authentication.accessToken,
                params: {
                    auth_token: localStorage.getItem('authToken'),
                    categories: FormatCartToRentalRequest(getState().rentalItems.shoppingCart),
                    language: 'nl',
                    type: 'items'
                }
            }

            await ActionUtility.createThunkEffect(dispatch, RentalCategoryAction.SUBMIT_MUTATION, RentalCategoryEffect.submitMutation, values);
        }
    }

    static cleanCategories() {
        return async (dispatch, getState) => {

            const values = {
                fetchUrl: ApiEnum.Api + '/mutation/create?rental=' + getState().rentalDetail.data.data.rental.uuid,
                method: 'post',
                accessToken: getState().authentication.accessToken,
                params: {
                    auth_token: localStorage.getItem('authToken'),
                    categories: FormatDetailToCategoryRequest(getState().rentalIssue.items, getState().rentalDetail.data.data),
                    language: 'nl',
                    type: 'items'
                }
            }

            await ActionUtility.createThunkEffect(dispatch, RentalCategoryAction.SUBMIT_MUTATION, RentalCategoryEffect.submitMutation, values);
        }
    }

    static passOnAvarageSwap() {

        return async (dispatch, getState) => {


            const values = {
                fetchUrl: ApiEnum.Api + '/mutation/create?rental=' + getState().rentalDetail.data.data.rental.uuid,
                method: 'post',
                accessToken: getState().authentication.accessToken,
                params: {
                    auth_token: localStorage.getItem('authToken'),
                    categories: CalculateSwapToCategoryRequest(getState().rentalDetail.data.data.rentalItems, getState().rentalReturn.items, getState().rentalIssue.items.filter(item => item.valid.status.code !== 'locked'), getState().rentalItems.bicycles, getState().rentalDetail.data.data),
                    language: 'nl',
                    type: 'items'
                }
            }

            await ActionUtility.createThunkEffect(dispatch, RentalCategoryAction.SUBMIT_MUTATION, RentalCategoryEffect.submitMutation, values);
        }
    }
}

import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {apiPartnerRentalDetail} from "../../../common/api/partner/rental/apiPartnerRentalDetail";
import {actionValidateGiveItemAction} from "../../../common/actions/actionValidateGiveItemAction";
import {actionChangeCategoryMutation} from "../../../common/actions/actionChangeCategoryMutation";
import {
    apiParnterMutationCreateCategories
} from "../../../common/api/partner/mutation/apiPartnerMutationCreateCategories";
import {apiPartnerItemLinkRentalAll} from "../../../common/api/partner/item/apiPartnerItemLinkRentalAll";
import {apiPartnerRentalAddDeposit} from "../../../common/api/partner/rental/apiPartnerRentalAddDeposit";
import {apiPartnerRentalRemoveDeposit} from "../../../common/api/partner/rental/apiPartnerRentalRemoveDeposit";
import {actionGiveItem} from "../../../common/actions/actionGiveItem";
import {
    formatRentalItemsToCategoryMutations
} from "../../../common/helpers/format/formatRentalItemsToCategoryMutations";
import {
    apiPartnerAvailabilityPerCategory
} from "../../../common/api/partner/availability/apiPartnerAvailabilityPerCategory";
import moment from "moment";
import {resetUnlinkItemModal} from "../../modals/unlinkItemModal/unlinkItemModalSlice";
import {resetServiceModal} from "../../../../views/serviceModal/serviceModalSlice";
import {formatRentalContainerToRentalItems} from "../../../common/helpers/format/formatRentalContainerToItems";


export const loadRentalItemsGive = createAsyncThunk(
    'rentalItemsGive/load',
    async (rentalUuid, {getState}) => {

        try {

            const rentalDetailResponse = await apiPartnerRentalDetail(rentalUuid)
            const rentalContainer = rentalDetailResponse?.data?.items

            const rentalStartDateMoment = moment(rentalContainer?.rental.dateRentStart)
            const currentMoment = moment()
            let startDate

            //  if (rentalStartDateMoment.isBefore(currentMoment)) {
            //    startDate = currentMoment.format('YYYY-MM-DD HH:mm')
            //   } else {
            startDate = rentalStartDateMoment.format('YYYY-MM-DD HH:mm')
            // }

            const availabilityResponse = await apiPartnerAvailabilityPerCategory({visibleInPartnerEnvironment: true}, {
                startDate: startDate,
                endDate: moment(rentalContainer?.rental.dateRentEnd).format('YYYY-MM-DD HH:mm'),
                rentalId: rentalContainer.rental.uuid
            })

            const availabilityAccessoireResponse = await apiPartnerAvailabilityPerCategory({visibleInPartnerEnvironment: true}, {
                startDate: startDate,
                endDate: moment(rentalContainer?.rental.dateRentEnd).format('YYYY-MM-DD HH:mm'),
                type: 'accessoire',
                rentalId: rentalContainer.rental.uuid
            })

            return {
                rentalContainer: rentalContainer,
                rentalItems: formatRentalContainerToRentalItems(rentalContainer),
                categoryAvailabilities: [...availabilityResponse.data.items, ...availabilityAccessoireResponse.data.items]
            }

        } catch (e) {
            console.log(e)
        }
    }
)

export const submitRentalItemsGive = createAsyncThunk(
    'rentalItemsGive/submit',
    async (_, {getState}) => {

        const {root} = getState()
        const {rentalItemsGivePage} = getState()

        if (rentalItemsGivePage.rentalContainer) {

            // Submit link items
            await apiPartnerItemLinkRentalAll({
                uuid: rentalItemsGivePage.rentalContainer.rental.uuid,
                items: rentalItemsGivePage.rentalGiveItems,
                changePhase: true
            })

            // Submit category mutations
            if ((rentalItemsGivePage.submitOption === 'swap' || rentalItemsGivePage.submitOption === 'delete') && rentalItemsGivePage.categoryMutations) {

                await apiParnterMutationCreateCategories({
                    categoryMutations: rentalItemsGivePage.categories.filter((category) => category.quantity !== 0),
                    rentalUuid: rentalItemsGivePage.rentalContainer.rental.uuid
                })

            }

            // Add or remove deposit
            if (!rentalItemsGivePage.rentalContainer.rental.delivery && getState().environment.data.info.enable_deposit) {
                await apiPartnerRentalAddDeposit(rentalItemsGivePage.rentalContainer.rental.uuid)
            } else {
                await apiPartnerRentalRemoveDeposit(rentalItemsGivePage.rentalContainer.rental.uuid)
            }

            return true

        } else {
            return false
        }

    }
)

export const giveItem = createAsyncThunk(
    'rentalItemsGive/giveItem',
    async (data, {getState, dispatch, rejectWithValue}) => {

        const {item, scanned} = data

        try {
            const {rentalItemsGivePage} = getState()

            // Reset modals
            dispatch(resetUnlinkItemModal())
            dispatch(resetServiceModal())

            return actionGiveItem({
                item: item,
                rentalItems: rentalItemsGivePage.rentalItems,
                rentalGiveItems: rentalItemsGivePage.rentalGiveItems,
                occupiedItems: rentalItemsGivePage.occupiedItems,
                serviceItems: rentalItemsGivePage.serviceItems,
                scanned: !!scanned
            })

        } catch (e) {
            console.log(e)
        }

    }
)


export const validateGiveItems = createAsyncThunk(
    'rentalItemsGive/validateGiveItems',
    async (_, {getState}) => {

        const {rentalItemsGivePage} = getState()

        return actionValidateGiveItemAction({
            rentalContainer: rentalItemsGivePage.rentalContainer,
            rentalGiveItems: rentalItemsGivePage.rentalGiveItems,
            rentalItems: rentalItemsGivePage.rentalItems,
            categoryMutations: rentalItemsGivePage.categoryMutations,
            rentalGiveItemNotifications: rentalItemsGivePage.rentalGiveItemNotifications,
            submitOption: rentalItemsGivePage.submitOption,
            categories: rentalItemsGivePage.categoryAvailabilities
        })
    }
)

export const setCategoryMutation = createAsyncThunk(
    'rentalItemsGive/setCategoryMutation',
    async (categoryMutation, {getState}) => {

        try {

            const {rentalItemsGivePage} = getState();

            return actionChangeCategoryMutation({
                categoryMutation: categoryMutation,
                rentalContainer: rentalItemsGivePage.rentalContainer,
                categoryMutationsState: rentalItemsGivePage.categoryMutations
            })

        } catch (e) {
            console.log(e.message)
        }

    }
)


const initialState = {
    loading: false,
    step: 'give',
    rentalItems: [],
    rentalGiveItems: [],
    occupiedItems: [],
    serviceItems: [],
    categoryMutations: [],
    rentalGiveItemNotifications: [],
    categoryAvailabilities: [],
    giveItemValidation: {
        processing: false,
        completed: false,
        tooManyCategoriesGiven: false,
        notAllCategoriesGiven: false
    },
    submitOption: 'default',
    submitting: false
}

export const rentalItemsGivePageSlice = createSlice({
    name: 'rentalItemsGive',
    initialState,
    reducers: {
        changeStep: (state, action) => {
            state.step = action.payload
            state.submitOption = 'default'
        },
        setSubmitOption: (state, action) => {
            state.submitOption = action.payload
        },
        formatCategories: (state) => {

            if (state.rentalContainer) {
                state.categories = formatRentalItemsToCategoryMutations({
                    rentalOrderLines: state.rentalContainer.rentalItems,
                    categoryMutations: state.categoryMutations,
                    rentalGiveItems: state.rentalGiveItems,
                    rentalItems: state.rentalItems,
                    compensationData: {
                        categoryAvailabilities: state.categoryAvailabilities,
                        rentalContainer: state.rentalContainer,
                    }
                })
            }

        },

    },
    extraReducers: (builder) => {

        builder.addCase(loadRentalItemsGive.pending, (state) => {
            Object.assign(state, initialState)
            state.loading = true
        })

        builder.addCase(loadRentalItemsGive.fulfilled, (state, action) => {
            state.rentalContainer = action.payload.rentalContainer
            state.rentalItems = action.payload.rentalItems
            state.categoryAvailabilities = action.payload.categoryAvailabilities
        })

        builder.addCase(giveItem.pending, (state) => {
            state.rentalGiveItemNotifications = []
        })

        builder.addCase(giveItem.fulfilled, (state, action) => {

            if (action.payload) {
                state.rentalGiveItems = action.payload.rentalGiveItems
                state.occupiedItems = action.payload.occupiedItems
                state.serviceItems = action.payload.serviceItems
            }

        })
        builder.addCase(setCategoryMutation.fulfilled, (state, action) => {

            if (action.payload) {
                state.categoryMutations = action.payload.categoryMutations
            }

        })
        builder.addCase(validateGiveItems.pending, (state) => {

            state.giveItemValidation = {
                ...state.giveItemValidation,
                processing: true,
                completed: false
            }

        })
        builder.addCase(validateGiveItems.fulfilled, (state, action) => {

            state.giveItemValidation = action.payload.giveItemValidation
            state.rentalGiveItems = action.payload.rentalGiveItems
            state.rentalGiveItemNotifications = action.payload.rentalItemNotifications

        })
        builder.addCase(submitRentalItemsGive.pending, (state) => {

            state.submitting = true

        })
        builder.addCase(submitRentalItemsGive.fulfilled, (state) => {

            Object.assign(state, initialState)

        })
    },
})

export const {
    changeStep,
    setSubmitOption,
    formatCategories
} = rentalItemsGivePageSlice.actions

export const rentalItemsGivePageReducer = rentalItemsGivePageSlice.reducer

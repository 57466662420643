import moment from "moment";
import TimePicker from "rc-time-picker";
import 'rc-time-picker/assets/index.css';
import {useEffect, useRef, useState} from "react";
import {calculateNextStartTime} from "../helpers/calculate/calculateNextStartTime";
import translate from "../helpers/utils/translate";
import {apiPartnerTimeUnitList} from "../api/partner/timeUnit/apiPartnerTimeUnitList";
import {apiPartnerTimeUnitCalculate} from "../api/partner/timeUnit/apiPartnerTimeUnitCalculate";
import {translateTimeUnit} from "../helpers/utils/translateTimeUnit";


export const RentalPeriod = (props) => {

    const {bookingPhase} = props
    const [isMounted, setIsMounted] = useState(false)
    const [defaultIsSet, setDefault] = useState(false)

    const [period, setPeriod] = useState({
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        startTime: calculateNextStartTime(),
        amount: 0
    })

    const [periodAmount, setPeriodAmount] = useState()
    const [timeUnits, setTimeUnits] = useState()
    const [calculating, setCalculating] = useState(false)


    useEffect(() => {

        if (isMounted) {

            if (props.rentalContainer) {

                let startDate = null
                let endDate = null
                let chosenUnit = null
                let amount = null
                let startTime = null
                let endTime = null

                if (!!props.rentalContainer.data.rental.date_rent_start && !!props.rentalContainer.data.rental.date_rent_end && !!props.rentalContainer.data.time_unit.id) {
                    startDate = moment(props.rentalContainer.data.rental.date_rent_start).format('YYYY-MM-DD')
                    endDate = moment(props.rentalContainer.data.rental.date_rent_end).format('YYYY-MM-DD')
                    chosenUnit = props.rentalContainer.data.time_unit.id
                    amount = props.rentalContainer.data.time_unit_amount
                    startTime = moment(props.rentalContainer.data.rental.date_rent_start).format('HH:mm')
                    endTime = moment(props.rentalContainer.data.rental.date_rent_end).format('HH:mm')
                } else {
                    startDate = moment(props.rentalContainer.rental?.dateRentStart).format('YYYY-MM-DD')
                    endDate = moment(props.rentalContainer.rental?.dateRentEnd).format('YYYY-MM-DD')
                    chosenUnit = props.rentalContainer.rental.timeUnitId
                    amount = period.amount
                    startTime = moment(props.rentalContainer.rental?.dateRentStart).format('HH:mm')
                    endTime = moment(props.rentalContainer.rental?.dateRentEnd).format('HH:mm')
                }

                setPeriod({
                    ...period,
                    startDate: startDate,
                    endDate: endDate,
                    amount: amount,
                    chosenUnit: chosenUnit,
                    startTime: startTime,
                    endTime: endTime
                })
            }
        } else {
            setIsMounted(true)
        }

        const getTimeUnitList = async () => {
            const response = await apiPartnerTimeUnitList()
            if (response.data?.items?.data) {
                setTimeUnits(response.data.items.data)
            }
        }

        getTimeUnitList()

    }, [props.rentalContainer, isMounted])


    const calculateAndSetPeriod = async (period) => {

        // Set default period
        setCalculating(true)

        const response = await apiPartnerTimeUnitCalculate({
            startDate: period.startDate,
            amount: period.amount,
            timeUnitId: period.chosenUnit,
            startTime: period.startTime
        })

        if (response.data?.status?.message) {
            setPeriod({
                ...period,
                startDate: response.data?.status?.message.startDate,
                startTime: response.data?.status?.message.startTime,
                endDate: response.data?.status?.message.endDate,
                endTime: response.data?.status?.message.endTime
            })
        }
        setCalculating(false)
    }


    useEffect(() => {
        props.setPeriod(period)
    }, [period])


    const selectedUnitType = timeUnits?.find((timeUnit) => timeUnit.id === period.chosenUnit)

    useEffect(() => {


        if (timeUnits && !defaultIsSet) {
            const timeUnit = timeUnits?.find((timeUnit) => !!timeUnit.isDefault)
            let periodAmount = timeUnit?.minAmount ? timeUnit.minAmount : 1

            if (period.amount) {
                periodAmount = period.amount
            }

            setDefault(true);
            setPeriodAmount(periodAmount)

            calculateAndSetPeriod({
                ...period,
                amount: periodAmount,
                chosenUnit: parseInt(timeUnit.id)
            })
        }


    }, [timeUnits])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const amount = parseInt(periodAmount)

            if (selectedUnitType && amount < selectedUnitType.minAmount){
                setPeriodAmount(selectedUnitType.minAmount)
            }

            if (selectedUnitType && amount > selectedUnitType.maxAmount){
                setPeriodAmount(selectedUnitType.maxAmount)
            }

            if (selectedUnitType && amount >= selectedUnitType.minAmount && amount <= selectedUnitType.maxAmount) {
                calculateAndSetPeriod({
                    ...period,
                    amount: parseInt(periodAmount)
                })
            }
        }, 500)

        return () => clearTimeout(delayDebounceFn)
    }, [periodAmount])
    
    return (
        <div className="c-info-blocks__row">
            <div className="c-info-blocks__single">
                <header className="c-subject-header c-subject-header--closed">
                    <h2>{translate('rentalPeriod')}</h2>
                </header>
                <div className="c-info-blocks__single__body">

                    {timeUnits?.length !== 1 &&
                    <div className={'c-form__row'} style={{display: 'block'}}>
                        {timeUnits?.map((timeUnit) => {

                            return (
                                <div className="c-form__group"
                                     style={{display: 'inline-block', alignItems: 'center', width: 'auto'}}>
                                    <label>
                                        <input
                                            name={'time-unit'}
                                            type={'radio'}
                                            onChange={(e) => {
                                                const selectedUnitTypeInside = timeUnits?.find((timeUnit) => timeUnit.id === parseInt(e.target.value))
                                                calculateAndSetPeriod({
                                                    ...period,
                                                    amount: selectedUnitTypeInside?.minAmount ? selectedUnitTypeInside.minAmount : 1,
                                                    chosenUnit: parseInt(e.target.value),
                                                    startTime: selectedUnitTypeInside?.typeId === 1 || selectedUnitTypeInside?.typeId === 2 ? calculateNextStartTime() : period.startTime
                                                })
                                            }}
                                            disabled={calculating}
                                            checked={timeUnit.id === period.chosenUnit}
                                            value={timeUnit.id}
                                            style={{margin: '0 1rem 0 0'}}
                                        />
                                        Per {translate(timeUnit.type.name)} x {timeUnit.amount}</label>
                                </div>
                            )

                        })}
                    </div>
                    }

                    {!!selectedUnitType &&
                    <div className="c-form__row">

                        <div className="c-form__group">

                            <label htmlFor="dateStart">
                                {translate('amount-of-unit')}: {translate(selectedUnitType.type.name)} x {selectedUnitType.amount}
                            </label>

                            <input
                                type={'number'}
                                value={periodAmount}
                                min={selectedUnitType.minAmount}
                                max={selectedUnitType.maxAmount}
                                disabled={calculating}
                                onChange={(e) => setPeriodAmount(e.target.value)}
                            />
                        </div>

                        <div className="c-form__group">
                            <label htmlFor="dateStart">{translate('fromDate')}</label>

                            <input type={'date'}
                                   disabled={bookingPhase === 'note' || calculating}
                                   onChange={(e) => calculateAndSetPeriod({
                                       ...period,
                                       startDate: e.target.value,

                                   })}
                                   min={moment().format('YYYY-MM-DD')}
                                   value={period.startDate}/>
                        </div>


                        <div className="c-form__group">
                            <label htmlFor="dateEnd">{translate('toDate')}</label>
                            <input type={'date'}
                                   disabled={true}
                                   min={moment().format('YYYY-MM-DD')}
                                   value={period.endDate}/>
                        </div>


                        {!!selectedUnitType.timeRequired &&

                        <>

                            <div className="c-form__group" style={{maxWidth: '15rem'}}>
                                <label htmlFor="dateStart">{translate('startTime')}</label>
                                <TimePicker
                                    value={moment('2022-12-12 ' + period.startTime)}
                                    onChange={(value) => {
                                        calculateAndSetPeriod({
                                            ...period,
                                            startTime: value.format('HH:mm')

                                        })
                                    }}
                                    disabled={bookingPhase === 'note' || calculating}
                                    allowEmpty={false}
                                    showSecond={false}
                                    minuteStep={15}
                                />
                            </div>

                            <div className="c-form__group" style={{maxWidth: '15rem'}}>
                                <label htmlFor="dateStart">{translate('endTime')}</label>
                                <TimePicker
                                    value={moment('2022-12-12 ' + period.endTime)}
                                    disabled={true}
                                    allowEmpty={false}
                                    showSecond={false}
                                    minuteStep={15}
                                />
                            </div>

                        </>
                        }

                    </div>
                    }

                </div>
            </div>
        </div>
    )

}

import TransactionAction from "./TransactionAction";
import moment from 'moment'

export default class TransactionReducer {

    static initialState = {
        updateResponse: null,
        updatePaymentResponse: null,
        detail: null,
        list: {
            data: null,
            totals: null,
            pages: null
        },
        listPeriod: {
            start: moment().startOf('day').toDate(),
            end: moment().endOf('day').toDate()
        },
        listFilter: {},
        activeListPage: 0,
        listSearchValue: '',
        totals: null,
        selectedDate: moment().format('YYYY-MM-DD'),
    };

    static reducer(state = TransactionReducer.initialState, action) {


        switch (action.type) {

            case TransactionAction.REQUEST_DETAIL:
                return {
                    ...state,
                };

            case TransactionAction.REQUEST_DETAIL + '_FINISHED':

                return {
                    ...state,
                    detail: action.payload
                };


            case TransactionAction.REQUEST_LIST:
                return {
                    ...state,
                    list: false
                };

            case TransactionAction.REQUEST_LIST + '_FINISHED':

                return {
                    ...state,
                    list: action.payload
                };

            case TransactionAction.REQUEST_LIST_BY_LOCATION:
                return {
                    ...state,
                };

            case TransactionAction.REQUEST_LIST_BY_LOCATION + '_FINISHED':

                return {
                    ...state,
                    list: action.payload
                };


            case TransactionAction.UPDATE_STATUS:
                return {
                    ...state,
                };

            case TransactionAction.UPDATE_STATUS + '_FINISHED':

                return {
                    ...state,
                    updateResponse: action.payload
                };

            case TransactionAction.UPDATE_PAYMENT_METHOD:
                return {
                    ...state,
                };

            case TransactionAction.UPDATE_PAYMENT_METHOD + '_FINISHED':

                return {
                    ...state,
                    updatePaymentResponse: action.payload
                };

            case TransactionAction.PARTIAL_PAYMENT:
                return {
                    ...state,
                };

            case TransactionAction.PARTIAL_PAYMENT + '_FINISHED':

                return {
                    ...state,
                    updateResponse: action.payload
                };

            case TransactionAction.REFUND_PAYMENT:
                return {
                    ...state,
                };

            case TransactionAction.REFUND_PAYMENT + '_FINISHED':

                return {
                    ...state,
                    updateResponse: action.payload
                };

            case TransactionAction.SET_PERIOD + '_FINISHED':

                return {
                    ...state,
                    listPeriod: {
                        start: moment(action.payload[0]).toDate(),
                        end: moment(action.payload[1]).toDate()
                    }
                };

            case TransactionAction.SET_PAGE + '_FINISHED':

                return {
                    ...state,
                    activeListPage: action.payload
                };

            case TransactionAction.SET_SEARCH_VALUE + '_FINISHED':

                return {
                    ...state,
                    listSearchValue: action.payload
                };


            default:
                return state;
        }
    }
}
import {batch} from 'react-redux'
import RentalDeliveryAction from "../../stores/rental/delivery/RentalDeliveryAction";
import RentalPeriodAction from "../../stores/rental/period/RentalPeriodAction";
import RentalCategoryAction from "../../stores/rental/category/RentalCategoryAction";
import RentalCreateAction from "../../stores/rental/create/RentalCreateAction";
import RentalExtendAction from "../../stores/rental/extend/RentalExtendAction";
import RentalTotalsAction from "../../stores/rental/totals/RentalTotalsAction";
import RentalCustomerDataAction from "../../stores/rental/customerData/RentalCustomerDataAction";
import TransactionAction from "../../stores/transaction/TransactionAction";
import RentalIssueItemsAction from "../../stores/rental/issueItems/RentalIssueItemsAction";
import RentalReturnItemsAction from "../../stores/rental/returnItems/RentalReturnItemsAction";
import RentalDetailAction from "../../stores/rental/detail/RentalDetailAction";
import DashboardAction from "../../stores/dashboard/DashboardAction";
import EnvironmentAction from "../../stores/environment/EnvironmentAction";
import RentalCancellationAction from "../../stores/rental/cancellation/RentalCancellationAction";
import ToTransactionCheck from "../helpers/ToTransactionCheck";

const RentalDispatcher = (dispatch) => ({

    dispatch: dispatch,

    load: (uuid) => {

        batch(() => {

            dispatch(RentalDetailAction.setLoader(true))

            dispatch(RentalDeliveryAction.getDeliveryLocations())

            dispatch(TransactionAction.requestList(uuid))

            dispatch(RentalDetailAction.requestMutations(uuid))

            /** Rental Details **/

            dispatch(RentalDetailAction.detail(uuid)).then(() => {


                /** Rental Issue **/

                dispatch(RentalIssueItemsAction.loadInitialCategorySelection())

                dispatch(RentalIssueItemsAction.loadFromRental(uuid)).then(() => {
                    dispatch(RentalIssueItemsAction.loadStatusFromItems())
                })

                /** Customer Data **/

                dispatch(RentalCustomerDataAction.setInitialData())

                /** Rental Delivery **/

                dispatch(RentalDeliveryAction.setInitialData())

                /** Rental Period **/

                dispatch(RentalPeriodAction.loadFromInfo()).then(() => {

                    /** Rental Items **/

                    dispatch(RentalCategoryAction.getAvailableBicycles()).then(() => {
                        dispatch(RentalCategoryAction.loadBicycleSelectionFromInfo()).then(() => {
                            dispatch(RentalCategoryAction.getAvailableAccessoires()).then(() => {
                                dispatch(RentalCategoryAction.loadAccessoireSelectionFromInfo()).then(() => {
                                    dispatch(RentalCategoryAction.updateCart()).then(() => {
                                        dispatch(RentalTotalsAction.loadDiscountFromInfo()).then(() => {
                                            dispatch(RentalTotalsAction.loadDepositFromInfo()).then(() => {
                                                dispatch(RentalTotalsAction.requestTotals(false, false, false, true)).then(() => {
                                                    dispatch(RentalDetailAction.setLoader(false))
                                                })
                                            })
                                        })
                                    })
                                })
                            })
                        })
                    })
                })
            })
        })
    },

    loadMin: (uuid) => {

        batch(() => {

            dispatch(RentalDetailAction.setLoader(true))

            dispatch(RentalDeliveryAction.getDeliveryLocations())

            dispatch(RentalDetailAction.requestTransactions(uuid))

            dispatch(RentalDetailAction.requestMutations(uuid))

            /** Rental Details **/

            dispatch(RentalDetailAction.detail(uuid)).then(() => {


                /** Rental Issue **/

                dispatch(RentalIssueItemsAction.loadInitialCategorySelection())

                dispatch(RentalIssueItemsAction.loadFromRental(uuid)).then(() => {
                    dispatch(RentalIssueItemsAction.loadStatusFromItems())
                })

                /** Customer Data **/

                dispatch(RentalCustomerDataAction.setInitialData())

                /** Rental Delivery **/

                dispatch(RentalDeliveryAction.setInitialData())

                /** Rental Period **/

                dispatch(RentalPeriodAction.loadFromInfo()).then(() => {

                    /** Rental Items **/

                    dispatch(RentalCategoryAction.loadBicycleSelectionFromInfo()).then(() => {
                        dispatch(RentalCategoryAction.loadAccessoireSelectionFromInfo()).then(() => {
                            dispatch(RentalCategoryAction.updateCart()).then(() => {
                                dispatch(RentalTotalsAction.loadDiscountFromInfo()).then(() => {
                                    dispatch(RentalTotalsAction.loadDepositFromInfo()).then(() => {
                                        dispatch(RentalTotalsAction.requestTotals(false, true)).then(() => {
                                            dispatch(RentalDetailAction.setLoader(false))
                                        })
                                    })
                                })
                            })
                        })

                    })
                })
            })
        })
    },


    loadPeriod: (uuid) => {
        batch(() => {

            dispatch(RentalDetailAction.setLoader(true))
            dispatch(RentalDetailAction.detail(uuid)).then(() => {
                dispatch(RentalPeriodAction.loadFromInfo()).then(() => {
                    dispatch(RentalCategoryAction.getAvailableBicycles()).then(() => {
                        dispatch(RentalCategoryAction.loadBicycleSelectionFromInfo()).then(() => {
                            dispatch(RentalCategoryAction.getAvailableAccessoires()).then(() => {
                                dispatch(RentalCategoryAction.loadAccessoireSelectionFromInfo()).then(() => {
                                    dispatch(RentalCategoryAction.updateCart()).then(() => {
                                        dispatch(RentalTotalsAction.loadDiscountFromInfo()).then(() => {
                                            dispatch(RentalTotalsAction.loadDepositFromInfo()).then(() => {
                                                dispatch(RentalTotalsAction.requestTotals()).then(() => {
                                                    dispatch(RentalDetailAction.setLoader(false))
                                                })
                                            })
                                        })
                                    })
                                })
                            })
                        })
                    })
                })
            })
        })
    },

    loadCancel: (uuid) => {
        dispatch(RentalDetailAction.setLoader(true))
        dispatch(RentalDetailAction.detail(uuid)).then(() => {
            dispatch(RentalCancellationAction.requestCosts(uuid)).then(() => {
                dispatch(RentalDetailAction.setLoader(false))
            })
        })
    },

    submitCancel: (props) => {
        dispatch(EnvironmentAction.setLoader(true)).then(() => {
            dispatch(RentalCancellationAction.submitMutation()).then(() => {
                dispatch(EnvironmentAction.setLoader(false)).then(() => {
                    ToTransactionCheck(props)
                })
            })
        })
    },

    resetAll: () => {
        batch(() => {
            dispatch(DashboardAction.reset());
            dispatch(RentalDetailAction.reset());
            dispatch(RentalReturnItemsAction.reset());
            dispatch(RentalIssueItemsAction.reset());
            dispatch(RentalCategoryAction.reset());
            dispatch(RentalDeliveryAction.reset());
            dispatch(RentalCreateAction.reset());
            dispatch(RentalCustomerDataAction.reset());
            dispatch(RentalExtendAction.reset());
            dispatch(RentalPeriodAction.reset());
            dispatch(RentalTotalsAction.reset());
        })
    }
})


export default RentalDispatcher;

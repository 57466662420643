import ActionUtility from "../../../common/helpers/ActionUtility";
import RentalReturnItemsEffect from "./RentalReturnItemsEffect";
import ApiEnum from "../../../common/constants/ApiEnum";

export default class RentalReturnItemsAction {

    static SET_DELIVERY_LOCATION = 'RentalCreateAction.SET_DELIVERY_LOCATION'

    static RESET = 'RentalReturnAction.RESET';
    static SCAN_CODE = 'RentalReturnAction.SCAN_CODE';
    static VALIDATE_AND_SET_ITEM = 'RentalReturnAction.VALIDATE_AND_SET_ITEM';

    static LOAD_STATUS_FROM_ITEMS = 'RentalReturnAction.LOAD_STATUS_FROM_ITEMS';

    static SUBMIT = 'RentalReturnAction.SUBMIT';

    static TOGGLE_REMARKS = 'RentalReturnAction.TOGGLE_REMARKS'
    static CHANGE_REMARKS = 'RentalReturnAction.CHANGE_REMARKS'

    static TOGGLE_POPUP = 'RentalReturnAction.TOGGLE_POPUP'

    static LOAD_FROM_RENTAL = 'RentalReturnAction.LOAD_FROM_RENTAL'

    static scanCode(code, scanned) {
        return async (dispatch, getState) => {

            let fetchUrl = ''

            code = code.replace(/\\/g, "").replace("000026", '')

            if (code.includes("t29.nl/i/")) {
                code = code.replace('http://', '');
                code = code.replace('https://', '');
                code = code.replace('www.', '');
                code = code.replace('t29.nl/i/', '');
                fetchUrl = ApiEnum.Api + '/item/detail-by-id?id=' + code
            } else {
                code = code.replace('http://2cr.nl/?1ATX0', '');
                code = code.replace('http://2CR.NL/?1ATX0', '');
                code = code.replace('http://2cr.nl/?1ABB0', '');
                code = code.replace('http://2CR.NL/?1ABB0', '');
                code = code.replace('http://2cr.nl/?1AVB0', '');
                code = code.replace('http://2CR.NL/?1AVB0', '');
                code = code.replace('http://2cr.nl/?1ALB0', '');
                code = code.replace('http://2CR.NL/?1ALB0', '');
                code = code.replace(/.*(?=DV)/, '');
                code = code.split('!')[0]
                fetchUrl = ApiEnum.Api + '/item/detail-by-code?code=' + code
            }

            const values = {
                fetchUrl: fetchUrl,
                code: code,
                accessToken: getState().authentication.accessToken,
                scanned: !!scanned,
                method: 'post',
            }

            await ActionUtility.createThunkEffect(dispatch, RentalReturnItemsAction.SCAN_CODE, RentalReturnItemsEffect.scanCode, values);
        };
    }

    static validateAndSetItem(uuid = false) {
        return async (dispatch, getState) => {

            const values = {
                accessToken: getState().authentication.accessToken,
                item: getState().rentalReturn.scannedItem,
            }

            if (uuid) {
                values.uuid = uuid
            } else {
                values.uuid = getState().rentalDetail.data.data.rental.uuid
            }

            await ActionUtility.createThunkEffect(dispatch, RentalReturnItemsAction.VALIDATE_AND_SET_ITEM, RentalReturnItemsEffect.validateItem, values);
        };
    }

    static loadStatusFromItems() {

        return async (dispatch, getState) => {
            const values = {
                issuedItems: getState().rentalIssue.items,
                returnItems: getState().rentalReturn.items
            }


            await ActionUtility.createThunkEffect(dispatch, RentalReturnItemsAction.LOAD_STATUS_FROM_ITEMS, RentalReturnItemsEffect.loadStatusFromItems, values);
        };

    }

    static submit(overwrite = false, changePhase = true) {
        return async (dispatch, getState) => {

            const values = {
                unlinkRental: {
                    fetchUrl: ApiEnum.Api + '/item/unlink-rental-all?&rental=' + getState().rentalDetail.data.data.rental.uuid,
                    method: 'post',
                    accessToken: getState().authentication.accessToken,
                    params: {
                        items: getState().rentalReturn.items.filter(item => item.valid.status.code !== 'locked').map((item) => (item.id)),
                        overwrite: overwrite,
                        change_phase: changePhase,
                    }
                },
                linkLocation: {
                    fetchUrl: ApiEnum.Api + '/item/link-location-all',
                    method: 'post',
                    accessToken: getState().authentication.accessToken,
                    params: {
                        items: getState().rentalReturn.items.filter(item => item.valid.status.code !== 'locked').map((item) => (item.id))
                    },
                }

            }

            await ActionUtility.createThunkEffect(dispatch, RentalReturnItemsAction.SUBMIT, RentalReturnItemsEffect.submit, values);
        };
    }

    static toggleRemarks(value) {
        return async (dispatch, getState) => {

            const values = {
                show: value,
                defaultRemarks: ''
            }

            if (!getState().rentalReturn.remarks) {
                values.defaultRermarks = getState().rentalDetail.data.data.rental.remarks
            } else {
                values.defaultRermarks = getState().rentalReturn.remarks
            }

            await ActionUtility.createThunkEffect(dispatch, RentalReturnItemsAction.TOGGLE_REMARKS, RentalReturnItemsEffect.toggleRemarks, values);
        };
    }

    static togglePopup() {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, RentalReturnItemsAction.TOGGLE_POPUP, RentalReturnItemsEffect.togglePopup);
        };
    }

    static reset() {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, RentalReturnItemsAction.RESET, RentalReturnItemsEffect.reset);
        };
    }

}

import {formatDate} from "../helpers/format/formatDate";
import {countRentalItems} from "../helpers/utils/countRentalItems";
import {formatCustomerString} from "../helpers/format/formatCustomerString";
import ReactPaginate from "react-paginate";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import translate from "../helpers/utils/translate";

export const RentalTable = (props) => {

    const {rentals, pagination, setOrderBy, sort, orderBy} = props
    const {slug} = useSelector((state) => state.environment)
    const history = useHistory()

    return (

        <>

            <table className={'c-table--custom c-table'}>

                <thead>

                <tr>
                    <th onClick={() => setOrderBy('id')} className={'is-sort'}>{translate('bookingsNumber')} {orderBy === 'id' &&
                    <span className={'sort-icon sort-' + sort}>↑</span>}</th>
                    <th onClick={() => setOrderBy('date_rent_start')}
                        className={'is-sort'}>{translate('from')} {orderBy === 'date_rent_start' &&
                    <span className={'sort-icon sort-' + sort}>↑</span>}</th>
                    <th onClick={() => setOrderBy('date_rent_end')}
                        className={'is-sort'}>{translate('until')} {orderBy === 'date_rent_end' &&
                    <span className={'sort-icon sort-' + sort}>↑</span>}</th>
                    <th onClick={() => setOrderBy('last_name')}
                        className={'is-sort'}>{translate('renter')} {orderBy === 'last_name' &&
                    <span className={'sort-icon sort-' + sort}>↑</span>}</th>
                    <th>{translate('amount')}</th>
                    <th>{translate('status')}</th>
                    <th>{translate('partnerLocation')}</th>
                </tr>

                </thead>

                <tbody>
                {rentals.map((rentalContainer) => {

                    return (
                        <tr onClick={() =>
                            history.push('/' + slug + '/dashboard/rental/detail/' + rentalContainer.rental.uuid)
                        } key={rentalContainer.rental.id}>
                            <td>#{rentalContainer.rental.id}</td>
                            <td>{formatDate(rentalContainer.rental.dateRentStart)}</td>
                            <td>{formatDate(rentalContainer.rental.dateRentEnd)}</td>
                            <td>{formatCustomerString(rentalContainer)}</td>
                            <td>{countRentalItems(rentalContainer.rentalItems)}</td>
                            <td>{rentalContainer.bookingPhase.name}</td>
                            <td>{rentalContainer.partnerLocation?.name}</td>
                        </tr>
                    )
                })}
                </tbody>

            </table>

            {pagination?.pages &&
            <ReactPaginate
                previousLabel={'< vorige'}
                nextLabel={'volgende >'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={parseInt(pagination.pages.total + '')}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={i => pagination.onChangePage(i.selected)}
                containerClassName={'pagination'}
                forcePage={parseInt(pagination.pages.current + '')}
                activeClassName={'active'}
            />}

        </>
    )

}

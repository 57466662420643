import {apiEnum} from "../../../enums/apiEnum";
import {useAppAxios} from "../../../../app/hooks";

export const apiPartnerTimeUnitAmount = async (args) => {
  return await useAppAxios.get(apiEnum.PARTNER + '/time-unit/amount', {
    headers: {
      'Authorization': `Bearer ` + localStorage.getItem('accessToken'),
      'X-Partner-User-Auth': localStorage.getItem('xPartnerUserAuth'),
      'X-Partner-Location-Auth': localStorage.getItem('xPartnerLocationAuth'),
      'content-type': 'application/json',
      'Accept': 'application/json'
    },
    params: {
      ...args
    }
  }).then(function (response) {
    return response
  }).catch(function (error) {
    return error
  })
}
// Dependencies
import React from 'react'
import {Formik, Field} from "formik";
import {connect} from "react-redux";

// Actions
import AuthenticationAction from "../../stores/authentication/AuthenticationAction";
import EnvironmentAction from "../../stores/environment/EnvironmentAction";

// Components
import Notifications from "../../common/components/Notifications";
import IconLock from "../../common/components/Authentication/IconLock";

// Constants
import Speeds from "../../common/constants/Speeds";
import translate from "../../createSlice/common/helpers/utils/translate";

const mapStateToProps = (state) => ({
    ...state.authentication,
    environment: state.environment
});

class Pin extends React.Component {

    render() {
        const notification = this.props.partnerUser.notifications;
        const {dark} = this.props.environment.colors;

        return (

            <Formik
                initialValues={{pin: ''}}
                onSubmit={(values) => {
                    this._submit(values)
                }}
            >
                {({values, errors, touched, handleChange, handleBlur, handleSubmit,}) => (

                    <form className="c-auth__form c-form" onSubmit={handleSubmit}>
                        <small>{translate('insertPincode')}</small>
                        <div className="c-form__row">
                            <div className="c-form__group">
                                <label htmlFor="password">{translate('pincode')}</label>
                                <div className="c-form__pin">
                                    <IconLock/>
                                    <Field data-hj-allow name={'pin'} type="password" id="pin"/>
                                </div>
                            </div>
                        </div>
                        <div className="c-form__row">
                            <div className="c-form__group">
                                <button className="btn btn--primary" style={{backgroundColor: dark}}>
                                    <span>{translate('login')}</span>
                                </button>
                            </div>
                        </div>

                        {!!notification && <Notifications {...notification}/>}

                    </form>

                )}
            </Formik>
        )
    }

    _submit = (values) => {
        this.props.dispatch(EnvironmentAction.setLoader(true)).then(() => {
            this.props.dispatch(AuthenticationAction.getUserByPin(values.pin)).then(() => {
                this.props.dispatch(EnvironmentAction.setLoader(false))
            }, Speeds.LoaderSpeed)
        })
    }
}

export default connect(mapStateToProps)(Pin);

// Dependencies
import React from 'react';
import {connect} from "react-redux";

// Actions
import RentalCancellationAction from "../../stores/rental/cancellation/RentalCancellationAction";

// Components
import ToDetailButton from "../../common/components/General/ToDetailButton";
import TinyLoader from "../../common/components/General/TinyLoader";

// Helpers
import DisplayPrice from "../../common/helpers/DisplayEuro";
import IsCurrentPartnerLocation from "../../common/helpers/IsCurrentPartnerLocation";

// Dispatchers
import RentalDispatcher from "../../common/dispatchers/RentalDispatcher";
import Scanner from "../../common/components/General/Scanner";
import translate from "../../createSlice/common/helpers/utils/translate";
import {PinValidationWrapper} from "../../common/wrappers/PinValidationWrapper";

const mapStateToProps = (state) => ({
    ...state.rentalCancellation,
    rentalDetail: state.rentalDetail.data,
    environment: state.environment
});


class RentalCancellation extends React.Component {

    componentDidMount() {
        this._init()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.match.params.rentalUuid !== this.props.match.params.rentalUuid) {
            this._init()
        }
    }

    _init = () => {
        const {rentalUuid} = this.props.match.params
        this.props.resetAll()
        this.props.loadCancel(rentalUuid)
    }

    render() {

        const {info, rentalDetail, history, environment, cancellationReason, dispatch, submitCancel} = this.props

        if (info && rentalDetail) {
            const insuranceCoversFee = info.insurance_covers_fee ?? false
            const isCurrentPartnerLocation = IsCurrentPartnerLocation(this.props)

            let statusClass = 'c-info-blocks__text__highlight '

            if (info.refund.percentage === 100) {
                statusClass += 'c-info-blocks__text__highlight--positive'
            } else {
                statusClass += 'c-info-blocks__text__highlight--orange'
            }

            if (isCurrentPartnerLocation) {

                return (
                    <main>
                        <PinValidationWrapper>

                            <Scanner type={'rental'}/>

                            <div className="o-container o-container--primary">

                                <header className="c-subject-header c-subject-header--split">

                                    <h1>{translate('bookingReservationCancel')}</h1>

                                    <div className="c-subject-header__buttons">

                                        <ToDetailButton
                                            history={history}
                                            slug={environment.slug}
                                            uuid={rentalDetail.data.rental.uuid}
                                        />

                                    </div>

                                </header>

                                <div className="s-order-detail">
                                    <div className="c-info-blocks c-form">
                                        <div className="c-info-blocks__row">
                                            <div className="c-info-blocks__text">
                                                <h2>
                                                    <u>#{rentalDetail.data.rental.id}</u>
                                                </h2>

                                                <p>{translate('cancelIntroPrefix')}
                                                    <u>#{rentalDetail.data.rental.id}</u> {translate('cancelIntroSuffix')}
                                                </p>

                                                <p>{translate('cancellationReasonIfYes')}</p>

                                                <div className={statusClass}>
                                                    <h4>{translate('cancellationReasonDatePrefix')} {info.daysLeft} {translate('cancellationReasonDateSuffix')}</h4>

                                                    {/*Cancellation without fine (on time)*/}
                                                    {!info.costs && !insuranceCoversFee &&
                                                        <p>{translate('cancellationCompensationPrefix')} {info.refund.percentage}% {translate('cancellationCompensationSuffix')}
                                                        <u><strong>{DisplayPrice(info.refund.price)}</strong></u>
                                                        </p>
                                                    }

                                                    {/*Cancellation with fine (too late)*/}
                                                    {info.costs > 0 && !insuranceCoversFee &&
                                                        <p>{translate('cancellationCompensationPrefix_lateWithFine')} {info.refund.percentage}% {translate('cancellationCompensationSuffix')}
                                                        <u><strong>{DisplayPrice(info.refund.price)}</strong></u>
                                                        </p>
                                                    }

                                                    {/*Cancellation without fine due too insurance (too late)*/}
                                                    {insuranceCoversFee &&
                                                        <p>{translate('cancellationCompensationPrefix_lateWithFineWithInsurance')} {info.refund.percentage}% {translate('cancellationCompensationSuffix')}
                                                        <u><strong>{DisplayPrice(info.refund.price)}</strong></u>
                                                        </p>
                                                    }
                                                </div>

                                            </div>

                                        </div>

                                        <div className="c-info-blocks__row">
                                            <div className="c-info-blocks__single">
                                                <header className="c-subject-header c-subject-header--closed">
                                                    <h2>{translate('cancellationReasonTitle')}</h2></header>
                                                <div className="c-info-blocks__single__body">
                                                    <div className="c-form__row">

                                                        <div className="c-form__group">
                                                            <label
                                                                htmlFor="">{translate('cancellationReasonSubtitle')}</label>
                                                            <textarea
                                                                value={cancellationReason}
                                                                onChange={(e) => dispatch(RentalCancellationAction.changeReason(e.target.value))}
                                                                name="cancellation-reason" cols="30" rows="10"
                                                            />
                                                        </div>

                                                    </div>

                                                    <div className="c-form__row">
                                                        <div className="c-form__group">
                                                            {cancellationReason !== '' &&
                                                            <button onClick={() => submitCancel(this.props)}
                                                                    type="submit"
                                                                    className="btn btn--rounded btn--primary">
                                                                <span>{translate('bookingReservationCancel')}</span>
                                                            </button>
                                                            }
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </PinValidationWrapper>
                    </main>
                )

            } else return null

        } else return <TinyLoader/>

    }

}

export default connect(mapStateToProps, RentalDispatcher)(RentalCancellation);

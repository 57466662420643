import {useAppAxios} from "../../../../app/hooks";
import {apiEnum} from "../../../enums/apiEnum";

export const apiSendTwoFactorCode = async () => {
    return await useAppAxios.get(apiEnum.PARTNER + '/user/send-two-factor-code')
        .then(function (response) {
            if (response.status === 204) {
                return {
                    data: {
                        status: 204
                    }
                };
            }
            return response;
        })
        .catch(function (error) {
            if (error.response) {
                return {
                    data: {
                        status: {
                            code: error.response.status
                        }
                    }
                };
            } else {
                return {
                    data: {
                        status: {
                            code: 500
                        }
                    }
                };
            }
        });
};
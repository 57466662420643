import {apiEnum} from "../../../enums/apiEnum";
import {useAppAxios} from "../../../../app/hooks";
import {decamelizeKeys} from "humps";

export const apiPartnerRentalCreate = async (args) => {
    return await useAppAxios.post(apiEnum.PARTNER + '/rental/create', {
        date_rent_start: args.dateRentStart,
        date_rent_end: args.dateRentEnd,
        delivery: args.delivery,
        categories: decamelizeKeys(args.categories),
        private: decamelizeKeys(args.private),
    },).then(function (response) {

        // Customer addresses from object to array
        if (response.data.items.customerAddresses) {
            response.data.items.customerAddresses = Object.values(response.data.items.customerAddresses)
        }

        return response
    }).catch(function (error) {
        console.log(error)
        return {
            data: {
                status: {
                    code: 500
                }
            }
        }
    })
}

import {apiEnum} from "../../../enums/apiEnum";
import {useAppAxios} from "../../../../app/hooks";

export const apiSwitchPartnerLocation = async (locationId) => {
    return await useAppAxios.post(`${apiEnum.PARTNER}/location/switch`, {}, {
        params: {
            locationId: locationId
        }
    }).then(function (response) {
        if (response.data && response.data.token) {
            localStorage.setItem('xPartnerLocationAuth', response.data.token);
        }
        return response;
    }).catch(function (error) {
        console.log(error);
        return {
            data: {
                status: {
                    code: 500
                }
            }
        };
    });
}
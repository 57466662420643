import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import ApiEnum from "../../common/constants/ApiEnum";
import fetchHelper from "../../common/helpers/fetchHelper";
import {apiPartnerItemUpdateStatus} from "../../createSlice/common/api/partner/item/apiPartnerItemUpdateStatus";
import {apiPartnerItemSetInService} from "../../createSlice/common/api/partner/item/apiPartnerItemSetInService";


export const toggleService = createAsyncThunk(
    'serviceModal/toggleService',
    async (_, {getState, dispatch}) => {

        const {serviceModal} = getState();

        let itemResponse = null

        if (serviceModal.item) {
            if (serviceModal.item.rental_status.code !== 'service') {
                if (serviceModal.selectedServices) {
                    if (serviceModal.selectedServices.length > 0) {
                        itemResponse = await apiPartnerItemSetInService(serviceModal.item, serviceModal.selectedServices.map(service => service.value), serviceModal.remarks)
                    } else {
                        itemResponse = await apiPartnerItemSetInService(serviceModal.item)
                    }
                }
            } else {
                itemResponse = await apiPartnerItemUpdateStatus(serviceModal.item, 'available')
            }
        }

       return itemResponse?.data?.items?.item ?? false

    }
)

export const getServiceList = createAsyncThunk(
    'serviceModal/getDefectList',
    async (id, {getState, dispatch}) => {
        const itemValues = {
            fetchUrl: ApiEnum.Api + '/item/service-defect-list?id=' + id,
            method: 'post',
            accessToken: getState().authentication.accessToken,
            params: {
                auth_token: localStorage.getItem('authToken'),
            }
        }

        const itemResponse = await fetchHelper(itemValues, dispatch)

        return itemResponse.items
    }
)

const initialState = {
    active: false,
    submitting: false,
    submitted: false,
    servicesLoading: false,
    showRemark: false,
    remarks: '',
    selectedServices: [],
    serviceOptions: []
}

export const serviceModalSlice = createSlice({
    name: 'serviceModal',
    initialState,
    reducers: {
        openServiceModal: (state, action) => {
            state.item = action.payload
            state.active = true
        },
        closeServiceModal: (state) => {
            state.active = false
        },
        setServiceModalRemarks: (state, action) => {
            state.remarks = action.payload
        },
        setSelectedServices: (state, action) => {
            state.selectedServices = action.payload
            state.showRemark = false
            state.selectedServices.map((service) => {
                if (service.remark === 1) {
                    state.showRemark = true
                }
            })
            if (!state.showRemark) {
                state.remarks = ''
            }
        },
        resetServiceModal: (state) => {
            Object.assign(state, initialState)
        }
    },
    extraReducers: (builder) => {
        builder.addCase(toggleService.pending, (state) => {
            state.submitting = true
            state.submitted = false
        })
        builder.addCase(toggleService.fulfilled, (state, action) => {
            state.submitting = false
            state.submitted = true
            state.item = action.payload
            state.active = false
            state.showRemark = false
            state.remarks = ''
            state.selectedServices = []
            state.serviceOptions = []
        })
        builder.addCase(getServiceList.pending, state => {
            state.servicesLoading = true
            state.showRemark = false
            state.remarks = ''
            state.selectedServices = []
            state.serviceOptions = []
        })
        builder.addCase(getServiceList.fulfilled, (state, action) => {
            action.payload?.data?.map((item) => {
                state.serviceOptions.push({value: item.id, label: item.name, remark: item.remark_active})
            })
            state.servicesLoading = false
        })
    },
})

export const {
    closeServiceModal,
    openServiceModal,
    setSelectedServices,
    setServiceModalRemarks,
    resetServiceModal
} = serviceModalSlice.actions

export const serviceModalReducer = serviceModalSlice.reducer

import ActionUtility from "../../../common/helpers/ActionUtility";
import RentalDeliveryEffect from "./RentalDeliveryEffect";
import ApiEnum from "../../../common/constants/ApiEnum";
import moment from "moment";

export default class RentalDeliveryAction {

    static SUBMIT_MUTATION = 'RentalDeliveryAction.SUBMIT_MUTATION';

    static SET_INITIAL_DATA = 'RentalDeliveryAction.SET_INITIAL_DATA';


    static SET_DELIVERY_STATUS = 'RentalDeliveryAction.SET_DELIVERY_STATUS';
    static GET_DELIVERY_LOCATIONS = 'RentalDeliveryAction.GET_DELIVERY_LOCATIONS';

    static SET_DELIVERY_LOCATION = 'RentalDeliveryAction.SET_DELIVERY_LOCATION'
    static SET_SUB_DELIVERY_LOCATION = 'RentalDeliveryAction.SET_SUB_DELIVERY_LOCATION'
    static SET_CUSTOM_LOCATION_VALUE = 'RentalDeliveryAction.SET_CUSTOM_LOCATION_VALUE'
    static SET_SUB_LOCATION_NUMBER = 'RentalDeliveryAction.SET_SUB_LOCATION_NUMBER'
    static FORMAT_SUB_DELIVERY_LOCATIONS = 'RentalDeliveryAction.FORMAT_SUB_DELIVERY_LOCATIONS'

    static RESET = 'RentalDeliveryAction.RESET'

    static VALIDATE_DELIVERY_BY_ADDRESS = 'RentalDeliveryAction.VALIDATE_DELIVERY_BY_ADDRESS';

    static setInitialData() {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, RentalDeliveryAction.SET_INITIAL_DATA, RentalDeliveryEffect.setInitialData);
        }
    }

    static setDeliveryStatus(value) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, RentalDeliveryAction.SET_DELIVERY_STATUS, RentalDeliveryEffect.setDeliveryStatus, value);
        };
    }

    static getDeliveryLocations() {
        return async (dispatch, getState) => {

            const values = {
                fetchUrl: ApiEnum.Api + '/delivery-address/list',
                method: 'post',
                accessToken: getState().authentication.accessToken,
                params: {
                    auth_token: localStorage.getItem('authToken'),
                    partner_environment_id: getState().environment.data.info.id
                }
            }

            await ActionUtility.createThunkEffect(dispatch, RentalDeliveryAction.GET_DELIVERY_LOCATIONS, RentalDeliveryEffect.getDeliveryAddressList, values);
        };
    }

    static setDeliveryLocation(values) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, RentalDeliveryAction.SET_DELIVERY_LOCATION, RentalDeliveryEffect.setDeliveryLocation, values);
        };
    }

    static setSubDeliveryLocation(values) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, RentalDeliveryAction.SET_SUB_DELIVERY_LOCATION, RentalDeliveryEffect.setSubDeliveryLocation, values);
        };
    }

    static setCustomLocationValue(value) {

        return async (dispatch, getState) => {
            const values = {
                current: getState().rentalDelivery.delivery.custom,
                value: value
            }
            await ActionUtility.createThunkEffect(dispatch, RentalDeliveryAction.SET_CUSTOM_LOCATION_VALUE, RentalDeliveryEffect.setCustomLocationValue, values);
        };
    }

    static setSubLocationNumber(number) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, RentalDeliveryAction.SET_SUB_LOCATION_NUMBER, RentalDeliveryEffect.setSubLocationNumber, number);
        };
    }


    static formatSubDeliveryLocations(values) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, RentalDeliveryAction.FORMAT_SUB_DELIVERY_LOCATIONS, RentalDeliveryEffect.formatSubDeliveryLocations, values);
        };
    }


    static submitMutation() {

        return async (dispatch, getState) => {

            const data = getState().rentalDelivery

            const values = {
                fetchUrl: ApiEnum.Api + '/mutation/create?rental=' + getState().rentalDetail.data.data.rental.uuid,
                method: 'post',
                accessToken: getState().authentication.accessToken,
                params: {
                    auth_token: localStorage.getItem('authToken'),
                    type: "delivery",
                    pick_up: data.delivery.enabled,
                    delivery: data.delivery.enabled,
                    deliveryAddress: data.delivery.deliveryPickupLocation.address,
                }
            }

            const deliveryData = data.delivery

            // Set delivery sub locations

            if (deliveryData.deliveryPickupLocation.value === 'custom') {

                values.params.deliveryAddress = {
                    street: deliveryData.custom.street,
                    number: deliveryData.custom.number,
                    zip_code: deliveryData.custom.zip_code,
                }

                if (deliveryData.custom.city['__isNew__']) {
                    values.params.deliveryAddress.city = deliveryData.custom.city.value
                } else {
                    values.params.deliveryAddress.city_id = deliveryData.custom.city.value
                }

            } else if (deliveryData.deliveryPickupSubLocation) {
                values.params.delivery_address_item_id = deliveryData.deliveryPickupSubLocation.id
                values.params.delivery_address_item_number = deliveryData.subLocationNumber.value
            } else {
                values.params.delivery_address_item_id = deliveryData.deliveryPickupLocation.value
            }

            if (deliveryData.deliveryPickupLocation.value === 'custom') {

                values.params.pickupAddress = {
                    street: deliveryData.custom.street,
                    number: deliveryData.custom.number,
                    zip_code: deliveryData.custom.zip_code,
                }

                if (deliveryData.custom.city['__isNew__']) {
                    values.params.pickupAddress.city = deliveryData.custom.city.value
                } else {
                    values.params.pickupAddress.city_id = deliveryData.custom.city.value
                }

            } else if (deliveryData.deliveryPickupSubLocation) {
                values.params.pickup_address_item_id = deliveryData.deliveryPickupSubLocation.id
                values.params.pickup_address_item_number = deliveryData.subLocationNumber.value
            } else {
                values.params.pickup_address_item_id = deliveryData.deliveryPickupLocation.value
            }


            await ActionUtility.createThunkEffect(dispatch, RentalDeliveryAction.SUBMIT_MUTATION, RentalDeliveryEffect.submitMutation, values);
        }

    }


    static validateDeliveryByAddress() {
        return async (dispatch, getState) => {

            const categories = [...getState().rentalItems.shoppingCart.bicycles, ...getState().rentalItems.shoppingCart.accessoires]

            const mappedCategories = categories.map((category) => {

                return {
                    category_id: category.id,
                    quantity: category.quantity
                }
            })

            const values = {
                fetchUrl: ApiEnum.Api + '/availability/validate-delivery-by-address?deliveryAddressId=' + getState().rentalDelivery.delivery.deliveryPickupLocation?.value + '&startDate=' + moment(getState().rentalPeriod.period.startDate).format('YYYY-MM-DD') + '&endDate=' + moment(getState().rentalPeriod.period.endDate).format('YYYY-MM-DD'),
                method: 'post',
                accessToken: getState().authentication.accessToken,
                params: {
                    auth_token: localStorage.getItem('authToken'),
                    categories: mappedCategories
                }
            }

            await ActionUtility.createThunkEffect(dispatch, RentalDeliveryAction.VALIDATE_DELIVERY_BY_ADDRESS, RentalDeliveryEffect.validateDeliveryByAddress, values);
        };
    }


    static reset(number) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, RentalDeliveryAction.RESET, RentalDeliveryEffect.reset, number);
        };
    }

}

import fetchHelper from "../../common/helpers/fetchHelper";

export default class EnvironmentEffect {

    static async detail(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        switch (response?.status?.code) {
            case(200):
                return {
                    success: true,
                    data: response.items.partner_environment,
                    slug: values.slug
                }
            default:
                return {
                    success: false,
                    notifications: false
                }
        }
    }

    static async enableRentalScanner(bool) {
        return bool
    }

    static async setLoader(bool) {
        return bool
    }

}

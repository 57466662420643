// Dependencies
import React from 'react'
import {connect} from "react-redux";

// Actions
import EnvironmentAction from "../../stores/environment/EnvironmentAction";

// Components
import AvailableCategories from "./components/AvailableCategories";

import ItemAction from "../../stores/item/ItemAction";
import Scanner from "../../common/components/General/Scanner";
import translate from "../../createSlice/common/helpers/utils/translate";

const mapStateToProps = (state) => ({
    item: state.item,
    environment: state.environment,
    depotModal: state.depotModal,
    serviceModal: state.serviceModal
});

class Stock extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            popup: {
                show: false,
                item: null,
                loading: false
            },
            activeCategoryId: null,
            categoryLoading: false
        }
    }

    componentDidMount() {
        this.load()
    }

    componentWillUnmount() {
        if (this.props.item.exportFile) {
            this.props.dispatch(ItemAction.resetExportFileData())
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.depotModal !== this.props.depotModal || (prevProps.serviceModal.item !== this.props.serviceModal.item)) {
            this.props.dispatch(ItemAction.requestAvailableCategorylist()).then(() => {
                this._requestCategoryItems(this.state.activeCategoryId)
            })

        }


    }


    load() {
        this.props.dispatch(EnvironmentAction.setLoader(true)).then(() => {
            this.props.dispatch(ItemAction.requestAvailableCategorylist()).then(() => {

                this.props.dispatch(EnvironmentAction.setLoader(false))
            })
        })
    }

    render() {

        const {primary_color_dark} = this.props.environment.data.info
        return (
            <main style={{padding: '2rem 0'}}>

                <Scanner type={'rental'}/>

                <div className="o-container o-container--primary">

                    {/*{!(this.props.item.exportFile && !this.props.item.exportLoading) &&*/}
                    {/*    <a className={'btn btn--small'} onClick={() => {*/}
                    {/*        this._generateExportList('stock', 'pdf')*/}
                    {/*    }} style={{*/}
                    {/*        marginBottom: '1rem',*/}
                    {/*        marginRight: '1rem',*/}
                    {/*        background: primary_color_dark,*/}
                    {/*        color: '#fff',*/}
                    {/*        cursor: 'pointer',*/}
                    {/*        display: 'inline-block'*/}
                    {/*    }}>PDF {translate('generating')}*/}
                    {/*    </a>*/}
                    {/*}*/}

                    {!(this.props.item.exportFile && !this.props.item.exportLoading) &&
                        <a className={'btn btn--small'} onClick={() => {
                            this._generateExportList('stock', 'xls')
                        }} style={{
                            marginBottom: '1rem',
                            background: primary_color_dark,
                            color: '#fff',
                            cursor: 'pointer',
                            display: 'inline-block'
                        }}>{translate('download')} XLS
                        </a>
                    }

                    {this.props.item.exportLoading && <p>{translate('exportIsBeingGenerated')}...</p>}

                    {(this.props.item.exportFile && this.props.item.exportFile.items.download_url) &&
                        <a className={'btn btn--small'}
                           href={this.props.item.exportFile.items.download_url}
                           onClick={() => setTimeout(() => this.props.dispatch(ItemAction.resetExportFileData()), 1500)}
                           target={'_blank'}
                           style={{
                               marginBottom: '1rem',
                               background: primary_color_dark,
                               color: '#fff',
                               cursor: 'pointer',
                               display: 'inline-block'
                           }}>{translate('downloadExportFile')}</a>
                    }

                    <AvailableCategories
                        {...this.state}
                        history={this.props.history}
                        setActiveCategoryId={this._setActiveCategoryId}
                    />
                </div>

            </main>
        )
    }

    _setActiveCategoryId = (id) => {

        if (this.state.activeCategoryId === id) {
            id = null
        }

        this.setState({
            activeCategoryId: id,
        })

        this._requestCategoryItems(id)

    }

    _requestCategoryItems = (id) => {

        this.setState({
            categoryLoading: true
        })

        if (id) {
            this.props.dispatch(ItemAction.requestAvailableItemList({category: id})).then(() => {
                this.props.dispatch(ItemAction.formatStockItemsByCategory()).then(() => {
                        this.setState({
                            categoryLoading: false
                        })
                    }
                )
            })

        } else {
            this.setState({
                categoryLoading: false
            })
        }
    }

    _generateExportList = (type, fileType) => {
        this.props.dispatch(ItemAction.requestExportList(type, fileType))
    }
}

export default connect(mapStateToProps)(Stock);

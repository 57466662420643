import React from 'react'
import {connect} from 'react-redux'
import EnvironmentAction from "../../stores/environment/EnvironmentAction";
import ItemAction from "../../stores/item/ItemAction";
import ReactPaginate from 'react-paginate';
import TransactionAction from "../../stores/transaction/TransactionAction";
import RouteEnum from "../../common/constants/RouteEnum";
import Scanner from "../../common/components/General/Scanner";
import translate from "../../createSlice/common/helpers/utils/translate";
import moment from "moment";


const mapStateToProps = (state) => ({
    item: state.item,
    environment: state.environment
})


class ItemsInService extends React.Component {

    componentDidMount() {
        this.load()
    }

    load() {
        this.props.dispatch(EnvironmentAction.setLoader(true)).then(() => {
            this.props.dispatch(ItemAction.requestInServiceItems()).then(() => {
                this.props.dispatch(EnvironmentAction.setLoader(false))
            })
        })
    }
    componentWillUnmount() {
        if (this.props.item.exportFile) {
            this.props.dispatch(ItemAction.resetExportFileData())
        }
    }

    render() {

        const {item} = this.props
        const {inServiceItems} = item
        const {pages} = inServiceItems

        const {primary_color_dark} = this.props.environment.data.info

        if (inServiceItems) {

            return (
                <main style={{padding: '4rem 0'}}>

                    <Scanner type={'rental'}/>

                    <div className="c-info-blocks__row o-container o-container--primary">

                        {/*{!(this.props.item.exportFile && !this.props.item.exportLoading) &&*/}
                        {/*<a className={'btn btn--small'}  onClick={() => {*/}
                        {/*    this._generateExportList('service','pdf')*/}
                        {/*}} style={{marginBottom: '1rem', marginRight: '1rem' ,background: primary_color_dark, color: '#fff', cursor: 'pointer', display: 'inline-block'}}>PDF {translate('generating')}*/}
                        {/*</a>*/}
                        {/*}*/}

                        {!(this.props.item.exportFile && !this.props.item.exportLoading) &&
                            <a className={'btn btn--small'} onClick={() => {
                                this._generateExportList('service', 'xls')
                            }} style={{
                                marginBottom: '1rem',
                                marginLeft: '0rem',
                                background: primary_color_dark,
                                color: '#fff',
                                cursor: 'pointer',
                                display: 'inline-block'
                            }}>{translate('download')} XLS
                            </a>
                        }

                        {this.props.item.exportLoading && <p>{translate('exportIsBeingGenerated')}...</p>}

                        {(this.props.item.exportFile && this.props.item.exportFile.items.download_url) &&
                            <a className={'btn btn--small'}
                               href={this.props.item.exportFile.items.download_url}
                               onClick={() => setTimeout(() => this.props.dispatch(ItemAction.resetExportFileData()), 1500)}
                               target={'_blank'}
                               style={{
                                   marginBottom: '1rem',
                                   background: primary_color_dark,
                                   color: '#fff',
                                   cursor: 'pointer',
                                   display: 'inline-block'
                               }}>{translate('downloadExportFile')}</a>
                        }

                        <div className="c-info-blocks__single">
                            <header className="c-subject-header c-subject-header--closed">
                                <h2>{translate('itemsInService')}</h2>
                            </header>
                            <div className="c-info-blocks__single__body c-info-blocks__single__body--scroll">
                                <table className="c-table-standard">

                                    <tbody>

                                    <tr>
                                        <th>{translate('productCodeNew')}</th>
                                        <th>{translate('category')}</th>
                                        <th>{translate('inServiceSince')}</th>
                                        <th>{translate('serviceDefects')}</th>
                                        <th>{translate('remarks')}</th>
                                    </tr>

                                    {inServiceItems.data && inServiceItems.data.map((item) => {

                                        let code = 'none'

                                        if (item.rental_status) {
                                            code = item.rental_status.code
                                        }

                                        return (
                                            <tr className={'status-' + code} key={'in-service-item-' + item.id}>
                                                <td>{item.product_code} </td>
                                                <td>{item.category.name}</td>
                                                <td>{item.maintenance?.date_created
                                                    ? moment(item.maintenance.date_created).format('DD-MM-YYYY')
                                                    : ''}</td>
                                                <td>{item.maintenance?.service_defects && item.maintenance.service_defects.length > 0
                                                    ? item.maintenance.service_defects.map(
                                                        (defect, index) => index ? ', ' : '' + defect.name)
                                                    : ''}
                                                </td>
                                                <td>{item.maintenance?.remarks
                                                    ? item.maintenance.remarks
                                                    : ''}
                                                </td>
                                            </tr>
                                        )
                                    })}

                                    </tbody>

                                </table>

                            </div>
                        </div>

                        {!!pages &&
                            <ReactPaginate
                                previousLabel={'< vorige'}
                                nextLabel={'volgende >'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={pages.total}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={(data) => this._handlePageClick(data)}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                forcePage={parseInt(pages.current)}
                                activeClassName={'active'}
                            />
                        }

                    </div>
                </main>
            )
        } else return null

    }

    _clickItemRow = (item) => {
        this.props.history.push('/' + this.props.environment.slug + RouteEnum.RentalDetail + '/' + item.rental.uuid)
    }

    _handlePageClick = (i) => {
        this.props.dispatch(ItemAction.setInServiceItemsPage(i.selected)).then(() => {
            this.props.dispatch(ItemAction.requestInServiceItems())
        })
    }

    _generateExportList = (type, fileType) => {
        this.props.dispatch(ItemAction.requestExportList(type, fileType))
    }

}

export default connect(mapStateToProps)(ItemsInService)

import React from "react";
import RouteEnum from "../../constants/RouteEnum";
import {Link} from 'react-router-dom'
import AuthenticationAction from "../../../stores/authentication/AuthenticationAction";
import CheckDisplayModule from "../../helpers/CheckDisplayModule";
import translate from "../../../createSlice/common/helpers/utils/translate";


export const MobileMenu = (props) => {

    const {environment, partnerUser} = props
    const {slug} = environment

    function unsetUser() {
        props.dispatch(AuthenticationAction.unsetUser())
    }

    function logOut() {
        props.dispatch(AuthenticationAction.logout())
    }

    return (
        <div className="c-menu">
            <div className="o-container o-container--primary">
                <ul className="c-menu__nav u-reset-ul">
                    <li><Link onClick={() => props.toggleMenu()} to={'/' + slug + RouteEnum.Dashboard}>Dashboard</Link>
                    </li>
                    {CheckDisplayModule('availability', environment, partnerUser) &&
                    <li><Link onClick={() => props.toggleMenu()}
                              to={'/' + slug + RouteEnum.Availability}>{translate('availability')}</Link></li>}
                    {CheckDisplayModule('scanItems', environment, partnerUser) &&
                    <li><Link onClick={() => props.toggleMenu()}
                              to={'/' + slug + RouteEnum.ScanItems}>{translate('scanItems')}</Link></li>}
                    {CheckDisplayModule('stock', environment, partnerUser) &&
                    <li><Link onClick={() => props.toggleMenu()}
                              to={'/' + slug + RouteEnum.Stock}>{translate('stock')}</Link></li>}
                    {CheckDisplayModule('itemsLate', environment, partnerUser) &&
                    <li><Link onClick={() => props.toggleMenu()}
                              to={'/' + slug + RouteEnum.ItemsLate}>{translate('itemsLate')}</Link></li>}
                    {CheckDisplayModule('itemsInService', environment, partnerUser) &&
                    <li><Link onClick={() => props.toggleMenu()}
                              to={'/' + slug + RouteEnum.ItemsInService}>{translate('itemsInService')}</Link></li>}
                    {CheckDisplayModule('transactions', environment, partnerUser) &&
                    <li><Link onClick={() => props.toggleMenu()}
                              to={'/' + slug + RouteEnum.TransactionOverview}>{translate('transactions')}</Link></li>}

                    <li><Link onClick={() => props.toggleMenu()}
                              to={'/' + slug + RouteEnum.Settings}>{translate('settings')}</Link></li>
                    <li><a href="https://help.tilia.app/" target="_blank">Helpdesk</a></li>
                </ul>
                <div className="c-menu__misc">
                    {CheckDisplayModule('rentalCreate', environment, props.partnerUser) &&

                    <Link onClick={() => props.toggleMenu()} to={RouteEnum.RentalCreate}
                          className="btn btn--small btn--primary">
                        <span>{translate('newRental')}</span>
                    </Link>
                    }
                    <button className="btn btn--small btn--outline" onClick={() => unsetUser()}>
                        <span>{translate('changeUser')} <small>(F12)</small></span>
                    </button>

                    <button className="btn btn--small btn--outline" onClick={() => props.openChatWidget()}>
                        <span>Chat</span>
                    </button>

                    <button className="btn btn--small btn--outline btn--square" onClick={() => logOut()}>
                        <i className="icon icon-power"/>
                    </button>
                </div>
            </div>
        </div>
    )

}

const ApiEnum = {
    Api: process.env.REACT_APP_API_URL + '/partner',
    ApiGeneral: process.env.REACT_APP_API_URL + '/general',
    ApiClient: process.env.REACT_APP_API_URL + '/client',

    ApiWebshopAvailability: process.env.REACT_APP_API_URL + '/webshop/availability',
    ApiWebshopLocation: process.env.REACT_APP_API_URL + '/webshop/location',

    key: '3c3ab799-89c1-48a0-90cf-9fb17548db01',
    secret: '1442671c-1874-4dda-bf80-943e7be19888'
}

export default ApiEnum

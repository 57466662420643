import ActionUtility from '../../common/helpers/ActionUtility'
import CountryEffect from "./CountryEffect";
import ApiEnum from "../../common/constants/ApiEnum";

export default class CountryAction {
    static REQUEST_COUNTRY_LIST = 'CountryAction.REQUEST_COUNTRY_LIST';

    static countryList() {
        return async (dispatch, getState) => {

            const values = {
                fetchUrl: ApiEnum.ApiGeneral + '/country/list',
                accessToken: getState().authentication.accessToken
            }

            await ActionUtility.createThunkEffect(dispatch, CountryAction.REQUEST_COUNTRY_LIST, CountryEffect.countryList, values);
        };
    }

}
import ApiEnum from "../../../common/constants/ApiEnum";
import ErrorAction from "../../error/ErrorAction";
import fetchHelper from "../../../common/helpers/fetchHelper";

export default class RentalIssueItemsEffect {

    static async scanCode(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        if (response.items) {

            return {
                item: response.items.item,
                scanned: values.scanned
            }

        } else return null

    }

    static async validateItem(values, dispatch) {

        const item = values.item
        values.methods = 'post'

        if (item) {

            // Link valid
            let url = ApiEnum.Api + '/item/link-valid?id=' + item.id

            if (values.uuid) {

                // connected to rental check
                url = ApiEnum.Api + '/item/link-rental-valid?id=' + item.id + '&rental=' + values.uuid
            }

            values.fetchUrl = url


            item.valid = await fetchHelper(values, dispatch)

            const categoryId = item.availability_category.id
            const availabilityCategory = values.availableCategories.find(availableCategory => availableCategory.id === categoryId)
            let rentalCategory = false;

            if (values.rentalCategories) {
                rentalCategory = values.rentalCategories.find((rentalCat) => rentalCat.data.category_id === categoryId)
            }

            if (availabilityCategory || rentalCategory) {

                if (!values.availabilityCheck) {

                    const rentalCategory = values.categories.find(category => category.category_id === item.availability_category.id)

                    if (rentalCategory) {

                        const quantity = rentalCategory.quantity
                        const currentLength = values.currentItems.filter(currentItem => currentItem.category.id === rentalCategory.category_id).length + 1

                        if (currentLength > quantity) {

                            item.valid = {
                                status: {
                                    code: 400,
                                },
                            }
                        }
                    }

                    return {
                        removal: values.removal,
                        item: item,
                        lastScanWarning: null
                    }

                } else {

                    // Getting the count of the required availability
                    let requiredAvailabilityLength = values.currentItems.filter(currentItem => currentItem.availability_category.id === availabilityCategory.id).length + 1
                    let availabilityLength = availabilityCategory.available

                    if (rentalCategory && rentalCategory.data) {
                        availabilityLength += rentalCategory.data.quantity
                    }

                    // Add the item if the availability is bigger or equal to the required scanned items
                    if (availabilityLength >= requiredAvailabilityLength) {

                        return {
                            removal: values.removal,
                            item: item,
                            lastScanWarning: null
                        }

                    } else {

                        return {
                            item: null,
                            lastScanWarning: 'Er is geen beschikbaarheid voor dit item in de huidige periode'
                        }
                    }

                }

            } else {

                return {
                    item: null,
                    lastScanWarning: 'De categorie van het gescande artikel komt niet voor in de beschikbare categorieen'
                }

            }

        } else {

            return {
                item: null,
                lastScanWarning: null
            }

        }
    }

    static loadStatusFromItems(values) {


        let status = {
            correctCount: 0,
            errors: [],
            completed: false,
            popupWarning: false
        }

        let amount = 0;


        if (values.categorySelection && values.items) {


            values.categorySelection.filter(item => item.category_id !== null).forEach((category) => {
                amount += category.quantity
            })

            values.items.forEach((item) => {

                if (item && item.valid.items && item.valid.items.errors && item.valid.items.errors.length) {
                    item.valid.items.errors.forEach(error => {
                        status.errors.push(error)
                    })
                }

            })

            if (values.categories) {
                values.categories.forEach((category) => {

                    const quantity = category.data.quantity
                    const relatedItems = values.items.filter(item => item.availability_category.id === category.data.category_id)

                    if (relatedItems.length === quantity) {
                        status.correctCount = status.correctCount + quantity
                    }


                })
            }

            if (amount) {

                if (values.items.length > amount) {
                    status.completed = false
                    status.warning = 'Let op, er zijn meer items gekoppeld dan er gereserveerd waren. Dit heeft financiële consequenties, wil je doorgaan?'

                } else if (values.items.length < amount) {
                    status.completed = false
                    status.warning = 'Let op, er zijn nog items te leveren. Komt de klant later terug voor deze items of moeten de items komen te vervallen? Let op, dit kan financiële consequenties hebben.'

                } else {


                    if (values.items.length === amount) {
                        status.completed = true
                        status.warning = false
                    } else {
                        status.completed = false
                        status.warning = 'Let op, er zijn genoeg items gescand maar er staan ook nog items open. Wil je deze items laten vervallen? Let op, dit kan financiële consequenties hebben.'
                    }
                }
            }


        }

        return status

    }

    static async submit(values, dispatch) {

        const linkRentalAll = async () => {
            const fetchUrl = ApiEnum.Api + '/item/link-rental-all?&rental=' + values.uuid

            const fetchValues = {
                method: 'post',
                fetchUrl: fetchUrl,
                params: values.params
            }
            const response = await fetchHelper(fetchValues, dispatch)

            if (response.status.code === 200) {
                dispatch(ErrorAction.unsetError())
                return response
            } else {
                dispatch(ErrorAction.setError(response))
                return response
            }
        }

        if (!values.create) {

            values.fetchUrl = ApiEnum.Api + '/mutation/create?rental=' + values.uuid
            const response = await fetchHelper(values, dispatch)

            if (response) {
                return linkRentalAll()
            }

        }

        if (values.create) {
            return linkRentalAll()
        }

    }

    static toggleRemarks(values) {
        return values;
    }

    static changeRemarks(value) {
        return value;
    }

    static togglePopup() {
        return true;
    }

    static reset() {
        return true;
    }

    static async loadFromRental(values, dispatch) {
        values.method = 'post'
        values.fetchUrl = ApiEnum.Api + '/item/list?limit=9999'

        const response = await fetchHelper(values, dispatch)

        if (response?.status?.code === 200) {

            dispatch(ErrorAction.unsetError())

            if (response.items && response.items.data) {

                return response.items.data.map(item => {
                    return {
                        ...item,
                        locked: true,
                        valid: {
                            status: {
                                code: 'locked'
                            }
                        }
                    }
                })

            } else {
                return []
            }

        } else {
            dispatch(ErrorAction.setError(response))
            return []
        }
    }

    static loadInitialCategorySelection(items) {

        if (items) {
            return items.filter(item => item.type !== 'deposit').map((item) => {

                return {
                    category_id: item.category_id,
                    quantity: item.quantity
                }
            })
        } else {
            return []
        }


    }

    static mutateCategorySelection(values) {
        return values
    }

    static async unlinkItem(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        if (response) {
            return response
        } else return null

    }

    static togglePassOnCategories(value) {
        return value
    }


}
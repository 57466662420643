import ActionUtility from '../../common/helpers/ActionUtility'
import CityEffect from "./CityEffect";
import ApiEnum from "../../common/constants/ApiEnum";

export default class CityAction {
    static REQUEST_CITY_LIST = 'CountryAction.REQUEST_CITY_LIST';

    static cityList() {
        return async (dispatch, getState) => {

            const values = {
                fetchUrl: ApiEnum.ApiGeneral + '/city/list',
                method: 'get',
                accessToken: getState().authentication.accessToken
            }

            await ActionUtility.createThunkEffect(dispatch, CityAction.REQUEST_CITY_LIST, CityEffect.cityList, values);
        };
    }

}
import {closeServiceModal, getServiceList} from "./serviceModalSlice";
import {toggleService} from "./serviceModalSlice";
import {useDispatch, useSelector} from "react-redux";
import {setServiceModalRemarks, setSelectedServices} from "./serviceModalSlice";
import React, {useEffect, useState} from "react";
import translate from "../../createSlice/common/helpers/utils/translate";
import Select from "react-select";
import TinyLoader from "../../common/components/General/TinyLoader";

const ServiceModalContainer = () => {

    const dispatch = useDispatch()

    const {colors} = useSelector((state) => state.environment)

    const {
        item,
        active,
        remarks,
        serviceOptions,
        selectedServices,
        servicesLoading,
        showRemark
    } = useSelector((state) => state.serviceModal)

    useEffect(() => {
        dispatch(getServiceList(Number(item.id)))
    }, [])

    if (item && active) {

        const content = formatContent(item)
        return (
            <div className="c-modal">
                <div className="c-modal__bg"/>
                <form onSubmit={(e) => {
                    e.preventDefault()
                    dispatch(closeServiceModal())
                }} className="c-modal__content c-modal__content--text c-modal__content--service">

                    <button className="c-modal__close" onClick={() => dispatch(closeServiceModal())}>
                        <i className="icon icon-cross"/>
                    </button>

                    <div className="c-modal__header">
                        <h2>{content.title}</h2>
                    </div>

                    <div className="c-modal__body">
                        <p>{content.text}</p>
                        {(item.rental_status.code !== 'service') && <>
                            {!servicesLoading ? (
                                <>

                                    {!!serviceOptions && serviceOptions.length > 0 &&
                                        <div className={'form-group'} style={{margin: '1rem 0'}}>

                                            <label style={{display: 'block'}}>{translate('malfunction')}</label>

                                            <Select
                                                placeholder={translate('chooseDefect')}
                                                options={serviceOptions}
                                                isSearchable={true}
                                                isClearable={true}
                                                isMulti
                                                onChange={(value) => dispatch(setSelectedServices(value))}
                                                value={selectedServices}
                                            />

                                        </div>
                                    }

                                    {showRemark &&
                                        <div className={'form-group'} style={{margin: '1rem 0'}}>
                                            <label htmlFor={'service-remark'}>{translate('notes')}</label>
                                            <textarea
                                                value={remarks}
                                                onChange={(e) => dispatch(setServiceModalRemarks(e.target.value))}
                                                id={'service-remark'}
                                                style={{
                                                    width: '100%',
                                                    minHeight: '10rem',
                                                    border: '1px solid #eee',
                                                    padding: '1rem'
                                                }}
                                            />
                                        </div>
                                    }
                                </>
                            ) : (
                                <div className={'form-group'} style={{margin: '1rem 0'}}>
                                    <TinyLoader color={colors.dark}/>
                                </div>
                            )}
                        </>}
                    </div>


                    <div className="c-modal__action">
                        {(item.rental_status.code !== 'service') && !servicesLoading ?
                            <>
                                {((!!serviceOptions?.length && !!selectedServices?.length) || !serviceOptions.length) &&
                                    <button className="btn btn--small btn--primary"
                                            onClick={() => dispatch(toggleService())}>
                                        <span>{content.buttonText}</span>
                                    </button>
                                }
                            </>
                            :
                            <button className="btn btn--small btn--primary" onClick={() => dispatch(toggleService())}>
                                <span>{content.buttonText}</span>
                            </button>

                        }


                        <button
                            className="btn btn--small btn--outline"
                            type={'submit'}
                        >
                            <span>{translate('back')}</span>
                        </button>

                    </div>
                </form>
            </div>
        )
    } else return null

}

const formatContent = (item) => {

    let title = 'Item ' + item.product_code + ' ' + translate('undoServiceSuffix')
    let text = translate('undoServiceQuestion');
    let buttonText = translate('confirm')

    if (item.rental_status.code !== 'service') {
        title = 'Item ' + item.product_code + ' ' + translate('setServiceSuffix')
        text = translate('setServiceQuestion');

        if (item.rental) {
            text = translate('disconnectItemMaintenanceText');
            buttonText = translate('disconnectAndConfirm')
        }
    }

    return {
        title: title,
        text: text,
        buttonText: buttonText
    }

}

export default ServiceModalContainer

import ActionUtility from "../../../common/helpers/ActionUtility";
import RentalDetailEffect from "./RentalDetailEffect";
import ApiEnum from "../../../common/constants/ApiEnum";
import TransactionEffect from "../../transaction/TransactionEffect";

export default class RentalDetailAction {

    static REQUEST_DETAIL = 'RentalDetailAction.REQUEST_DETAIL';
    static REQUEST_MUTATIONS = 'RentalDetailAction.REQUEST_MUTATIONS';
    static REQUEST_TRANSACTIONS = 'RentalDetailAction.REQUEST_TRANSACTIONS';

    static SEND_MAIL = 'RentalDetailAction.SEND_MAIL';
    static SEND_PAYMENT_MAIL = 'RentalDetailAction.SEND_PAYMENT_MAIL';

    static SET_LOADING = 'RentalDetailAction.SET_LOADING';
    static RESET = 'RentalDetailAction.RESET';

    static detail(id) {
        return async (dispatch, getState) => {

            const values = {
                fetchUrl: ApiEnum.Api + '/rental/detail?uuid=' + id,
                method: 'post',
                accessToken: getState().authentication.accessToken,
                params: {
                    auth_token: localStorage.getItem('authToken'),
                }
            }


            await ActionUtility.createThunkEffect(dispatch, RentalDetailAction.REQUEST_DETAIL, RentalDetailEffect.detail, values);
        }

    }

    static sendMail() {
        return async (dispatch, getState) => {

            const values = {
                fetchUrl: ApiEnum.Api + '/rental/send-mail?uuid=' + getState().rentalDetail.data.data.rental.uuid,
                method: 'post',
                accessToken: getState().authentication.accessToken,
                params: {
                    auth_token: localStorage.getItem('authToken')
                }
            }

            await ActionUtility.createThunkEffect(dispatch, RentalDetailAction.SEND_MAIL, RentalDetailEffect.sendMail, values);
        }

    }

    static sendPaymentMail() {
        return async (dispatch, getState) => {

            const values = {
                fetchUrl: ApiEnum.Api + '/rental/send-payment-mail?uuid=' + getState().rentalDetail.data.data.rental.uuid,
                method: 'post',
                accessToken: getState().authentication.accessToken,
                params: {
                    auth_token: localStorage.getItem('authToken')
                }
            }

            await ActionUtility.createThunkEffect(dispatch, RentalDetailAction.SEND_PAYMENT_MAIL, RentalDetailEffect.sendMail, values);
        }

    }

    static requestMutations(id) {
        return async (dispatch, getState) => {

            const values = {
                fetchUrl: ApiEnum.Api + '/mutation/list?rental=' + id,
                method: 'post',
                accessToken: getState().authentication.accessToken,
                params: {
                    auth_token: localStorage.getItem('authToken')
                }
            }

            await ActionUtility.createThunkEffect(dispatch, RentalDetailAction.REQUEST_MUTATIONS, RentalDetailEffect.requestMutations, values);
        };
    }

    static requestTransactions(uuid) {

        return async (dispatch, getState) => {
            const values = {
                fetchUrl: ApiEnum.Api + '/transaction/list?rental=' + uuid,
                method: 'post',
                accessToken: getState().authentication.accessToken,
                params: {
                    auth_token: localStorage.getItem('authToken')
                }
            }

            await ActionUtility.createThunkEffect(dispatch, RentalDetailAction.REQUEST_TRANSACTIONS, RentalDetailEffect.requestTransactions, values);
        }

    }

    static setLoader(value) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, RentalDetailAction.SET_LOADING, RentalDetailEffect.setLoading, value);
        };
    }

    static reset() {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, RentalDetailAction.RESET, RentalDetailEffect.reset);
        };
    }


}

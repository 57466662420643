import {apiEnum} from "../../../enums/apiEnum";
import {useAppAxios} from "../../../../app/hooks";

export const apiPartnerRentalDetail = async (rentalUuid) => {
    return await useAppAxios.post(apiEnum.PARTNER + '/rental/detail', {}, {
        params: {
            uuid: rentalUuid,
        }
    }).then(function (response) {

        // Customer addresses from object to array
        if(response.data.items.customerAddresses){
            response.data.items.customerAddresses = Object.values(response.data.items.customerAddresses)
        }

        if(!response.data.items.rentalItems){
            response.data.items.rentalItems = []
        }


        return response
    }).catch(function (error) {
        console.log(error)
        return {
            data: {
                status: {
                    code: 500
                }
            }
        }
    })
}

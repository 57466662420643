// Dependencies
import React from 'react'
import {connect} from "react-redux";
import {withRouter, Link} from 'react-router-dom';
import {Helmet} from "react-helmet";

// Constants
import RouteEnum from "../../constants/RouteEnum";
import AuthenticationAction from "../../../stores/authentication/AuthenticationAction";

// Helpers
import GetContrast from "../../helpers/GetContrast";
import CheckDisplayModule from "../../helpers/CheckDisplayModule";
import {MobileMenu} from "./MobileMenu";
import translate from "../../../createSlice/common/helpers/utils/translate";
import Select, {components} from "react-select";
import {
    apiSwitchPartnerLocation
} from "../../../createSlice/common/api/partner/switchPartnerLocation/apiSwitchPartnerLocation";


const mapStateToProps = (state) => ({
    environment: state.environment,
    authentication: state.authentication,
    partnerUser: state.authentication.partnerUser,
    partnerLocation: state.partnerLocation.detail,
    partnerLocationList: state.authentication.partnerUser.data.locationAccess,
});

class DashboardHeader extends React.Component {


    constructor(props) {
        super(props);
        this.inputLocationRef = React.createRef();

        this.state = {
            showMenu: false,
            loadChat: false,
            chatStatus: true,
            showLocationSelection: false,
            selectedPartnerLocation: undefined,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.partnerLocationList !== this.props.partnerLocationList) {
            this.setState({
                selectedPartnerLocation: this.props.partnerLocationList
            })
        }


        if(prevState.locationId !== this.state.locationId){
            const fetchData = async () => {
                await this.changeToOtherLocation(this.state.locationId);
            }

            fetchData();
        }

    }

    componentDidMount() {
        document.addEventListener("click", this.togglePartnerLocationOptions, false);
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.togglePartnerLocationOptions, false)
    }

    togglePartnerLocationOptions = (event) => {
        if (this.inputLocationRef && !this.inputLocationRef.current.contains(event.target)) {
            this.setState({
                showLocationSelection: false
            })
        }
    }

    getCurrentLocation = () => {
        const { partnerLocationId } = this.props.partnerUser;
        const { partnerLocationList } = this.props;

        if (!partnerLocationList) {
            return null;
        }

        const currentLocation = partnerLocationList.find(
            location => location.id === parseInt(partnerLocationId)
        );

        return currentLocation || null;
    }


    changeToOtherLocation = async (id) => {
        try {
            const response = await apiSwitchPartnerLocation(id);
            if (response.data.items && response.data.items.accessToken) {
                localStorage.setItem('xPartnerLocationAuth', response.data.items.accessToken);
                this.props.dispatch(AuthenticationAction.updatePartnerLocationAuth(response.data.items.accessToken));
            }
        } catch (error) {
            console.error('Error switching location:', error);
        }
    }



    toggleMobileMenu = () => {
        this.setState({
            showMenu: !this.state.showMenu
        })
    }


    render() {

        var element1 = document.getElementsByClassName("dante-embed-chat");
        var element2 = document.getElementsByClassName("dante-embed-icon");
        var element3 = document.getElementById("dante_tooltip_iframe");

        if (this.state.chatStatus) {
            if (element1[0]) {
                element1[0].classList.add("inActive");
            }
            if (element2[0]) {
                element2[0].classList.add("inActive");
            }
            if (element3) {
                element3.classList.add("inActive");
            }
        } else {
            if (element1[0]) {
                element1[0].classList.remove("inActive");
            }
            if (element2[0]) {
                element2[0].classList.remove("inActive");
            }
            if (element3) {
                element3.classList.remove("inActive");
            }
        }


        const props = this.props;
        const {environment, partnerUser} = props;
        const {slug} = environment;
        const {dark, light} = environment.colors
        const hidePartnerLocationDropdown = !!this.props.partnerLocation?.hide_partner_location_dropdown ?? true

        let partnerLocationList = this.props.partnerLocationList?.sort((a, b) => a.name.localeCompare(b.name))?.map((partnerLocation) => {
                return {
                    value: partnerLocation.id,
                    label: partnerLocation.name,
                }
            }
        )
        return (
            <>
                {!!this.state.loadChat &&
                    <Helmet>
                        <script>window.danteEmbed =
                            "https://dante-ai.com/embed?kb_id=2e96f1e1-1f99-4b1a-b7a6-c455bb063057&token=68b970fa-31da-418f-8f1a-dbdd83c546b9&modeltype=gpt-4&mode=undefined&bubble=true&bubbleopen=false"
                        </script>
                        <script src="https://dante-ai.com/bubble-embed.js"></script>


                        <style>

                            {`
                   
                    .dante-embed-chat{
                        opacity: 1!important;
                        pointer-events: all!important;
                    }
                    
                    .dante-embed-chat.inActive{
                        opacity: 0!important;
                        pointer-events: none!important;
                    }
                   
                    .dante-embed-icon{
                        bottom: 122px!important;
                        opacity: 1!important;
                        pointer-events: all!important;
                    }
                    
                    .dante-embed-icon.inActive{
                        bottom: 122px!important;
                        opacity: 0!important;
                        pointer-events: none!important;
                    }
                    
                    #dante_tooltip_iframe{
                        bottom: 180px!important;
                        opacity: 1!important;
                        pointer-events: all!important;
                    }
                    
                    #dante_tooltip_iframe.inActive{
                        bottom: 180px!important;
                        opacity: 0!important;
                        pointer-events: none!important;
                    }
                    
                    `}
                        </style>
                    </Helmet>
                }

                <header className="c-header">

                    <div className="c-header__container">
                        <div className="c-header__container__logo"
                             onClick={() => this._toHome()}
                        >
                            <InnerStyle colors={environment.colors}/>
                            <img src={environment.data.logo} alt={'logo'}/>
                        </div>
                        <div className="c-header__container__location-title"
                             ref={this.inputLocationRef}
                             onClick={() => this.setState({showLocationSelection: true})}
                        >
                            {hidePartnerLocationDropdown && !!this.props.partnerLocation &&
                                <span className={'c-location-title'}>{this.props.partnerLocation.name}</span>
                            }
                            {!hidePartnerLocationDropdown &&
                                <div className="c-header__container__location-title__select">
                                    <Select
                                        placeholder={this.getCurrentLocation()?.name ?? translate('searchLocations')}
                                        styles={{
                                            placeholder: (defaultStyles) => {
                                                return {
                                                    ...defaultStyles,
                                                    color: '#000',
                                                }
                                            }
                                        }}
                                        options={partnerLocationList}
                                        components={data => {
                                            return (
                                                <div style={{
                                                    color: data.color,
                                                    backgroundColor: data.backgroundColour
                                                }}>
                                                    <components.Option {...data} />
                                                </div>
                                            )
                                        }}
                                        onChange={(e) => {
                                            this.setState({
                                            ...this.state,
                                            locationId: e.value
                                        })}}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                    <div className="c-header__misc">


                        <Link to={'/' + slug + RouteEnum.Dashboard} className="btn btn--small btn--outline"><span
                            style={{'color': dark}}>Dashboard</span></Link>

                        {CheckDisplayModule('rentalCreate', environment, partnerUser) &&
                            <Link className="btn btn--small"
                                  to={'/' + slug + RouteEnum.RentalCreate}
                                  style={{'marginLeft': '.5rem', backgroundColor: dark}}>
                        <span
                            style={{color: GetContrast(dark)}}>{translate('newRental')}</span>
                            </Link>
                        }

                        {CheckDisplayModule('availability', environment, partnerUser) &&
                            <Link className="btn btn--small"
                                  style={{'marginLeft': '.5rem', backgroundColor: light}}
                                  to={'/' + slug + RouteEnum.Availability}
                            >
                        <span
                            style={{color: GetContrast(light)}}>{translate('availability')}</span>
                            </Link>
                        }
                        {CheckDisplayModule('scanItems', environment, partnerUser) &&
                            <Link className="btn btn--small"
                                  style={{'marginLeft': '.5rem', backgroundColor: light}}
                                  to={'/' + slug + RouteEnum.ScanItems}
                            >
                        <span
                            style={{color: GetContrast(light)}}>{translate('scanItems')}</span>
                            </Link>
                        }

                        {CheckDisplayModule('stock', environment, partnerUser) &&
                            <Link className="btn btn--small"
                                  style={{'marginLeft': '.5rem', backgroundColor: light}}
                                  to={'/' + slug + RouteEnum.Stock}>
                                <span style={{color: GetContrast(light)}}>{translate('stock')}</span>
                            </Link>
                        }

                        {CheckDisplayModule('itemsLate', environment, partnerUser) &&
                            <Link className="btn btn--small"
                                  style={{'marginLeft': '.5rem', backgroundColor: '#272727'}}
                                  to={'/' + slug + RouteEnum.ItemsLate}>
                                <span style={{color: 'white'}}>{translate('itemsLate')}</span>
                            </Link>
                        }

                        {CheckDisplayModule('itemsInService', environment, partnerUser) &&
                        <Link className="btn btn--small"
                              style={{'marginLeft': '.5rem', backgroundColor: '#272727'}}
                              to={'/' + slug + RouteEnum.ItemsInService}>
                            <span style={{color: 'white'}}>{translate('itemsInService')}</span>
                        </Link>
                        }

                        {CheckDisplayModule('openingTimes', environment, partnerUser) &&
                            <Link className="btn btn--small"
                                  style={{'marginLeft': '.5rem', backgroundColor: '#eee'}}
                                  to={'/' + slug + RouteEnum.Settings}>
                                <span style={{color: '#000'}}>{translate('settings')}</span>
                            </Link>
                        }

                        {CheckDisplayModule('transactions', environment, partnerUser) &&
                            <Link className="btn btn--small"
                                  style={{'marginLeft': '.5rem', backgroundColor: '#eee'}}
                                  to={'/' + slug + RouteEnum.TransactionOverview}
                            >
                                <span style={{color: '#000'}}>{translate('transactions')}</span>

                            </Link>
                        }

                        <a href="https://help.tilia.app/" target="_blank"
                           className="btn btn--small"
                           style={{'marginLeft': '.5rem', backgroundColor: '#eee'}}>
                            <span style={{color: '#000'}}>Helpdesk</span>
                        </a>

                        <button className="btn btn--small btn--outline" onClick={() => this._unsetUser()}>
                            <span>{translate('changeUser')} <small>(F12)</small></span>
                        </button>

                        <button className="btn btn--small btn--outline" onClick={() => this._openChatWidget()}>
                            <span>Chat</span>
                        </button>

                        <button className="btn btn--small btn--outline btn--square" onClick={() => this._logOut()}><i
                            style={{color: dark}}
                            className="icon icon-power"/>
                        </button>
                    </div>

                    <div className="c-header__menu-trigger" onClick={() => this.toggleMobileMenu()}>
                        <span/>
                        <span/>
                        <span/>
                    </div>

                </header>

                {
                    !!this.state.showMenu &&
                    <MobileMenu
                        toggleMenu={this.toggleMobileMenu}
                        dispatch={this.props.dispatch}
                        openChatWidget={this._openChatWidget}
                        environment={this.props.environment}
                        partnerUser={this.props.partnerUser}
                    />
                }
            </>
        )


    }

    _toHome = () => {
        const {history} = this.props
        history.push('/' + this.props.environment.slug + RouteEnum.Dashboard)
    }


    _logOut = () => {
        this.props.dispatch(AuthenticationAction.logout())
    }

    _unsetUser = () => {
        this.props.dispatch(AuthenticationAction.unsetUser())
    }

    _openChatWidget = () => {
        this.setState({
            chatStatus: !this.state.chatStatus,
            loadChat: true
        })
    }

}

const InnerStyle = (props) => {

    const {light, dark} = props.colors

    return (
        <style dangerouslySetInnerHTML={{
            __html: `
                  .pagination li.active a, .ReactVirtualized__Table__headerRow, .c-table-filter ul .is-active { background:` + light + `; color:  ` + GetContrast(light) + `;}
                  .ReactVirtualized__Table__headerRow span {color:` + GetContrast(light) + ` }

                  .c-icon-blocks__single.is-active{
                    background: ` + GetContrast(light) + ` !important;
                  }

                  .c-icon-blocks__single.is-active div{
                    background: ` + light + ` !important;
                  }

                  .c-icon-blocks__single.is-active div small{
                    color: ` + GetContrast(light) + ` !important;
                  }

                  .c-icon-blocks__single.is-active h2, .c-icon-blocks__single.is-active i{
                     color: ` + light + ` !important;
                  }

                  .ReactVirtualized__Table__row:hover, .c-table--custom thead tr { background:` + light + ` !important; color: ` + GetContrast(light) + ` !important; }
                    .c-select-customers__item.is-active, .c-order-handling__number, .c-order-handling-issue-numbers .c-order-handling__list__single__amount button {background:` + light + `; color: ` + GetContrast(dark) + ` !important }
                    .c-icon-blocks__single div small, .btn--outline i, .btn--outline span {color:` + dark + `; }
                 `
        }}/>
    )

}

export default connect(mapStateToProps)(withRouter(DashboardHeader));

import DashboardAction from "./DashboardAction";
import {SortDirection} from "react-virtualized";
import moment from 'moment'
import DashboardDisplayOptions from "../../common/constants/DashboardDisplayOptions";
import DashboardDeliveryFilterOptions from "../../common/constants/DashboardDeliveryFilterOptions";
import DashboardRentalButtons from "../../common/constants/RentalDashboardButtons";

export default class DashboardReducer {

    static initialState = {
        list: {
            data: false,
            loading: false,
            sortBy: 'RentalNr',
            sortDirection: SortDirection.DESC,
        },
        items: {
            data: false,
            loading: false,
            sortBy: 'RentalNr',
            sortDirection: SortDirection.DESC,
        },
        period: {
            start: moment().startOf('day').toDate(),
            end: moment().endOf('day').toDate()
        },
        display: { // Was weeklyFilterTypes
            active: 'reservation',
            options: DashboardDisplayOptions(),
        },
        deliveryFilter: {  // Was activeSub
            active: 'all',
            options: DashboardDeliveryFilterOptions()
        },
        weeklyAvailability: {
            data: null,
            loading: false,
        },
        rentalDashboardButtons: {
            active: null,
            options: DashboardRentalButtons()
        },
        activePage: 0,
    };

    static reducer(state = DashboardReducer.initialState, action) {


        switch (action.type) {

            case DashboardAction.REQUEST_RENTALS_IN_PERIOD:
                return {
                    ...state,
                    list: {
                        ...state.list,
                        loading: true
                    }
                };

            case DashboardAction.REQUEST_RENTALS_IN_PERIOD + '_FINISHED':

                if (action.payload) {

                    return {
                        ...state,
                        list: {
                            loading: false,
                            data: action.payload.data,
                            pages: action.payload.pages,
                            activePage: action.payload.activePage,
                            sortBy: action.payload.sortBy,
                            sortDirection: action.payload.sortDirection,
                        },

                        display: {
                            ...state.display,
                            active: action.payload.display,
                        },
                        deliveryFilter: {
                            ...state.deliveryFilter,
                            active: action.payload.deliveryFilter
                        },
                        period: action.payload.dates
                    };
                } else {
                    return {...state}
                }

            case DashboardAction.REQUEST_ITEMS_IN_PERIOD:
                return {
                    ...state,
                    items: {
                        ...state.items,
                        loading: true
                    }
                };

            case DashboardAction.REQUEST_ITEMS_IN_PERIOD + '_FINISHED':

                if (action.payload) {

                    return {
                        ...state,
                        items: {
                            loading: false,
                            data: action.payload.data,
                            pages: action.payload.pages,
                            activePage: action.payload.activePage,
                            sortBy: action.payload.sortBy,
                            sortDirection: action.payload.sortDirection,
                        },

                        display: {
                            ...state.display,
                            active: action.payload.display,
                        },
                        deliveryFilter: {
                            ...state.deliveryFilter,
                            active: action.payload.deliveryFilter
                        },
                        period: action.payload.dates
                    };
                } else {
                    return {...state}
                }


            case DashboardAction.REQUEST_WEEKLY_AVAILABILITY:

                return {
                    ...state,
                    ...state.dashboard,
                    weeklyAvailability: {
                        data: state.weeklyAvailability.data,
                        loading: true
                    }
                };

            case DashboardAction.REQUEST_WEEKLY_AVAILABILITY + '_FINISHED':

                return {
                    ...state,
                    ...state.dashboard,
                    weeklyAvailability: {
                        data: action.payload,
                        loading: false
                    }
                };


            case DashboardAction.SET_PAGE:

                return {
                    ...state,
                };

            case DashboardAction.SET_PAGE + '_FINISHED':

                return {
                    ...state,
                    activePage: action.payload

                }

            case DashboardAction.SET_LOADING + '_FINISHED':
                return {
                    ...state,
                    loading: action.payload
                }

            case DashboardAction.RESET + '_FINISHED':
                if (action.payload) {
                    return {
                        ...state,
                        rentalDashboardButtons: DashboardReducer.initialState.rentalDashboardButtons
                    }
                } else {
                    return {...state}
                }

            case DashboardAction.SET_RENTAL_BUTTON + '_FINISHED':

                return {
                    ...state,
                    rentalDashboardButtons: {
                        ...state.rentalDashboardButtons,
                        active: action.payload
                    },
                }


            default:
                return state;
        }


    }
}
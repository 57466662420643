import React from 'react'
import {connect} from 'react-redux'
import EnvironmentAction from "../../stores/environment/EnvironmentAction";
import ItemAction from "../../stores/item/ItemAction";
import ReactPaginate from 'react-paginate';
import TransactionAction from "../../stores/transaction/TransactionAction";
import RouteEnum from "../../common/constants/RouteEnum";
import Scanner from "../../common/components/General/Scanner";
import translate from "../../createSlice/common/helpers/utils/translate";

const mapStateToProps = (state) => ({
    item: state.item,
    environment: state.environment
})


class ItemsLate extends React.Component {

    componentDidMount() {
        this.load()
    }

    load() {
        this.props.dispatch(EnvironmentAction.setLoader(true)).then(() => {
            this.props.dispatch(ItemAction.requestLateItems()).then(() => {
                this.props.dispatch(EnvironmentAction.setLoader(false))
            })
        })
    }

    componentWillUnmount() {
        if (this.props.item.exportFile) {
            this.props.dispatch(ItemAction.resetExportFileData())
        }
    }

    render() {

        const {item} = this.props
        const {lateItems} = item
        const {pages} = lateItems

        const {primary_color_dark} = this.props.environment.data.info

        if (lateItems) {

            return (
                <main style={{padding: '4rem 0'}}>

                    <Scanner type={'rental'}/>

                    <div className="c-info-blocks__row o-container o-container--primary">

                        {/*{!(this.props.item.exportFile && !this.props.item.exportLoading) &&*/}
                        {/*    <a className={'btn btn--small'} onClick={() => {*/}
                        {/*        this._generateExportList('late', 'pdf')*/}
                        {/*    }} style={{*/}
                        {/*        marginBottom: '1rem',*/}
                        {/*        marginRight: '1rem',*/}
                        {/*        background: primary_color_dark,*/}
                        {/*        color: '#fff',*/}
                        {/*        cursor: 'pointer',*/}
                        {/*        display: 'inline-block'*/}
                        {/*    }}>PDF {translate('generating')}*/}
                        {/*    </a>*/}
                        {/*}*/}

                        {!(this.props.item.exportFile && !this.props.item.exportLoading) &&
                            <a className={'btn btn--small'} onClick={() => {
                                this._generateExportList('late', 'xls')
                            }} style={{
                                marginBottom: '1rem',
                                background: primary_color_dark,
                                color: '#fff',
                                cursor: 'pointer',
                                display: 'inline-block'
                            }}>{translate('download')} XLS
                            </a>
                        }

                        {this.props.item.exportLoading && <p>{translate('exportIsBeingGenerated')}...</p>}

                        {(this.props.item.exportFile && this.props.item.exportFile.items?.download_url) &&
                            <a className={'btn btn--small'}
                               href={this.props.item.exportFile.items?.download_url}
                               onClick={() => setTimeout(() => this.props.dispatch(ItemAction.resetExportFileData()), 1500)}
                               target={'_blank'}
                               style={{
                                       marginBottom: '1rem',
                                       background: primary_color_dark,
                                       color: '#fff', cursor: 'pointer',
                                       display: 'inline-block'
                                   }}>{translate('downloadExportFile')}</a>
                        }

                        <div className="c-info-blocks__single">
                            <header className="c-subject-header c-subject-header--closed">
                                <h2>{translate('itemsTooLate')}</h2>
                            </header>
                            <div className="c-info-blocks__single__body c-info-blocks__single__body--scroll">
                                <table className="c-table-standard">

                                    <tbody>

                                    <tr>
                                        <th>{translate('productCodeNew')}</th>
                                        <th>{translate('category')}</th>
                                        <th>{translate('rental')}</th>
                                        <th>{translate('name')}</th>
                                        <th>{translate('daysTooLate')}</th>
                                        <th>{translate('establishment')}</th>

                                    </tr>

                                    {lateItems.data && lateItems.data.map((item, index) => {

                                        let code = 'none'

                                        if (item.rental_status) {
                                            code = item.rental_status.code
                                        }

                                        return (
                                            <tr className={'status-' + code} key={'late-item-' + index}>
                                                <td>{item.product_code} </td>
                                                <td>{item.category}</td>
                                                {!item.status && <td/>}
                                                <td className={'hover-td'}
                                                    onClick={() => this._clickItemRow(item)}>{item.rental.id}</td>
                                                <td>{item.customer}</td>
                                                <td>{item.days_late}</td>
                                                <td>{item.partner_location}</td>
                                            </tr>
                                        )
                                    })}

                                    </tbody>

                                </table>

                            </div>
                        </div>

                        {!!pages &&
                            <ReactPaginate
                                previousLabel={'< vorige'}
                                nextLabel={'volgende >'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={pages.total}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={(data) => this._handlePageClick(data)}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                forcePage={parseInt(pages.current)}
                                activeClassName={'active'}
                            />
                        }

                    </div>
                </main>
            )
        } else return null

    }

    _clickItemRow = (item) => {
        this.props.history.push('/' + this.props.environment.slug + RouteEnum.RentalDetail + '/' + item.rental.uuid)
    }

    _handlePageClick = (i) => {
        this.props.dispatch(ItemAction.setLateItemsPage(i.selected)).then(() => {
            this.props.dispatch(ItemAction.requestLateItems())
        })
    }


    _generateExportList = (type, fileType) => {
        this.props.dispatch(ItemAction.requestExportList(type, fileType))
    }


}

export default connect(mapStateToProps)(ItemsLate)

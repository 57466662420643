import {useAppAxios} from "../../../../app/hooks";
import {apiEnum} from "../../../enums/apiEnum";

export const apiRequestPasswordReset = async (data) => {
    return await useAppAxios.post(apiEnum.PARTNER + '/location/send-password-reset-mail' + data.url, {},
        {}).then(function (response) {
        switch (response.status) {
            case(200):
                return {
                    data: {
                        status: {
                            code: 200
                        },
                        message: "Successfully requested password reset"
                    }
                }
            case(404):
                return {
                    data: {
                        status: {
                            code: 500
                        },
                        message: "Partner location not found"
                    }
                }
            default:
                return {
                    data: {
                        status: {
                            code: 500
                        },
                        message: "Er heeft zich een onbekende fout voorgedaan. Probeer het later opnieuw."
                    }
                }
        }
    }).catch(function (error) {
        console.log(error)
        return {
            data: {
                status: {
                    code: 500
                },
                message: "Er heeft zich een onbekende fout voorgedaan. Probeer het later opnieuw."
            }
        }
    })
}

import {apiEnum} from "../../../enums/apiEnum";
import {useAppAxios} from "../../../../app/hooks";

export const apiPartnerRentalRemoveDeposit = async (rentalUuid) => {
    return await useAppAxios.post(apiEnum.PARTNER + '/rental/remove-deposit', {}, {
        params: {
            uuid: rentalUuid
        }
    }).then(function (response) {
        return response
    }).catch(function (error) {
        console.log(error)
        return {
            data: {
                status: {
                    code: 500
                }
            }
        }
    })
}

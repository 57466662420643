import {apiEnum} from "../../../enums/apiEnum";
import {useAppAxios} from "../../../../app/hooks";
import {decamelizeKeys} from "humps";

export const apiPartnerLocationOpeningTimes = async (openingTimes) => {

    return await useAppAxios.post(apiEnum.PARTNER + '/location/opening-times', {
        opening_times: decamelizeKeys(openingTimes)
    }).then(function (response) {
        return response
    }).catch(function (error) {
        console.log(error)
        return {
            data: {
                status: {
                    code: 500
                }
            }
        }
    })
}

import ApiEnum from "../../common/constants/ApiEnum";
import ErrorAction from "../error/ErrorAction";
import AuthenticationAction from "./AuthenticationAction";
import fetchHelper from "../../common/helpers/fetchHelper";

export default class AuthenticationEffect {

    static async requestAccessToken(dispatch) {


        const values = {
            fetchUrl: process.env.REACT_APP_API_URL + '/auth/connect', method: 'post', params: {
                key: ApiEnum.key, secret: ApiEnum.secret
            }
        }

        const response = await fetchHelper(values, dispatch)

        if (response.status.code === 200 || response.status.code === 400) {
            return {
                response: response.items,
            }
        } else {
            return {
                error: true, message: response.status.message
            }
        }
    }


    static async login(values, dispatch) {
        const response = await fetchHelper(values, dispatch)

        switch (response.status.code) {
            case(200):
                return {
                    success: true, data: response.items.partnerLocation, partnerLocationAuth: response.items.token
                }
            case(404):
                return {
                    success: false, notifications: {
                        error: 'Combinatie van e-mailadres en wachtwoord niet herkent. Probeer het opnieuw.'
                    }
                }
            case(401):
                return {
                    success: false, notifications: {
                        error: 'Combinatie van e-mailadres en wachtwoord niet herkent. Probeer het opnieuw.'
                    }
                }
            default:
                return {
                    success: false, notifications: {
                        error: 'Er heeft zich een onbekende fout voorgedaan. Probeer het later opnieuw.'
                    }
                }
        }
    }

    static async timeOut() {
        // No effect needed at the moment
        return true
    }


    static async logout() {
        localStorage.removeItem('xPartnerLocationAuth');
        localStorage.removeItem('xPartnerUserAuth');
        return true;
    }

    static async unsetUser() {
        return true
    }

    static async getUserByPin(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        switch (response?.status?.code ?? 9999) {
            case(200):

                dispatch(ErrorAction.unsetError())

                let updatedPartnerUser = {
                    ...response.items.partnerUser,
                    locationAccess: response.items.locationAccess
                };

                return {
                    success: true,
                    data: updatedPartnerUser,
                    notifications: false,
                    xPartnerUserAuth: response.items.partnerUser.auth_token,
                    partnerLocationId: response.items.partnerLocationId
                }

            case(404):
                return {
                    success: false,
                    notifications: {
                        error: 'Er is geen gebruiker gekoppeld aan deze pin code.'
                    }
                }

            case(401):
                return {
                    success: false,
                    notifications: false
                }

            default:
                return {
                    success: false,
                    notifications: {
                        error: 'Er heeft zich een onbekende fout voorgedaan. Probeer het later opnieuw.'
                    }
                }
        }
    }

    static async changePartnerLocationAuth(xPartnerLocationAuth, dispatch) {

        dispatch(AuthenticationAction.unsetUser())

        if (xPartnerLocationAuth) {
            return {
                success: true,
                xPartnerLocationAuth: xPartnerLocationAuth
            }

        }
        if (!xPartnerLocationAuth) {
            return {
                success: false,
                xPartnerLocationAuth: null
            }

        }

    }
}

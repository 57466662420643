import ActionUtility from "../../common/helpers/ActionUtility";
import PaymentEffect from "./PaymentEffect";
import ApiEnum from "../../common/constants/ApiEnum";

export default class PaymentAction {

    static REQUEST_METHOD_LIST = 'PaymentAction.SET_NEW_DATE';
    static REQUEST_STATUS_LIST = 'PaymentAction.CHECK_AVAILABILITY';


    static requestMethodList(rentalUuid) {

        return async (dispatch, getState) => {

            const values = {
                fetchUrl: ApiEnum.Api + '/payment-method/list',
                method: 'post',
                accessToken: getState().authentication.accessToken,
                params: {
                    auth_token: localStorage.getItem('authToken'),
                    partner_environment_id: getState().environment.data.info.id,
                    rental_uuid: rentalUuid
                }
            }

            await ActionUtility.createThunkEffect(dispatch, PaymentAction.REQUEST_METHOD_LIST, PaymentEffect.requestMethodList, values);
        }

    }

    static requestStatusList() {

        return async (dispatch, getState) => {

            const values = {
                fetchUrl: ApiEnum.Api + '/payment-status/list',
                method: 'post',
                accessToken: getState().authentication.accessToken,
                params: {
                    auth_token: localStorage.getItem('authToken'),
                    partner_environment_id: getState().environment.data.info.id
                }
            }

            await ActionUtility.createThunkEffect(dispatch, PaymentAction.REQUEST_STATUS_LIST, PaymentEffect.requestStatusList, values);
        }

    }


}

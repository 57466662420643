import {RentalHeader} from "../../../common/components/RentalHeader";
import {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {useHistory} from "react-router";

import {
    changeStep, formatCategories,
    giveItem,
    loadRentalItemsGive,
    setCategoryMutation,
    setSubmitOption,
    submitRentalItemsGive,
    validateGiveItems
} from "./rentalItemsGivePageSlice";
import {useParams} from "react-router-dom";
import GiveItemModal from "../../../common/components/GiveItemModal";
import {RentalInfoBar} from "../../../common/components/RentalInfoBar";
import {RentalActionBar} from "../../../common/components/RentalActionBar";
import {RentalOrderLinesToGive} from "../../../common/components/RentalOrderLinesToGive";
import {ScannedItems} from "../../../common/components/ScannedItems";
import {RentalItemNotifications} from "../../../common/components/RentalItemNotifications";
import TinyLoader from "../../../../common/components/General/TinyLoader";
import Loader from 'react-loader-spinner'
import {resetUnlinkItemModal} from "../../modals/unlinkItemModal/unlinkItemModalSlice";
import {resetServiceModal} from "../../../../views/serviceModal/serviceModalSlice";
import translate from "../../../common/helpers/utils/translate";
import {PinValidationWrapper} from "../../../../common/wrappers/PinValidationWrapper";
import {camelizeKeys} from "humps";
import {apiPartnerItemDetailById} from "../../../common/api/partner/item/apiPartnerItemDetailById";

export const RentalItemsGivePageContainer = () => {

    const dispatch = useAppDispatch()

    const {rentalUuid} = useParams();

    const {
        rentalContainer,
        step,
        giveItemValidation,
        rentalGiveItems,
        rentalItems,
        submitOption,
        rentalGiveItemNotifications,
        categoryMutations,
        submitting,
        occupiedItems,
        serviceItems,
        categories
    } = useAppSelector(store => store.rentalItemsGivePage)


    const {
        environment,
        unlinkItemModal,
        serviceModal
    } = useAppSelector(store => store)

    const {partnerLocation} = useAppSelector((state) => state)

    const history = useHistory()
    const [pageLoaded, setPageLoaded] = useState(false)

    useEffect(() => {
        if (rentalUuid) {
            dispatch(loadRentalItemsGive(rentalUuid)).then(() => {
                setPageLoaded(true)
            })
        }
    }, [])

    // After unlink/service modal set the given item so it will be put in the correct array of objects
    useEffect( () => {

        if (unlinkItemModal.submitted && unlinkItemModal.item) {
            dispatch(giveItem({item: unlinkItemModal.item}))
        }

        async function fetchServiceItemById() {

            const response = await apiPartnerItemDetailById(parseInt(serviceModal.item.id))
            dispatch(giveItem({item: response?.data?.items?.item}))

        }

        if (serviceModal.submitted && serviceModal.item) {
            fetchServiceItemById();
        }

    }, [unlinkItemModal.submitted, serviceModal.submitted])

    useEffect(() => {
        if (step === 'give') {
            dispatch(validateGiveItems())
        }
    }, [rentalGiveItems.length, step])

    useEffect(() => {
        // reformat categories
        dispatch(formatCategories())
    }, [rentalGiveItems, categoryMutations])


    const submit = () => {
        dispatch(submitRentalItemsGive()).then(() => {

            const rentalHasInsurance = rentalContainer.rentalItems.some((rentalItem) => rentalItem.type === 'insurance')

            if (!!rentalHasInsurance || !!partnerLocation?.detail?.hide_insurance_suggestions) {
                history.push('/' + environment.slug + '/dashboard/rental/transaction-check/' + rentalContainer.rental.uuid)
            } else {
                history.push('/' + environment.slug + '/dashboard/rental/insurance/' + rentalContainer.rental.uuid)
            }

        })
    }

    if (rentalContainer && pageLoaded) {
        return (
            <main>
                <PinValidationWrapper>
                    <div className="o-container o-container--primary" style={{paddingBottom: '6rem'}}>

                        <RentalHeader
                            rentalId={rentalContainer.rental.id}
                            rentalUuid={rentalContainer.rental.uuid}
                            pageTitle={'Uitgeven'}
                        />

                        <RentalInfoBar rentalContainer={rentalContainer}/>

                        <div className={'c-swap-container'}>

                            {!!rentalContainer.rentalItems.length &&
                                <>
                                    <h2>{translate('itemsToGive')}</h2>

                                    <RentalOrderLinesToGive
                                        rentalOrderLines={rentalContainer.rentalItems}
                                        categoryMutations={categoryMutations}
                                        giveItems={rentalGiveItems}
                                        rentalItems={rentalItems}
                                        mutateCategoryAmountAction={(categoryMutation) => dispatch(setCategoryMutation(categoryMutation))}
                                    />
                                </>
                            }

                            <ScannedItems
                                title={translate('itemsToBeGivenTitle')}
                                scanItemAction={(item) => dispatch(giveItem({item: item, scanned: true}))}
                                removeItemAction={(item) => dispatch(giveItem({item: item}))}
                                items={rentalGiveItems}
                                validation={true}
                                lockedItems={rentalItems}
                                validating={giveItemValidation.processing}
                                disabled={false}
                                notifications={rentalGiveItemNotifications}
                                itemScanner={true}
                            />

                            {!!occupiedItems.length &&
                                <ScannedItems
                                    title={translate('alreadyConnectedToExistingBooking')}
                                    scanItemAction={(item) => dispatch(giveItem({item: item}))}
                                    removeItemAction={(item) => dispatch(giveItem({item: item}))}
                                    items={occupiedItems}
                                    validation={true}
                                    validating={giveItemValidation.processing}
                                    disabled={false}
                                    notifications={rentalGiveItemNotifications}
                                    displayRentalInfo={true}
                                    itemScanner={false}
                                />
                            }

                            {!!serviceItems.length &&
                                <ScannedItems
                                    title={translate('itemsInService')}
                                    scanItemAction={(item) => dispatch(giveItem({item: item}))}
                                    removeItemAction={(item) => dispatch(giveItem({item: item}))}
                                    items={serviceItems}
                                    validation={true}
                                    validating={giveItemValidation.processing}
                                    disabled={false}
                                    notifications={rentalGiveItemNotifications}
                                    displayServiceInfo={true}
                                    itemScanner={false}
                                />
                            }

                            <RentalItemNotifications notifications={rentalGiveItemNotifications}/>

                            {giveItemValidation.processing &&
                                <div className={'c-processing'} style={{marginBottom: '1.5rem'}}>

                                    <Loader
                                        type={'circles'}
                                        height="20"
                                        width="20"
                                        color='#002BD3'
                                        ariaLabel='loading'
                                    />

                                    <p style={{marginLeft: '1rem'}}> {translate('itemValidation')}</p>

                                </div>
                            }
                        </div>


                        {(step === 'modal') &&
                            <GiveItemModal
                                submitting={submitting}
                                rentalOrderLines={rentalContainer.rentalItems}
                                rentalItems={rentalItems}
                                activeOption={submitOption}
                                changeOption={(value) => dispatch(setSubmitOption(value))}
                                rentalContainer={rentalContainer}
                                onClose={() => {
                                    dispatch(changeStep('give'))
                                }}
                                onSubmit={() => {
                                    submit()
                                }}
                                giveItems={rentalGiveItems}
                                tooManyCategoriesGiven={giveItemValidation.tooManyCategoriesGiven}
                                notAllCategoriesGiven={giveItemValidation.notAllCategoriesGiven}
                                categoryMutations={categoryMutations}
                                mutateCategoryAmountAction={(categoryMutation) => dispatch(setCategoryMutation(categoryMutation))}
                            />
                        }

                        <RentalActionBar
                            orderLines={rentalContainer.rentalItems}
                            showLoader={submitting}
                            rentalGiveItems={rentalGiveItems}
                            rentalItems={rentalItems}
                            submitEnabled={!!rentalGiveItems.length}
                            rentalNotifications={rentalGiveItemNotifications}
                            categoryMutations={categoryMutations}
                            buttonText={'Uitgeven'}
                            onSubmit={() => {
                                dispatch(validateGiveItems()).then(() => {
                                    if (!rentalGiveItemNotifications.filter((filter) => (filter.type === 'error' || filter.type === 'unavailable')).length) {
                                        if (giveItemValidation.completed && !submitting) {
                                            submit()
                                            return
                                        }
                                        dispatch(changeStep('modal'))
                                    }
                                })
                            }}
                        />

                    </div>
                </PinValidationWrapper>
            </main>
        )
    } else return <TinyLoader/>

}

// Dependencies
import React from 'react'
import {connect} from "react-redux";
import ReactPaginate from 'react-paginate';

// Actions
import TransactionAction from "../../stores/transaction/TransactionAction";

// Helpers
import DisplayPrice from "../../common/helpers/DisplayEuro";

// Constants
import RouteEnum from "../../common/constants/RouteEnum";
import DateRangeData from "../../common/constants/DateRangeData";
import {DateRangePicker} from "rsuite";
import FormatDate from "../../common/helpers/FormatDate";
import Scanner from "../../common/components/General/Scanner";
import translate from "../../createSlice/common/helpers/utils/translate";

const mapStateToProps = (state) => ({
    environment: state.environment,
    transaction: state.transaction
});

const {ranges, locale} = DateRangeData()

class TransactionOverview extends React.Component {

    componentDidMount() {
        this.props.dispatch(TransactionAction.requestListByLocation())
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.transaction.listPeriod !== this.props.transaction.listPeriod) {
            this.props.dispatch(TransactionAction.requestListByLocation())
        }
    }

    render() {

        const {transaction} = this.props
        const {list, totals, listSearchValue} = transaction
        const {pages} = list

        return (
            <main style={{padding: '2rem 0'}}>

                <Scanner type={'rental'}/>

                <div className="o-container o-container--primary">

                    <div className="c-transaction-filter">

                        <DateRangePicker
                            isoWeek={true}
                            defaultValue={[this.props.transaction.listPeriod.start, this.props.transaction.listPeriod.end]}
                            value={[this.props.transaction.listPeriod.start, this.props.transaction.listPeriod.end]}
                            locale={locale}
                            ranges={ranges}
                            onChange={(data) => this._changeDate(data)}
                            renderValue={(value) => {
                                return FormatDate(value[0]) + ' ~ ' + FormatDate(value[1]);
                            }}
                        />

                        <input type={'text'} value={listSearchValue} onChange={(e) => this._changeSearchValue(e)}
                               onKeyDown={(e) => this._changeSearchValue(e)}/>

                    </div>

                    {(!!list.data && !!list.data.length) &&
                    <>
                        <h4 style={{marginTop: '4rem', padding: 0}}>Transacties</h4>
                        <table className={'transaction-overview transaction-overview--hover'}>

                            <tr>
                                <th>{translate('bookingsNumber')}</th>
                                <th>{translate('transactionNumber')}</th>
                                <th>{translate('renter')}</th>
                                <th>{translate('paymentMethod')}</th>
                                <th>{translate('alreadyPaid')}</th>
                                <th>{translate('fineForLateCancellation')}</th>
                                <th>{translate('depositPaid')}</th>
                                <th>{translate('refundedBookingAmount')}</th>
                                <th>{translate('depositAmountRefunded')}</th>
                            </tr>

                            {!!list.data && list.data.map((item) => {

                                const {
                                    rental_id,
                                    customer,
                                    id,
                                    payment_method,
                                    total,
                                    fine_for_late_cancellation,
                                    deposit,
                                    total_returned,
                                    deposit_returned
                                } = item

                                return (
                                    <tr onClick={() => this._clickItemRow(item)} key={'rental-' + rental_id}>
                                        <td>{rental_id}</td>
                                        <td>{id}</td>
                                        <td>{customer}</td>
                                        <td>{payment_method}</td>
                                        <td>{DisplayPrice(total)}</td>
                                        <td>{DisplayPrice(fine_for_late_cancellation)}</td>
                                        <td>{DisplayPrice(deposit)}</td>
                                        <td>{DisplayPrice(total_returned)}</td>
                                        <td>{DisplayPrice(deposit_returned)}</td>
                                    </tr>
                                )
                            })}

                        </table>
                    </>
                    }

                    {!!list.totals &&
                    <>
                        <h4 style={{marginTop: '4rem', padding: 0}}>{translate('totals')}</h4>

                        <table className={'transaction-overview'}>

                            <tr>
                                <th>{translate('paymentMethod')}</th>
                                <th>{translate('balanceRental')}</th>
                                <th>{translate('balanceDeposit')}</th>
                            </tr>

                            {Object.keys(list.totals).map((key) => {

                                const totalItem = list.totals[key]

                                return (
                                    <tr key={'total-item-' + key}>
                                        <td>{key}</td>
                                        <td>{DisplayPrice(totalItem.amount_total)}</td>
                                        <td>{DisplayPrice(totalItem.amount_deposit)}</td>
                                    </tr>
                                )

                            })}

                        </table>

                    </>
                    }

                    {!!pages &&
                    <ReactPaginate
                        previousLabel={'< ' + translate('previous')}
                        nextLabel={translate('next') + ' >'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={pages.total}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={(data) => this._handlePageClick(data)}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        forcePage={parseInt(pages.current)}
                        activeClassName={'active'}
                    />
                    }

                </div>
            </main>
        )
    }

    _clickItemRow = (item) => {
        this.props.history.push('/' + this.props.environment.slug + RouteEnum.RentalDetail + '/' + item.rental_uuid)
    }

    _changeDate = (data) => {
        this.props.dispatch(TransactionAction.setPeriod(data)).then(() => {
            this.props.dispatch(TransactionAction.requestListByLocation())
        })
    }

    _changeSearchValue = (e) => {
        this.props.dispatch(TransactionAction.setSearchValue(e.target.value)).then(() => {

                if (e.key === 'Enter') {
                    this.props.dispatch(TransactionAction.requestListByLocation())
                }
            }
        )
    }

    _handlePageClick = (i) => {
        this.props.dispatch(TransactionAction.setPage(i.selected)).then(() => {
            this.props.dispatch(TransactionAction.requestListByLocation())
        })
    }


}

export default connect(mapStateToProps)(TransactionOverview);

import {apiEnum} from "../../../enums/apiEnum";
import {useAppAxios} from "../../../../app/hooks";

export const apiPartnerItemLinkRentalAll = async (args) => {
    return await useAppAxios.post(apiEnum.PARTNER + '/item/link-rental-all', {
        items: args.items.map((item) => {
            return item.id
        }),
        overwrite: true,
        change_phase: args.changePhase, // before it was also used with exchanging items. Now it can always be true
    }, {
        params: {
            rental: args.uuid
        }
    }).then(function (response) {
        return response
    }).catch(function (error) {
        console.log(error)
        return {
            data: {
                status: {
                    code: 500
                }
            }
        }
    })
}

export const apiEnum = {
    AUTH: process.env.REACT_APP_API_URL + '/auth',
    ENVIRONMENT: process.env.REACT_APP_API_URL + '/environment',
    PARTNER: process.env.REACT_APP_API_URL + '/partner',
    GENERAL: process.env.REACT_APP_API_URL + '/general',
    CLIENT: process.env.REACT_APP_API_URL + '/client',
    WEBSHOP: process.env.REACT_APP_API_URL + '/webshop',
    TASK_USER: process.env.REACT_APP_API_URL + '/task/user'
}


import fetchHelper from "../../common/helpers/fetchHelper";

export default class ItemEffect {

    static async scanCode(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        if (response) {
            return {
                item: response.items.item,
                lastScanWarning: null
            }
        } else return {
            item: null,
            lastScanWarning: null
        }

    }

    static async submit(values, dispatch) {

        const responseUnlink = await fetchHelper(values.unlinkValues, dispatch)

        if (responseUnlink) {
            const response = await fetchHelper(values.linkValues, dispatch)
            if (response) {
                return response
            } else return null
        }

    }

    static async requestExportList(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        if (response) {
            return response
        } else return []

    }

    static async updateStatus(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        if (response) {
            return response
        } else return null
    }

    static async requestAvailableItemList(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        if (response) {
            return response.items.data
        } else return []
    }

    static async requestLateItems(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        if (response) {
            return response.items
        } else return []
    }
    static async requestInServiceItems(values, dispatch) {

        const response = await fetchHelper(values, dispatch)
        console.log(response)
        if (response) {
            return response.items
        } else return []
    }

    static async formatStockItemsByCategory(values) {

        return values.categories.map((category) => {
            const categoryItems = values.items.filter(item => item.category && item.category.id === category.category_id)

            return {
                ...category,
                items: categoryItems,
                stock: categoryItems.filter(item => item.rental_status && item.rental_status.code === 'available').length
            }

        })

    }

    static emptyScannedItems() {
        return true;
    }

    static reset() {
        return true;
    }

    static setLateItemsPage(pageNr) {
        return pageNr;
    }
    static setInServiceItemsPage(pageNr) {
        return pageNr;
    }
     static resetExportFileData() {
        return true;
    }

}
import ActionUtility from '../../common/helpers/ActionUtility'
import LocationEffect from "./LocationEffect";
import ApiEnum from "../../common/constants/ApiEnum";

export default class LocationAction {
    static REQUEST_DETAIL = 'LocationAction.REQUEST_DETAIL';
    static SUBMIT_OPENING_TIMES = 'LocationAction.SUBMIT_OPENING_TIMES';
    static REQUEST_LIST = 'LocationAction.REQUEST_LIST';


    static detail() {

        return async (dispatch, getState) => {
            const values = {
                fetchUrl: ApiEnum.Api + '/location/detail',
                method: 'post',
                accessToken: getState().authentication.accessToken,
                params: {
                    auth_token: localStorage.getItem('authToken'),
                }
            }

            await ActionUtility.createThunkEffect(dispatch, LocationAction.REQUEST_DETAIL, LocationEffect.requestDetail, values);
        };

    }

    static list() {


        return async (dispatch, getState) => {
            const values = {
                fetchUrl: ApiEnum.Api + '/location/list?clientLocations=true',
                method: 'post',
                accessToken: getState().authentication.accessToken,
                params: {
                    auth_token: localStorage.getItem('authToken'),
                }
            }

            await ActionUtility.createThunkEffect(dispatch, LocationAction.REQUEST_LIST, LocationEffect.requestList, values);
        };

    }

    static submitOpeningTimes(openingTimes) {
        return async (dispatch, getState) => {

            const values = {
                fetchUrl: ApiEnum.Api + '/location/opening-times',
                method: 'post',
                accessToken: getState().authentication.accessToken,
                params: {
                    auth_token: localStorage.getItem('authToken'),
                    opening_times: openingTimes
                }
            }

            await ActionUtility.createThunkEffect(dispatch, LocationAction.SUBMIT_OPENING_TIMES, LocationEffect.submitOpeningTimes, values);
        };
    }


}
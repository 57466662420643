import React from 'react'
import CustomerModel from "../../../common/models/CustomerModel";
import IsCurrentPartnerLocation from "../../../common/helpers/IsCurrentPartnerLocation";
import translate from "../../../createSlice/common/helpers/utils/translate";


class RentalCustomer extends React.Component {

    render() {

        const {rentalDetail} = this.props
        const {holidayAddress, data} = rentalDetail
        const {customer_private_person} = data


        if (customer_private_person) {
            const {addresses} = customer_private_person
            const customer = new CustomerModel(customer_private_person)
            const isCurrentPartnerLocation = IsCurrentPartnerLocation(this.props)

            return (
                <div className="c-info-blocks__single">
                    <header className="c-subject-header c-subject-header--closed">
                        <h2>{translate('customerData')}</h2>
                    </header>
                    <div className="c-info-blocks__single__body">

                        {!!isCurrentPartnerLocation &&
                        <div className="c-info-blocks__single__body__row">
                            <span>{translate('customerType')}</span>
                            <span>{translate('privateCustomerType')}</span>
                        </div>
                        }
                        {!!customer.data.name &&
                        <div className="c-info-blocks__single__body__row">
                            <span>{translate('name')}</span>
                            <span>{customer.data.name}</span>
                        </div>
                        }
                        {!!customer.data.telephone &&
                        <div className="c-info-blocks__single__body__row">
                            <span>{translate('phoneNumber')}</span>
                            <span>{customer.data.telephone}</span>
                        </div>
                        }
                        {!!customer.data.mobile_number &&
                        <div className="c-info-blocks__single__body__row">
                            <span>{translate('mobileNumber')}</span>
                            <span>{customer.data.mobile_number}</span>
                        </div>
                        }
                        {!!customer.data.email &&
                        <div className="c-info-blocks__single__body__row">
                            <span>{translate('emailAddress')}</span>
                            <span>{customer.data.email}</span>
                        </div>
                        }

                        {!!rentalDetail.identificationRequired && <>
                            {!!data.customer.document_number &&
                            <div className="c-info-blocks__single__body__row">
                                <span>{translate('documentNumber')}</span>
                                <span>{data.customer.document_number}</span>
                            </div>
                            }
                            {!!(data.customer.document_type && data.customer.document_number) &&
                            <div className="c-info-blocks__single__body__row">
                                <span>{translate('documentType')}</span>
                                <span>{data.customer.document_type}</span>
                            </div>
                            }

                        </>}

                        {(addresses && isCurrentPartnerLocation) && Object.values(addresses).map((address) => {

                            return (
                                <div className="c-address-item" key={'address-' + address.id}>
                                    <div className="c-info-blocks__single__body__row">
                                        <span>{translate('addressType')}</span>
                                        <span>{address.type.name}</span>
                                    </div>
                                    <div className="c-info-blocks__single__body__row">
                                        <span>{translate('city')}</span>
                                        <span>{address.city}</span>
                                    </div>
                                    <div className="c-info-blocks__single__body__row">
                                        <span>{translate('zipcode')}</span>
                                        <span>{address.zip_code}</span>
                                    </div>
                                    <div className="c-info-blocks__single__body__row">
                                        <span>{translate('streetHouseNumber')}</span>
                                        <span>{address.street} {address.house_number}{address.house_number_addition}</span>
                                    </div>
                                </div>
                            )

                        })}

                        {!!isCurrentPartnerLocation &&
                        <div className="c-info-blocks__single__body__row">
                            <span>{translate('holidayAddress')}</span>
                            <span>{holidayAddress}</span>
                        </div>
                        }

                        {!!rentalDetail.data.customer.remarks &&
                            <div className="c-info-blocks__single__body__row">
                                <span>{translate('notes')}</span>
                                <span>{rentalDetail.data.customer.remarks}</span>
                            </div>
                        }

                    </div>
                </div>
            )
        } else return null

    }

}

export default RentalCustomer;

import {ItemScanner} from "./ItemScanner";
import moment from "moment";
import {useAppDispatch} from "../../app/hooks";
import {openUnlinkItemModal} from "../../features/modals/unlinkItemModal/unlinkItemModalSlice";
import {openServiceModal} from "../../../views/serviceModal/serviceModalSlice";
import translate from "../helpers/utils/translate";
import {decamelizeKeys} from "humps";


export const ScannedItems = (props) => {

    const {title, items, removeItemAction, validation, validating, notifications, lockedItems} = props
    const dispatch = useAppDispatch()
    let containerClassName = 'c-order-handling__scanned'

    if (validation && validating) {
        containerClassName += ' is-disabled';
    }

    return (
        <div className={containerClassName}>
            {title &&
            <header style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <h4>{title}</h4>
                {props.itemScanner &&
                <ItemScanner
                    scanCode={props.scanCode}
                    disabled={!!(props.disabled || (validation && validating))}
                             onScanAction={(item) => props.scanItemAction(item)}/>
                }
            </header>
            }
            <div className={'c-info-blocks__single__body c-info-blocks__single__body--scroll'}>
                <table className={'c-table-standard'}>
                    <tbody>
                    <tr>
                        <th/>
                        <th>{translate('productCodeNew')}</th>
                        <th>{translate('productCodeOld')}</th>
                        <th>Categorie</th>
                        {props.displayRentalInfo &&
                        <>
                            <th>{translate('bookingsNumber')}</th>
                            <th>{translate('endDate')}</th>
                        </>
                        }

                        {(props.displayServiceInfo || props.displayRentalInfo) &&
                        <>
                            <th>{translate('action')}</th>
                        </>
                        }

                        <th/>
                    </tr>

                    {!!items.length && items.map((item) => {

                        const notification = notifications?.find((notification) => notification.id === item.id)
                        let className = 'item-row'

                        if (notification) {
                            className = 'is-warning'
                        }

                        return (
                            <tr className={className} key={'item-' + item.id}>
                                <td>
                                    <i className="icon-cross c-button-icon" onClick={() => removeItemAction(item)}/>
                                </td>
                                <td>{item.productCode}</td>
                                <td>{item.productCodePrev}</td>
                                <td>{item.category.name}</td>

                                {props.displayRentalInfo &&
                                <>
                                    <td>#{item.rental?.id}</td>
                                    <td>{moment(item.rental?.dateRentEnd).format('DD-MM-YYYY')}</td>
                                    <td>
                                        <button
                                            onClick={() => dispatch(openUnlinkItemModal(item))}>{translate('add')}</button>
                                    </td>
                                </>
                                }

                                {props.displayServiceInfo &&
                                <>
                                    <td>
                                        <button
                                            onClick={() => dispatch(openServiceModal(decamelizeKeys(item)))}>{translate('add')}</button>
                                    </td>
                                </>
                                }

                                {props.validation && <td></td>}
                            </tr>
                        )
                    })}

                    {(!props.displayRentalInfo && !!lockedItems?.length) && lockedItems.map((lockedItem) => {

                        return (
                            <tr className={'item-row is-locked'} key={'locked-item-' + lockedItem.id}>
                                <td/>
                                <td>{lockedItem.productCode}</td>
                                <td>{lockedItem.productCodePrev}</td>
                                <td>{lockedItem.category.name}</td>
                                <td><i className={'icon icon-lock'}/></td>
                            </tr>
                        )
                    })}

                    </tbody>
                </table>

                {(!items.length && !lockedItems?.length) &&
                <p className={'c-no-scanned-items'}>{translate('noItemsScanned')}...</p>
                }

            </div>

        </div>
    )

}
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import EnvironmentAction from "../../../../stores/environment/EnvironmentAction";
import {apiSendTwoFactorCode} from "../../../common/api/partner/user/apiPartnerUserSendTwoFactorCode";

export const requestNewTwoFactorCode = createAsyncThunk(
    'sendTwoFactorAuthModal/requestNewTwoFactorCode',
    async (_, { dispatch }) => {
        dispatch(EnvironmentAction.setLoader(true));
        const response = await apiSendTwoFactorCode();
        dispatch(EnvironmentAction.setLoader(false));
        return response;
    }
);

const initialState = {
    requestingNewCode: false,
    error: false,
};

export const sendTwoFactorAuthModalSlice = createSlice({
    name: 'sendTwoFactorAuthModal',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(requestNewTwoFactorCode.pending, (state) => {
            state.requestingNewCode = true;
        });
        builder.addCase(requestNewTwoFactorCode.fulfilled, (state, action) => {
            state.requestingNewCode = false;
            switch (action.payload.data.status.code) {
                case 204:
                    state.error = false;
                    break;
                case 400:
                    state.error = 'Already authenticated';
                    break;
                case 423:
                    state.error = 'Code requested recently';
                    break;
                default:
                    state.error = 'Unexpected error';
                    break;
            }
        });
        builder.addCase(requestNewTwoFactorCode.rejected, (state) => {
            state.requestingNewCode = false;
            state.error = 'Unexpected error';
        });
    },
});

export const sendTwoFactorAuthModalReducer = sendTwoFactorAuthModalSlice.reducer;
// Dependencis
import {Link} from "react-router-dom";
import React from "react";

// Helpers
import DisplayPrice from "../../../common/helpers/DisplayEuro";
import IsCurrentPartnerLocation from "../../../common/helpers/IsCurrentPartnerLocation";
import CheckDisplayModule from "../../../common/helpers/CheckDisplayModule";
import translate from "../../../createSlice/common/helpers/utils/translate";
import moment from "moment";


const RentalData = (props) => {

    const {environment, rentalDetail, rentalTotals, transaction, user} = props
    const {totals} = rentalTotals
    const rentalData = rentalDetail.data.rental
    const lastTransaction = transaction[transaction.length - 1]
    const isCurrentPartnerLocation = IsCurrentPartnerLocation(props)

    return (

        <div className="c-info-blocks__single">

            <header className="c-subject-header c-subject-header--closed">
                <h2>{translate('rentalData')}</h2>
            </header>

            <div className="c-info-blocks__single__body">
                <div className="c-info-blocks__single__body__row">
                    <span>{translate('bookingsNumber')}</span>
                    <span>{rentalDetail.RentalNr}</span>
                </div>
                <div className="c-info-blocks__single__body__row">
                    <span>{translate('establishment')}</span>
                    <span>{rentalDetail.partnerLocation}</span>
                </div>
                <div className="c-info-blocks__single__body__row">
                    <span>{translate('dateCreated')}</span>
                    <span>{rentalDetail.dateCreated}</span>
                </div>
                <div className="c-info-blocks__single__body__row">
                    <span>{translate('rentalPeriod')}</span>
                    {!!rentalDetail.data.time_unit.time_required ? (
                        <span>{moment(rentalData.date_rent_start).format('DD/MM/YYYY HH:mm')} - {moment(rentalData.date_rent_end).format('DD/MM/YYYY HH:mm')}</span>
                    ) : (
                        <span>{rentalDetail.hirePeriod}</span>
                    )}
                </div>
                <div className="c-info-blocks__single__body__row">
                    <span>{translate('status')}</span>
                    <span>{rentalDetail.status}</span>
                </div>

                {!!rentalDetail.paymentStatus && isCurrentPartnerLocation && CheckDisplayModule('financial', props.environment, props.partnerUser) &&
                <div className="c-info-blocks__single__body__row">
                    <span>{translate('paymentStatus')}</span>
                    <span>
                        <Link
                            to={'/' + environment.slug + '/dashboard/rental/' + rentalDetail.data.rental.uuid + '/transaction/' + rentalDetail.last_transaction_id + '/status'}>
                            {rentalDetail.paymentStatus}
                        </Link>
                    </span>
                </div>
                }

                {!!rentalDetail.paymentDate && isCurrentPartnerLocation && CheckDisplayModule('financial', props.environment, props.partnerUser) &&
                <div className="c-info-blocks__single__body__row">
                    <span>{translate('paymentDate')}</span>
                    <span>{rentalDetail.paymentDate}</span>
                </div>
                }

                {!!isCurrentPartnerLocation &&
                <div className="c-info-blocks__single__body__row">
                    <span>{translate('delivery')}</span>
                    <span>{rentalDetail.delivery ? translate('yes') : translate('no')}</span>
                </div>
                }

                {!!isCurrentPartnerLocation &&
                <div className="c-info-blocks__single__body__row">
                    <span>{translate('luggageHandling')}</span>
                    <span>{rentalDetail.luggageHandling ? 'Ja' : 'Nee'}</span>
                </div>
                }

                {!!isCurrentPartnerLocation && CheckDisplayModule('financial', props.environment, props.partnerUser) &&
                <div className="c-info-blocks__single__body__row">
                    <span>{translate('totalPrice')}</span>
                    <span>{rentalDetail.totalPrice}</span>
                </div>
                }

                {!!totals && lastTransaction && rentalDetail.data.paymentStatus === 'open' && isCurrentPartnerLocation &&
                <div className="c-info-blocks__single__body__row"
                     style={{color: '#FF7700'}}>
                    <span>{translate('outstandingAmount')}</span>
                    <span>
                    <Link style={{color: '#FF7700'}}
                          to={'/' + environment.slug + '/dashboard/rental/' + rentalDetail.data.rental.uuid + '/transaction/' + lastTransaction.id}>
                        {DisplayPrice(totals.data.totalPriceRest)}
                    </Link>
                    </span>
                </div>
                }

                {!!rentalDetail.webshopName &&
                <div className="c-info-blocks__single__body__row">
                    <span>Webshop</span>
                    <span>{rentalDetail.webshopName}</span>
                </div>
                }

                {!!rentalDetail.data.rental.remarks &&
                <div className="c-info-blocks__single__body__row">
                    <span>{translate('notes')}</span>
                    <span>{rentalDetail.data.rental.remarks}</span>
                </div>
                }
                {!!rentalDetail.identificationRequired && <>
                    {!!rentalDetail.data.customer.document_number &&
                    <div className="c-info-blocks__single__body__row">
                        <span>{translate('document-number')}</span>
                        <span>{rentalDetail.data.customer.document_number}</span>
                    </div>
                    }

                    {!!rentalDetail.data.customer.document_type &&
                    <div className="c-info-blocks__single__body__row">
                        <span>{translate('document-type')}</span>
                        <span>{rentalDetail.data.customer.document_type}</span>
                    </div>
                    }
                </>}

            </div>
        </div>
    )

}

export default RentalData

import React from "react";
import {connect} from "react-redux";
import RentalDispatcher from "../../../common/dispatchers/RentalDispatcher";
import RentalReturnItemsAction from "../../../stores/rental/returnItems/RentalReturnItemsAction";
import translate from "../../../createSlice/common/helpers/utils/translate";

const mapStateToProps = (state) => ({
    rentalDetail: state.rentalDetail.data,
    rentalIssue: state.rentalIssue,
    environment: state.environment,
    ...state.rentalReturn
});

class RentalReturnItems extends React.Component {


    render() {

        const {items} = this.props.rentalIssue

        if (items) {

            return (
                <div className="c-order-handling__list">
                    {!!this.props.environment.data.info?.can_take_all_items &&
                    <div style={{minWidth: '100%'}}>
                        <button onClick={() => this._returnAllItems(items)} style={{margin: '2rem 0'}}
                                className={'btn btn--small btn--primary'}><span>{translate('retrieveAllItems')}</span>
                        </button>
                    </div>
                    }

                    {this.props.rentalIssue.items.map((item) => {

                        const isScanned = this.isScanned(item)
                        let label = this._formatLabel(item)

                        return (
                            <div className="c-order-handling__list__single" key={'rental-issue-item-' + item.id}>
                                <div className="c-order-handling__list__single__wrap">
                                    <h6>{label}</h6>
                                </div>

                                {isScanned &&
                                <div
                                    className="c-order-handling__number c-order-handling__number--check c-order-handling__number__small">
                                    <i className="icon icon-check"/>
                                </div>
                                }

                                {!isScanned &&
                                <button onClick={() => this._returnItem(item)}
                                        className="c-order-handling__number c-order-handling__number__small"
                                        style={{background: 'red'}}>
                                    <i className="icon icon-cross"/>
                                </button>
                                }
                            </div>
                        )
                    })}

                </div>
            )

        } else return null
    }

    isScanned = (item) => {
        const itemId = item.id
        let scanned = false

        this.props.items.forEach((item) => {
            if (item.id === itemId) {
                scanned = true
            }
        })

        return scanned

    }

    _formatLabel = (item) => {
        let label = item.category.name

        if (item.product_code && item.product_code_prev) {
            label += ' - ' + item.product_code + ' / ' + item.product_code_prev;
        } else if (item.product_code_prev) {
            label += ' - ' + item.product_code_prev;
        } else if (item.product_code) {
            label += ' - ' + item.product_code;
        }
        return label
    }

    _returnAllItems = (items) => {

        items.forEach((item) => {
            if(!this.isScanned(item)){
                this._returnItem(item)
            }
        })

    }

    _returnItem = (item) => {

        let productCode = ''

        if (item.product_code) {
            productCode = item.product_code;
        } else if (item.product_code_prev) {
            productCode = item.product_code_prev;
        }

        this.props.dispatch(RentalReturnItemsAction.scanCode(productCode))
    }
}

export default connect(mapStateToProps, RentalDispatcher)(RentalReturnItems);
